import React, { Component } from 'react'

const BlankPage = () => {

  return (
    <div>
      
    </div>
  );
}

export default BlankPage;