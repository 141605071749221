import React, { useState, useEffect } from 'react';
import ModifyTester from './ModifyTester';
import { Button, Modal, Dropdown } from 'react-bootstrap';
import FetchWrapper from '../../util/fetchWrapper';

const ModifyTicketModal = ({ isAdd, isEdit, backdrop, keyboard, setShowModal, onAfterHideModal, showModal, currentUser, testerId, betaTestId, ...props }) => {
    const [ticketTitle, setTicketTitle] = useState('');
    const handleHide = () => {
        setShowModal(false);
        if (onAfterHideModal) {
            onAfterHideModal();
        }
    }

    return (
        <div className={showModal}>
            <Modal show={showModal} onHide={handleHide} backdrop={backdrop} keyboard={keyboard}
                dialogClassName="modal-40w"
                contentClassName="modal-40h"
            >
                <Modal.Body
                    style={{
                        maxHeight: 'calc(100vh)',
                        overflowY: 'auto'
                    }}
                >
                    <ModifyTester
                        currentUser={currentUser}
                        isModalRender={true}
                        setShowTesterModal={setShowModal}
                        isAdd={isAdd}
                        isEdit={isEdit}
                        betaTestId={betaTestId}
                        testerId={testerId}
                    />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ModifyTicketModal;