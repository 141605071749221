import React, { useState } from "react";
import DropDown from "./DropDown";

const FiltersPanel = ({
    otherButtons,
    setShowIsDeleted,
    showIsDeleted,
    showIsInActive,
    setShowIsInActive,
    showDeletedFilter,
    showInactiveFilter,
    showSearchBar,
    showTypeFilter,
    typeFilterOptions,
    typeFilterOnChange,
    typeFilterLabel,
    typeFilterValueProp,
    ...props }) => {
    const [hideFilters, setHideFilters] = useState(true);
    const filtersBoxMarginBottom = hideFilters ? { marginBottom: '0rem' } : { marginBottom: '1rem' };
    const showFiltersMarginBottom = hideFilters ? { marginBottom: '1rem' } : { marginBottom: '0rem' };
    return (
        <>
            <div>
                <button
                    className="btn btn-sm btn-gradient-primary h-auto"
                    style={{ ...showFiltersMarginBottom }}
                    onClick={(e) => {
                        e.preventDefault();
                        setHideFilters(!hideFilters);
                    }}
                >
                    {hideFilters ? 'Show' : 'Hide'} Filters
                </button>
                {
                    otherButtons ?
                        <>
                            {otherButtons.map(function (button, index) {
                                let newElement;
                                // button.style = { ...button.style, ...showFiltersMarginBottom }
                                if (button.type === 'button') {
                                    if (button.props.style) {
                                        newElement = React.cloneElement(button, {
                                            style: {
                                                ...button.props.style, ...showFiltersMarginBottom
                                            }
                                        })
                                    }
                                    else {
                                        newElement = React.cloneElement(button, {
                                            style: {
                                                ...showFiltersMarginBottom
                                            }
                                        })
                                    }
                                }
                                else if (button.type === 'a' && button.props.children.type === 'button') {
                                    if (button.props.children.props.style) {
                                        newElement = React.cloneElement(button.props.children, {
                                            style: {
                                                ...button.props.children.props.style, ...showFiltersMarginBottom
                                            }
                                        })
                                    }
                                    else {
                                        newElement = React.cloneElement(button.props.children, {
                                            style: {
                                                ...showFiltersMarginBottom
                                            }
                                        })
                                    }
                                }
                                return newElement;
                            })}
                        </>
                        :
                        <></>
                }
            </div>
            <div className='card mt-3' style={{ ...filtersBoxMarginBottom, paddingLeft: '16px', paddingRight: '8px' }} hidden={hideFilters}>
                <div className='row' style={{ marginLeft: '0px' }}>
                    <h5>Filters</h5>
                </div>
                <div className='row' style={{ marginLeft: '0px' }}>
                    {
                        showDeletedFilter ?
                            (
                                <div className="form-check">
                                    <label className="form-check-label" style={{ paddingRight: '.5rem' }}>
                                        <input
                                            name="isDeleted"
                                            type="checkbox"
                                            className="form-check-input form-control"
                                            onChange={(event) => { setShowIsDeleted(!showIsDeleted) }}
                                        />
                                        <i className="input-helper"></i>
                                        Show Deleted
                                    </label>
                                </div>
                            )
                            :
                            <></>
                    }

                    {
                        showInactiveFilter ?
                            (
                                <div className="form-check">
                                    <label className="form-check-label">
                                        <input
                                            name="isActive"
                                            type="checkbox"
                                            className="form-check-input form-control"
                                            onChange={(event) => { setShowIsInActive(!showIsInActive) }}
                                        />
                                        <i className="input-helper"></i>
                                        Show Inactive
                                    </label>
                                </div>
                            )
                            :
                            <></>
                    }

                    {
                        showTypeFilter && (
                            <div className='col-3 pl-0 ml-0'>
                                <DropDown
                                    options={typeFilterOptions}
                                    onChangeCallback={(e) => {
                                        typeFilterOnChange(e.target.value);
                                    }}
                                    label={typeFilterLabel}
                                    valueProp={typeFilterValueProp}
                                />
                            </div>
                        )
                    }
                </div>
            </div>
        </>
    );
}

export default FiltersPanel;