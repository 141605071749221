import React, { useState, useEffect } from "react";
import { Form, Formik } from "formik";
import { TextField } from "../form-elements/TextField";
import { TextArea } from "../form-elements/TextArea";
import { useHistory } from "react-router-dom";

export default function MessageForm({ initialValues, validate, handleSubmit, isView, ...props }) {
    const history = useHistory();
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validate}
            validateOnChange={true}
            enableReinitialize={true}
            onSubmit={values => {
                if (handleSubmit) {
                    handleSubmit(values);
                }
            }}
        >
            <Form>
                <div>
                    {
                        isView ?
                            <>
                                <TextField
                                    label="From"
                                    name="from"
                                    type="text"
                                    disabled={true}
                                />
                            </>
                            :
                            <></>
                    }

                    <TextField
                        label="Subject"
                        name="title"
                        type="text"
                        disabled={isView}
                    />

                    <TextArea
                        label="Message"
                        name="text"
                        type="text"
                        disabled={isView}
                    />
                </div>
                {!isView ?
                    <>
                        <button type="submit" className="btn btn-gradient-primary mr-2">Submit</button>
                        <button className="btn btn-light" onClick={(e) => {
                            e.preventDefault();
                            history.goBack();
                        }}>Cancel</button>
                    </>
                    :
                    <>

                    </>}
            </Form>
        </Formik>
    );
}