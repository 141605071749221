import React, { useEffect, useState, useRef } from 'react';
import FetchWrapper from '../../util/fetchWrapper';
import 'react-tabs/style/react-tabs.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import UserSettings from './UserSettings';
import IntegrationSettings from '../shared/IntegrationSettings';
import ConfirmationModal from '../shared/ConfirmationModal';
import { Formik, Form } from 'formik';
import { useHistory } from 'react-router-dom';
import { ROLES } from '../models/enums/rolesEnum';

const Settings = ({ currentUser, ...props }) => {
    const fw = FetchWrapper();
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const tab = queryParams.get('tab');
    const [settings, setSettings] = useState({});
    const [modifiedSettings, setModifiedSettings] = useState({ dummy: 'dummy' });
    const [saveButtonActive, setSaveButtonActive] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [confirmationModalMessage, setConfirmationModalMessage] = useState('');
    const [confirmationCodalTitle, setConfirmationModalTitle] = useState('');
    const [continueWithTabChange, setContinueWithTabChange] = useState(true);
    const saveButtonRef = useRef(null);
    const [hasTesterRole, setHasTesterRole] = useState(false);

    useEffect(() => {
        fw.get('/usersettings/get-all-settings')
            .then(data => {
                if (data.errorMessage) {
                    console.log(data.errorMessage);
                    return;
                }
                setSettings(data);
            })
    }, []);

    useEffect(() => {
        const { dummy, ...modifiedSettingsWithoutDummy } = modifiedSettings;
        if (Object.keys(modifiedSettingsWithoutDummy).length === 0) {
            setSaveButtonActive(false);
        }
        else {
            setSaveButtonActive(true);
        }
    }, [modifiedSettings]);

    useEffect(() => {
        setHasTesterRole(currentUser?.roles?.filter((role) => role.roleId === ROLES.TESTER && role.isGranted === true).length === 1 ?? false)
    }, [currentUser]);

    const updateAccountType = (accountTypeId) => {
        return fw.post('/usersettings/update-account-type', { accountTypeId })
            .then(data => {
                if (data.errorMessage) {
                    console.log(data.errorMessage);
                    return false;
                }
                return true;
            })
    }

    const disconnectGithub = () => {
        return fw.post('/authentication/github-disconnect')
            .then(data => {
                if (data.errorMessage) {
                    // setModalMessage(data.errorMess``age);
                    // setShowModal(true);
                }
                return data.success;
            });
    }

    const disconnectAsana = () => {
        return fw.post('/authentication/asana-disconnect')
            .then(data => {
                if (data.errorMessage) {
                    // setModalMessage(data.errorMess``age);
                    // setShowModal(true);
                }
                return data.success;
            });
    }

    const disconnectJira = () => {
        return fw.post('/authentication/jira-disconnect')
            .then(data => {
                if (data.errorMessage) {
                    // setModalMessage(data.errorMess``age);
                    // setShowModal(true);
                }
                return data.success;
            });
    }

    const updateCoreSettings = (modifiedSettingsWithoutDummy) => {
        return fw.post('/usersettings/update-core-settings', modifiedSettingsWithoutDummy)
            .then(data => {
                if (data.errorMessage) {
                    console.log(data.errorMessage);
                    return false;
                }
                return true;
            })
    }

    const saveButtonClick = () => {
        const { dummy, ...modifiedSettingsWithoutDummy } = modifiedSettings;
        let continueSave = true;
        if (modifiedSettings.accountTypeId) {
            continueSave = updateAccountType(modifiedSettings.accountTypeId);
            delete modifiedSettingsWithoutDummy.accountTypeId;
        }

        if (modifiedSettings.isGithubAuthenticated === false) {
            continueSave = disconnectGithub();
            delete modifiedSettingsWithoutDummy.isGithubAuthenticated;
        }

        if (modifiedSettings.isAsanaAuthenticated === false) {
            continueSave = disconnectAsana();
            delete modifiedSettingsWithoutDummy.isAsanaAuthenticated;
        }

        if (modifiedSettings.isJiraAuthenticated === false) {
            continueSave = disconnectJira();
            delete modifiedSettingsWithoutDummy.isJiraAuthenticated;
        }

        console.log('modifiedSettingsWithoutDummy', modifiedSettingsWithoutDummy);

        if (Object.keys(modifiedSettingsWithoutDummy).length > 0) {
            continueSave = updateCoreSettings(modifiedSettingsWithoutDummy);
        }

        if (continueSave) {
            window.location.reload();
        }
    }

    const getSelectedTabIndex = (tab) => {
        switch (tab) {
            case 'user':
                return 0;
            case 'integrations':
                return hasTesterRole ? 0 : 1;
            default:
                return 0;
        }
    }

    const initialValues = {
        githubOwner: settings.githubOwner,
        githubRepository: settings.githubRepository,
    }

    return (
        <>
            <Formik
                initialValues={initialValues}
                validateOnChange={true}
                enableReinitialize={true}
            >
                <Form>
                    <div className='card p-3'>
                        <h2>Settings</h2>
                        <div className='row col-12 pb-3'>
                            <button
                                className='btn btn-gradient-primary mr-3'
                                disabled={!saveButtonActive}
                                onClick={saveButtonClick}
                                ref={saveButtonRef}
                            >Save</button>
                        </div>
                        <Tabs
                            onSelect={(index, lastIndex, event) => {
                                if (index !== lastIndex) {
                                    setSaveButtonActive(false);
                                }
                            }}
                            selectedIndex={getSelectedTabIndex(tab)}
                        >
                            <TabList>
                                <Tab
                                    onClick={(e) => {
                                        e.preventDefault();
                                        history.push('/user-settings?tab=user')
                                    }}
                                >User</Tab>
                                {!hasTesterRole && (
                                    <Tab
                                        onClick={(e) => {
                                            e.preventDefault();
                                            history.push('/user-settings?tab=integrations')
                                        }}
                                    >Integrations</Tab>
                                )}
                            </TabList>
                            <TabPanel>
                                <UserSettings
                                    settings={settings}
                                    modifiedSettings={modifiedSettings}
                                    setModifiedSettings={setModifiedSettings} />
                            </TabPanel>

                            <TabPanel>
                                <IntegrationSettings
                                    currentUser={currentUser}
                                    globalSettings={settings}
                                    modifiedSettings={modifiedSettings}
                                    setModifiedSettings={setModifiedSettings}
                                    saveButtonRef={saveButtonRef}
                                    asanaRedirectUrl={process.env.REACT_APP_ASANA_SETTINGS_REDIRECT_URL}
                                />
                            </TabPanel>
                        </Tabs>
                    </div>
                </Form>
            </Formik>
            <ConfirmationModal
                showModal={showConfirmationModal}
                setShowModal={setShowConfirmationModal}
                title={confirmationCodalTitle}
                message={confirmationModalMessage}
                confirmText="Continue"
                cancelText="Cacnel"
                handleConfirmationClick={() => {
                    setShowConfirmationModal(false);
                }}
            />
        </>
    );
}

export default Settings;