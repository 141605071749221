
const editTicketImpl = ({ fw, onSaveSuccess }) => {
    const saveButtonClick = (values) => {
        console.log('submitting values', values);
        fw.post('/tickets/update-ticket', values)
            .then(data => {
                console.log(data);
                if (data.errorMessage) {
                    console.log(data.errorMessage);
                    //todo show modal
                }

                if (data.success) {
                    // window.location = '/'
                    //todo redirect when url ticket display is function on modify beta test page
                    // window.location.reload();
                    if (onSaveSuccess) {
                        onSaveSuccess();
                    }
                }
            })
    }

    return {
        saveButtonClick,
    }
}

export default editTicketImpl;