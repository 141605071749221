import React, { useState, useEffect, useMemo } from "react";
import useCollapse from 'react-collapsed';
import FetchWrapper from "../../util/fetchWrapper";
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { Link } from 'react-router-dom';
import FiltersPanel from "../shared/FiltersPanel";
import { TICKET_TYPES } from "../models/enums/ticketsEnum";
import { useHistory } from "react-router-dom";

const RequestsCollapse = ({
    betaTestId,
    requests,
    setShowAlertModal,
    setAlertModalTitle,
    setAlertModalMessage,
    isAdd,
    isView,
    isEdit,
    setShowTicketModal,
    setTicketModalId,
    setTicketIsAdd,
    setTicketIsEdit,
    setTicketIsView,
    setTicketTypeId,
    isTesterJoined,
    setShouldGoBackOnClose,
    ...props }) => {
    const [displayData, setDisplayData] = useState(undefined);
    const [showIsDeleted, setShowIsDeleted] = useState(false);
    const [showIsInActive, setShowIsInActive] = useState(false);
    const history = useHistory();

    const filterDisplayData = (data) => {
        if (!data) {
            return;
        }

        if (showIsDeleted || showIsInActive) {
            setDisplayData(requests);
            return;
        }

        const localDisplayData = data.filter(function (request) {
            if (request.isDeleted === false) {
                return request;
            }
        });
        setDisplayData(localDisplayData);
    }

    useEffect(() => {
        filterDisplayData(requests);
    }, [requests]);

    useEffect(() => {
        filterDisplayData(requests);
    }, [showIsInActive]);

    const columns = React.useMemo(
        () => [
            {
                name: 'Title',
                selector: row => row.title,
                sortable: true,
            },
            {
                name: 'Created by',
                selector: row => row.fullName,
                sortable: true,
            },
            {
                name: 'Created Date',
                selector: row => row.creationTime,
                format: row => moment(row.creationTime).format('lll'),
                sortable: true,
            },
            {
                name: 'Is Active',
                selector: row => row.isDeleted,
                cell: row => {
                    return <div style={{ paddingLeft: '1rem' }}>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input
                                    readOnly={true}
                                    checked={!row.isDeleted}
                                    name={`isActive-${row.Id}`}
                                    type="checkbox"
                                    className="form-check-input form-control"
                                />
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                },
                sortable: true,
            },
            {
                selector: row => row.ticketId,
                sortable: true,
                cell: row => {
                    return (
                        <>
                            {/* {!isAdd && isView ?
                                <>
                                    <button className='btn btn-gradient-primary mr-2'
                                        onClick={(e) => {
                                            if (e.ctrlKey === true) {
                                                e.preventDefault();
                                            }
                                            console.log('setting ticket id', row.ticketId);
                                            setTicketModalId(row.ticketId);
                                            setTicketTypeId(TICKET_TYPES.REQUEST);
                                            setTicketIsView(true);
                                            setTicketIsAdd(false);
                                            setTicketIsEdit(false);
                                            setShowTicketModal(true);
                                        }}>
                                        View
                                    </button>
                                </>
                                : */}
                            <>
                                {/* <Link to={`/requests/edit/${row.ticketId}`}> */}
                                <button className='btn btn-sm btn-gradient-primary mr-2'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const newLocation = `/betatests/${betaTestId}/request/edit/${row.ticketId}`;
                                        if (window.location.href.includes(newLocation)) {
                                            setShouldGoBackOnClose(true);
                                            setShowTicketModal(true);
                                        }
                                        else {
                                            setShouldGoBackOnClose(true);
                                            history.push(newLocation);
                                        }
                                    }}>
                                    Edit
                                </button>
                                {/* </Link> */}
                            </>
                            {/* } */}
                        </>
                    )
                }
            },
        ],
        [requests],
    );


    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

    // const createRequestButton =
    //     <a href={`/requests/add?betaTestId=${betaTestId}`}>

    //     </a>;
    return (
        <div className="collapsible">
            <h4 className="header" {...getToggleProps()}>
                Requests {isExpanded ? <i className="mdi mdi-arrow-up"></i> : <i className=" mdi mdi-arrow-down"></i>}
            </h4>
            <div {...getCollapseProps()} id="collapse-container">
                <div className="content">
                    <>
                        <FiltersPanel
                            showIsDeleted={showIsDeleted}
                            setShowIsDeleted={setShowIsDeleted}
                            showIsInActive={showIsInActive}
                            setShowIsInActive={setShowIsInActive}
                            // otherButtons={!isTesterJoined ? [] : [createRequestButton]}
                            showInactiveFilter={true}
                        />
                        <DataTable
                            columns={columns}
                            data={displayData}
                            pagination />
                    </>
                </div>
            </div>
        </div>
    );
}

export default RequestsCollapse;