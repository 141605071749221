import React from "react";
import { Formik, Form, Field, ErrorMessage, useField } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './textfield.css';

export const DateSelector = ({ label, onChange, selectorDisabled, ...props }) => {
    const [field, meta] = useField(props);
    return (
        <div className='text-field-wrapper'>
            <label htmlFor={field.name}>{label}</label>
            <div className="d-flex search-field form-group text-field-input-wrapper">
                <Field name={field.name}>
                    {({ field, meta, form: { setFieldValue } }) => {
                        return (
                            <DatePicker
                                {...field}
                                className="form-control"
                                selected={field.value || null}
                                onChange={(val) => {
                                    setFieldValue(field.name, val);
                                    if (onChange) {
                                        onChange(val);
                                    }

                                }}
                                disabled={selectorDisabled}
                            />
                        );
                    }}
                </Field>
            </div>
            <ErrorMessage component="span" name={field.name} className="error" />
        </div>
    );
};
