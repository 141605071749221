import React, { useRef, useState } from 'react';
import FetchWrapper
    from '../../util/fetchWrapper';
import ConfirmationModal from '../shared/ConfirmationModal';
import AlertModal from '../shared/AlertModal';

const FileUpload = ({
    uploadText,
    files,
    fileNames,
    handleDragDropEvent,
    clearAllFiles,
    createFormData,
    setFiles,
    removeFile,
    alreadyUploadedFiles,
    fileType,
    linkingId,
    hasUploadPermission,
    uploadDisabled,
    ...props }) => {

    const inputRef = useRef();
    const fw = FetchWrapper();

    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [deletingFile, setDeletingFile] = useState('');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [confirmationModalMessage, setConfirmationModalMessage] = useState('');
    const [confirmationModalTitle, setConfirmationModalTitle] = useState('');

    return (
        <span>
            {hasUploadPermission && (<h4>Upload Files</h4>)}
            {uploadText ?
                <>
                    {hasUploadPermission && (React.cloneElement(uploadText))}
                </>
                :
                <>
                    {hasUploadPermission && (<p>Please use the form to your right to upload any file(s) of your choosing.</p>)}
                </>
            }

            <div className="form-container">
                {/* Display the files to be uploaded */}
                <div>
                    <ul>
                        {fileNames.map((name) => (
                            <li key={name}>
                                <span>{name}</span>

                                <span onClick={() => removeFile(name)}>
                                    <i className="mdi mdi-close" />
                                </span>
                            </li>
                        ))}
                    </ul>
                    {alreadyUploadedFiles.length > 0 && (<h4>Saved Files:</h4>)}
                    <ul>
                        {alreadyUploadedFiles.map((file, index) => {
                            const ft = fileType ? fileType : 2;
                            const url = `${process.env.REACT_APP_API_URL}/files/get-file?linkingId=${linkingId}&filePath=${encodeURIComponent(file.path)}&fileType=${ft}`;
                            return (
                                <li key={index}>
                                    <a
                                        href={url}
                                        target="_blank"
                                    >
                                        {file.name}
                                    </a>
                                    {hasUploadPermission && (<span onClick={() => {
                                        setDeletingFile(file);
                                        setConfirmationModalMessage(`Are you sure you want to delete ${file.name}?`);
                                        setConfirmationModalTitle('Delete File');
                                        setShowConfirmationModal(true);
                                    }}>
                                        <i className="mdi mdi-close" />
                                    </span>)}
                                </li>
                            )
                        })}

                    </ul>
                </div>

                {/* Provide a drop zone and an alternative button inside it to upload files. */}
                {hasUploadPermission && (<div
                    onDragEnter={handleDragDropEvent}
                    onDragOver={handleDragDropEvent}
                    onDrop={(e) => {
                        if (uploadDisabled) {
                            return;
                        }
                        handleDragDropEvent(e);
                        setFiles(e, 'a');
                    }}
                    className="mb-3"
                    style={{ border: '1px solid #ccc', padding: '10px' }}
                >
                    <p className='text-center'>Drag and drop files here</p>

                    {/* Hide the crappy looking default HTML input */}
                    <input
                        id="file-upload"
                        ref={inputRef}
                        type="file"
                        multiple
                        style={{ display: 'none' }}
                        onChange={(e) => {
                            if (uploadDisabled) {
                                return;
                            }
                            let split = '\\';
                            if (e.target.value.includes('/')) {
                                split = '/';
                            }
                            const val = e.target.value.split(split);
                            const fileName = val[val.length - 1];
                            if (fileNames.indexOf(fileName) === -1) {
                                setFiles(e, 'a');
                            }
                            // inputRef.current.value = null;
                        }}
                    />
                </div>)}
            </div>
            {hasUploadPermission && (
                <div className='row col-12'>
                    <button
                        disabled={uploadDisabled}
                        className='btn btn-sm btn-gradient-primary mr-2'
                        onClick={(e) => {
                            e.preventDefault();
                            inputRef.current.click();
                        }}>Or select files to upload</button>
                    {files.length > 0 && (
                        <button
                            className='btn btn-sm btn-gradient-danger mr-2'
                            onClick={() => clearAllFiles()}>Clear All</button>
                    )}
                    {/* <button className='btn btn-gradient-primary mr-2' onClick={handleSubmit}>Submit</button> */}

                </div>
            )}

            <ConfirmationModal showModal={showConfirmationModal} setShowModal={setShowConfirmationModal} message={confirmationModalMessage} title={confirmationModalTitle}
                onAfterHideModal={() => {
                    // window.location = '/roles'

                }}
                handleConfirmationClick={() => {
                    const fileToDelete = {
                        filePath: `${encodeURIComponent(deletingFile.path)}`,
                        fileId: deletingFile.id,
                        fileType,
                        linkingId,
                    }
                    fw.post(`/files/delete-file`, fileToDelete)
                        .then(data => {
                            if (data.errorMessage) {
                                setModalMessage(data.errorMessage);
                                setModalTitle('Error');
                            } else {
                                setModalMessage('File deleted successfully.');
                                setModalTitle('Success');
                            }
                            setShowConfirmationModal(false);
                            setShowModal(true);
                        });
                }}
            />
            <AlertModal showModal={showModal} setShowModal={setShowModal} message={modalMessage} title={modalTitle} onAfterHideModal={() => {
                window.location.reload();
            }} />
        </span>
    );
};

export default FileUpload;