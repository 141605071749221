import { TICKET_TYPES } from "../models/enums/ticketsEnum";

const addTicketImpl = ({ fw, betaTestId, ticketTypeId, history, setShowTicketModal, viewTickets }) => {
    const saveButtonClick = (values) => {
        console.log('submitting values', values);
        fw.post('/tickets/save-ticket', values)
            .then(data => {
                if (data.errorMessage) {
                    console.log(data.errorMessage);
                    //todo show modal
                }

                if (data.success) {
                    if (viewTickets === true) {
                        window.location.href = `/betatests/${betaTestId}/tickets/${ticketTypeId === TICKET_TYPES.BUG ? 'bug' : 'request'}/edit/${data.ticketId}`;
                    }
                    else {
                        window.location.href = `/betatests/${betaTestId}/${ticketTypeId === TICKET_TYPES.BUG ? 'bug' : 'request'}/edit/${data.ticketId}`;
                    }
                }
            })
    }

    return {
        saveButtonClick,
    }
}

export default addTicketImpl;