import React, { useEffect, useState } from "react";
import FetchWrapper from "../../util/fetchWrapper";
import ConfirmationModal from '../shared/ConfirmationModal';
import { useHistory } from 'react-router-dom';

const BetaTesterSettings = ({
    currentUser,
    betaTestName,
    betaTest,
    rawBetaTestData,
    isOwner,
    userTesterObject,
    ...props }) => {
    const history = useHistory();
    const fw = FetchWrapper();
    const [betaTesterSettings, setBetaTesterSettings] = useState({});
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [confirmationModalMessage, setConfirmationModalMessage] = useState('');
    const [confirmationCodalTitle, setConfirmationModalTitle] = useState('');

    // useEffect(() => {
    //     fw.get('/beta-tests/tester-settings', { betaTestName: betaTestName })
    //         .then(data => {
    //             if (data.errorMessage) {
    //                 console.log('error', data.errorMessage);
    //                 return;
    //             }
    //             setBetaTesterSettings(data);
    //         })
    // }, []);

    const removeTesterFromBetaTest = () => {
        if (Object.keys(userTesterObject).length <= 0) {
            return;
        }
        fw.post('/testers/leave-beta-test', { testerId: userTesterObject?.id, betaTestId: betaTest?.id })
            .then(data => {
                if (data.errorMessage) {
                    // setAlertModalMessage(data.errorMessage);
                    // setAlertModalTitle('Error')
                    // setShowAlertModal(true);
                    console.error(data.errorMessage);
                    return;
                }

                if (data.success) {
                    history.push('/betatests');
                }
            })
    }

    return (
        <div className="card">
            <div className='card-body'>
                <div>
                    <h4 className="text-danger">Danger Zone</h4>
                    <hr style={{ color: '#fe7c96', backgroundColor: '#fe7c96' }} />
                    <div className='col-lg-8 col-md-12 col-sm-12 pl-0 ml-0'>
                        <h5 className="pb-2">
                            Leave Beta Test?
                        </h5>
                        <div className="col-3 pl-0 ml-0">
                            <button type="submit" className="btn btn-sm btn-gradient-danger btn-fw btn-sm"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setConfirmationModalMessage('Are you sure you want to remove yourself from this beta test? You can readd yourself later.')
                                    setConfirmationModalTitle('Are you sure?');
                                    setShowConfirmationModal(true);
                                }}
                            >Leave Beta Test</button>
                        </div>
                    </div>
                </div>

            </div>
            <ConfirmationModal
                showModal={showConfirmationModal}
                setShowModal={setShowConfirmationModal}
                title={confirmationCodalTitle}
                message={confirmationModalMessage}
                confirmText="Yes"
                cancelText="No"
                handleConfirmationClick={() => {
                    setShowConfirmationModal(false);
                    removeTesterFromBetaTest();
                }}
            />
        </div>
    )
};

export default BetaTesterSettings;
