import React, { useState, useEffect } from 'react';

const UserSettings = ({ settings, modifiedSettings, setModifiedSettings, ...props }) => {
    const [hideSwitchAccountType, setHideSwitchAccountType] = useState(true);
    const [enableSwapAccountInput, setEnableSwapAccountInput] = useState(true);
    const [accountType, setAccountType] = useState(settings.isCompany === true ? 3 : 4);
    const [originalAccountType, setOriginalAccountType] = useState(null);
    useEffect(() => {
        setAccountType(settings.isCompany === true ? 3 : 4);
        if (originalAccountType === null && settings.isCompany !== undefined) {
            setOriginalAccountType(settings.isCompany === true ? 3 : 4);
        }
    }, [settings]);

    const deleteAccountTypeId = () => {
        const alteredModifiedSettings = modifiedSettings;
        delete alteredModifiedSettings.accountTypeId;
        setModifiedSettings({ ...alteredModifiedSettings });
    }

    return (
        <>
            <div>
                <div className='row col-3 card pl-3 ml-0' style={{ outline: '1px solid black' }}>
                    <h4>Account Type: </h4>
                    <div style={{ maxHeight: '41px' }} className='form-group pb-0 mb-0'>
                        <div className='row col-12'>
                            <div className="form-check col-6 text-justify">
                                <label className="form-check-label">
                                    <input
                                        disabled={enableSwapAccountInput}
                                        type="radio"
                                        className="form-check-input"
                                        name="optionsRadios"
                                        id="optionsRadios1"
                                        value=""
                                        onClick={() => {
                                            setAccountType(3);
                                            if (originalAccountType === 4) {
                                                setModifiedSettings({
                                                    ...modifiedSettings,
                                                    accountTypeId: 3,
                                                })
                                            }
                                            else {
                                                deleteAccountTypeId();
                                            }
                                        }}
                                        checked={accountType == 3} />
                                    <i className="input-helper"></i>
                                    Company
                                </label>
                            </div>
                            <div className="form-check col-6 text-justify">
                                <label className="form-check-label">
                                    <input
                                        disabled={enableSwapAccountInput}
                                        type="radio"
                                        className="form-check-input"
                                        name="optionsRadios"
                                        id="optionsRadios2"
                                        value=""
                                        onClick={() => {
                                            setAccountType(4);
                                            if (originalAccountType === 3) {
                                                setModifiedSettings({
                                                    ...modifiedSettings,
                                                    accountTypeId: 4,
                                                });
                                            }
                                            else {
                                                deleteAccountTypeId();
                                            }
                                        }}
                                        checked={accountType == 4} />
                                    <i className="input-helper"></i>
                                    Tester
                                </label>
                            </div>
                        </div>
                    </div>
                    <button className='btn btn-warning col-6 btn-sm mb-1 pb-1'
                        onClick={(e) => {
                            e.preventDefault();
                            setHideSwitchAccountType(!hideSwitchAccountType);
                        }}>
                        Change Account Type
                    </button>
                    {hideSwitchAccountType === false &&
                        (
                            <div>
                                <div className='card bg-danger  p-1'>
                                    {
                                        settings.isCompany === true ?
                                            `
                                                WARNING: If you change your account type from a Company to a Tester all
                                            of your open beta tests will be set to closed. You CAN switch back but you will
                                            have to manually restart each beta test.
                                        `
                                            :
                                            `
                                                WARNING: If you change your account type from a Tester to a Company you will
                                                no longer be able to participate in any beta tests that you do not own. You CAN switch back and will but
                                                you will have to manually joinany beta tests you were previously participating.
                                        `
                                    }

                                </div>
                                <div className="form-check mx-sm-2">
                                    <label className="form-check-label">
                                        <input type="checkbox" className="form-check-input" checked={!enableSwapAccountInput}
                                            onChange={(e) => {
                                                if (!enableSwapAccountInput === true) {
                                                    setAccountType(originalAccountType);
                                                    deleteAccountTypeId();
                                                }
                                                setEnableSwapAccountInput(!enableSwapAccountInput);
                                            }}
                                        /> I Understand
                                        <i className="input-helper"></i>
                                    </label>
                                </div>
                            </div>
                        )}
                </div>
            </div>
        </>
    );
}

export default UserSettings;