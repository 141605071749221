import React, { useState, useEffect, useRef, useCallback } from 'react';
import { TextField } from '../form-elements/TextField.js';
import { CheckBox } from '../form-elements/CheckBox';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Button, Modal, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
import FetchWrapper from "../../util/fetchWrapper";
import * as Yup from 'yup';
import Comments from '../shared/Comments';
import DropDown from '../shared/DropDown';
import { useHistory } from 'react-router-dom';
import useMargin from '../shared/useMargin';
import useHeight from '../shared/useHeight';
import useFileUpload from 'react-use-file-upload';
import FileUpload from '../shared/FileUpload.js';
import { FILE_TYPE } from '../models/enums/fileTypeEnum';
import addTicketImpl from './addTicketImpl.js';
import editTicketImpl from './editTicketImpl.js';
import 'react-tabs/style/react-tabs.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import RichTextBox from '../form-elements/RichTextBox.js';

const ModifyTicket = ({
    ticketId,
    ticketTypeId,
    isAdd,
    isEdit,
    isView,
    isModalRender,
    setShowTicketModal,
    currentUser,
    betaTestId,
    shouldGoBackOnClose,
    isTesterJoined,
    isOwner,
    viewTickets,
    ...props }) => {
    const paramTicketId = props?.match?.params?.ticketId ?? ticketId ?? 0;
    const [canExportToGithub, setCanExportToGithub] = useState(false);
    const [modalTicketId, setModalTicketId] = useState(paramTicketId);
    const history = useHistory();
    const fw = FetchWrapper();
    const [ticket, setTicket] = useState({});
    const [canEditTicket, setCanEditTicket] = useState(!isView && isEdit || isAdd);
    const [statuses, setStatuses] = useState([]);
    const [comments, setComments] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [canUploadFiles, setCanUploadFiles] = useState(true);
    const reference = useRef(null);
    const rootElement = useRef(null);

    const initialValues = {
        title: ticket?.title ?? '',
        description: ticket?.description ?? '',
        comment: ticket?.comment ?? '',
        createdBy: isAdd ? (currentUser?.fullName ?? '') : (ticket?.fullName ?? ''),
    };
    const paddingForSaveButton = useMargin('ticketStatusId');
    const heightForLeaveButton = useHeight('title');
    const validate = Yup.object({
        title: Yup.string().required('Please provide a Title'),
        description: Yup.string().required('Please provide a Description').notOneOf(['<p class="editor-paragraph"><br></p>'], 'Please provide a Description'),
    });

    let dataImpl = addTicketImpl({
        fw,
        betaTestId,
        ticketTypeId,
        history,
        setShowTicketModal,
        viewTickets,
    });


    if (isEdit) {
        dataImpl = editTicketImpl({
            fw,
            onSaveSuccess: () => {
                // setShowTicketModal(false);
                window.location.reload();
            }
        });
    }

    const submitFiles = () => {
        if (files.length > 0) {
            const formData = createFormData();
            formData.append('linkingId', modalTicketId);
            formData.append('fileTypeId', FILE_TYPE.TICKET);
            fw.postForm(`/files/upload-file`, formData)
                .then(data => {
                    if (data.errorMessage) {
                    }
                })
        }
    }

    const handleSubmit = (values) => {
        values.statusId = ticket?.statusId ?? 1;
        values.ticketTypeId = ticketTypeId;
        values.betaTestId = betaTestId;
        values.ticketId = modalTicketId;
        values.isDeleted = ticket?.isDeleted ?? false;
        console.log(values);
        dataImpl.saveButtonClick(values);

        submitFiles();
    };

    const {
        files,
        fileNames,
        handleDragDropEvent,
        clearAllFiles,
        createFormData,
        setFiles,
        removeFile,
    } = useFileUpload();

    useEffect(() => {
        if (!ticket || ticket.id != modalTicketId && !isAdd) {
            fw.get(`/tickets/get-by-id?ticketId=${modalTicketId}`)
                .then(data => {
                    if (data.errorMessage) {
                        //display error message
                    }
                    setStatuses(data.statuses);
                    setComments(data.comments);
                    setCanExportToGithub(data.canExportToGithub);
                    setUploadedFiles(data?.files ?? []);
                    setTicket(data.ticket);

                })
        }
    }, [ticketId]);

    useEffect(() => {
        if (isAdd) {
            fw.get(`/tickets/get-statuses?ticketTypeId=${FILE_TYPE.TICKET}`)
                .then(data => {
                    if (data.errorMessage) {
                        //todo: display error message modal
                    }
                    setStatuses(data.statuses);
                    setTicket({});
                })
        }
    }, [isAdd])

    const uploadTextFormatted =
        <>
            <p>Please use the form to your right to upload any file(s) of your choosing.</p>
            <p>These files will be attached to the ticket.</p>
        </>;

    // const githubTooltip = (<Tooltip id="githubTooltip">Please configure your GitHub account in the integration settings</Tooltip>)

    return (
        <>
            <div className='card' style={{ height: '100%' }} ref={rootElement}>
                <span className='col-12 modal-close my-h-1 my-max-h-1 pr-0'>
                    <div style={{ float: 'right' }}
                        onClick={() => {
                            if (isModalRender) {
                                // history.push(`/betatests/${isEdit ? 'edit' : 'view'}/${betaTestId}`);
                                setShowTicketModal(false);
                                if (shouldGoBackOnClose) {
                                    history.goBack();
                                }
                            }
                        }}
                    >
                        {isModalRender && (<i className="mdi mdi-close-box-outline icon-md"
                        // style={{ float: 'right', height: 'calc(150%)', width: 'calc(150%)' }}
                        ></i>)}
                    </div>
                </span>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validate}
                    validateOnChange={true}
                    enableReinitialize={true}
                    onSubmit={values => {
                        handleSubmit(values);
                    }}
                >
                    {({ setFieldValue }) => (
                        <Form
                            ref={reference}>
                            <div style={{ overflowX: 'hidden' }} className={`${isModalRender ? 'pl-0 pr-1' : ''} card-body`}>
                                <div className='ticket-container'>
                                    <div className='ticket-header'>
                                        <div className='card-title'>
                                            <div className="ml-0 row">
                                                <div className="ml-0 col-8 row">
                                                    {!isModalRender && (<i className="mdi mdi-arrow-left-bold cursor-hover"
                                                        onClick={() => {
                                                            history.goBack();
                                                        }}
                                                    ></i>)}
                                                    <h4>
                                                        {isAdd ? 'Add' : ''} {ticketTypeId === 1 ? 'Bug' : 'Request'}: <a href={`/tickets/view/${ticket?.id}`}>{ticket?.id}</a>
                                                    </h4>
                                                </div>
                                                <div className="col-1">

                                                </div>
                                                <div className="col-2">
                                                    <CheckBox
                                                        checked={!ticket?.isDeleted ?? true}
                                                        // outerStyles={{ marginTop: '2.5rem' }}
                                                        name="isDeleted"
                                                        displayName="Is Active"
                                                        disabled={!canEditTicket}
                                                        onChange={(e) => {
                                                            setTicket({ ...ticket, isDeleted: !ticket.isDeleted })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row col-12' style={{ marginLeft: '0px' }}>
                                            <div className="col-3 ml-0 pl-0">
                                                <TextField
                                                    className="h-auto form-control form-control-lg"
                                                    label="Title"
                                                    id="title"
                                                    name="title"
                                                    type="text"
                                                    placeholder="Title"
                                                    disabled={!canEditTicket}
                                                />
                                            </div>
                                            <div className="col-3">
                                                <TextField
                                                    className="h-auto form-control form-control-lg"
                                                    label="Created By"
                                                    name="createdBy"
                                                    type="text"
                                                    placeholder="Created By"
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-2">
                                                <DropDown
                                                    dropDownHeight={heightForLeaveButton}
                                                    isDisabled={!canEditTicket || isAdd}
                                                    options=
                                                    {statuses?.map((status) => {
                                                        return <option key={status.id} value={status.id} selected={ticket?.statusId === status.id}>{status.name}</option>
                                                    })}
                                                    onChangeCallback={(e) => {
                                                        setTicket({ ...ticket, statusId: e.target.value })
                                                    }}
                                                    inputName="statusId"
                                                    label="Status"
                                                    valueProp={ticket?.statusId}
                                                    labelFor="statuses"
                                                    labelId="ticketStatusId"
                                                />
                                            </div>
                                            <div className='col-2'>
                                                {/* <label for="submitButton" style={{ color: '#fff', backgroundColor: '#fff' }}>S</label> */}
                                                <Button
                                                    id="submitButton"
                                                    variant='primary'
                                                    type='submit'
                                                    className='d-flex search-field form-group text-field-input-wrapper'
                                                    style={{ marginTop: paddingForSaveButton }}
                                                    // onClick={handleSubmit}
                                                    disabled={!canEditTicket}
                                                >
                                                    Save
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    <hr style={{ width: '98%', minWidth: '98%' }} />

                                    <div className='ticket-body'>
                                        <div className="col-12 row">
                                            <div className="col-7">
                                                <RichTextBox
                                                    className="h-auto form-control"
                                                    placeholder="Description"
                                                    label="Description"
                                                    name="description"
                                                    disabled={!canEditTicket}
                                                    setFieldValue={setFieldValue}
                                                    defaultValue={ticket?.description}
                                                    placeholderText="Description"
                                                />
                                                <RichTextBox
                                                    className="h-auto form-control"
                                                    placeholder="Comment"
                                                    label="Comment"
                                                    name="comment"
                                                    disabled={!canEditTicket}
                                                    setFieldValue={setFieldValue}
                                                />

                                                {(comments && comments.length > 0) && (<Comments
                                                    comments={comments}
                                                />)}
                                            </div>
                                            <div className='col-5'>
                                                <Tabs>
                                                    <TabList>
                                                        <Tab>Attachments</Tab>
                                                        <Tab>Export</Tab>
                                                    </TabList>
                                                    <TabPanel>
                                                        <FileUpload
                                                            uploadDisabled={isView || !isAdd && !isEdit}
                                                            // uploadText={uploadTextFormatted}
                                                            files={files}
                                                            fileNames={fileNames}
                                                            handleDragDropEvent={handleDragDropEvent}
                                                            clearAllFiles={clearAllFiles}
                                                            createFormData={createFormData}
                                                            setFiles={setFiles}
                                                            removeFile={removeFile}
                                                            alreadyUploadedFiles={uploadedFiles}
                                                            linkingId={ticket?.id}
                                                            fileType={FILE_TYPE.TICKET}
                                                            hasUploadPermission={canUploadFiles}
                                                        />
                                                    </TabPanel>
                                                    <TabPanel>
                                                        <div className='row col-12'>
                                                            <OverlayTrigger placement='top' overlay={<Tooltip id="githubTooltip">Please configure your GitHub account in the integration settings</Tooltip>} >
                                                                <button
                                                                    className='btn btn-sm btn-primary d-flex search-field form-group text-field-input-wrapper'
                                                                    style={{ marginTop: paddingForSaveButton }}
                                                                    // disabled={!canExportToGithub}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        fw.post('/tickets/export-ticket-to-github',
                                                                            { ticketId: ticket?.id })
                                                                            .then(data => {
                                                                                console.log('export github response', data);
                                                                                if (data.success && data.url) {
                                                                                    console.log('opening url', data.url);
                                                                                    window.open(data.url, '_blank');
                                                                                    console.log('opened url', data.url);
                                                                                }
                                                                            })
                                                                    }}
                                                                >
                                                                    Export to Github
                                                                </button>
                                                            </OverlayTrigger>
                                                        </div>
                                                        <div className='row col-12'>
                                                            <OverlayTrigger placement='top' overlay={<Tooltip id="githubTooltip">Please configure your GitHub account in the integration settings</Tooltip>} >
                                                                <button
                                                                    className='btn btn-sm btn-primary d-flex search-field form-group text-field-input-wrapper'
                                                                    style={{ marginTop: paddingForSaveButton }}
                                                                    // disabled={!canExportToGithub}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        fw.post('/tickets/export-ticket-to-jira',
                                                                            { ticketId: ticket?.id })
                                                                            .then(data => {
                                                                                console.log('export jira response', data);
                                                                                if (data.success && data.url) {
                                                                                    console.log('opening url', data.url);
                                                                                    window.open(data.url, '_blank');
                                                                                    console.log('opened url', data.url);
                                                                                }
                                                                            })
                                                                    }}
                                                                >
                                                                    Export to Jira
                                                                </button>
                                                            </OverlayTrigger>
                                                        </div>
                                                        <div className='row col-12'>
                                                            <OverlayTrigger placement='top' overlay={<Tooltip id="githubTooltip">Please configure your GitHub account in the integration settings</Tooltip>} >
                                                                <button
                                                                    className='btn btn-sm btn-primary d-flex search-field form-group text-field-input-wrapper'
                                                                    style={{ marginTop: paddingForSaveButton }}
                                                                    // disabled={!canExportToGithub}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        fw.post('/tickets/export-ticket-to-asana',
                                                                            { ticketId: ticket?.id })
                                                                            .then(data => {
                                                                                console.log('export jira response', data);
                                                                                if (data.success && data.url) {
                                                                                    console.log('opening url', data.url);
                                                                                    window.open(data.url, '_blank');
                                                                                    console.log('opened url', data.url);
                                                                                }
                                                                            })
                                                                    }}
                                                                >
                                                                    Export to Asana
                                                                </button>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </TabPanel>
                                                </Tabs>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    )
}

export default ModifyTicket;