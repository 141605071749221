import React, { useState, useEffect, useRef } from "react";
import ExampleTheme from "./ExampleTheme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import TreeViewPlugin from "./TreeViewPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HeadingNode, QuoteNode, serialize, TextField } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import ToolbarPlugin from "./ToolbarPlugin";
import './RichTextStyle.css'
import { Field, ErrorMessage } from "formik";
import { $getRoot, $getSelection, $createParagraphNode, $createTextNode, createEditor, $insertNodes, $getPreviousSelection } from 'lexical';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';


function Placeholder({ placeholderText, ...props }) {
    return (<div className="editor-placeholder">{placeholderText}</div>);
}

const MyOnChangePlugin = ({ onChange, ...props }) => {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        return editor.registerUpdateListener((editorState) => {
            // console.log('editorState', editorState);
            // console.log('editor', editor);
            const html = $generateHtmlFromNodes(editor, null);
            // console.log('editor update', html);
            onChange(html);
        });
    }, [onChange, editor]);
    return null;
}

export default function RichTextBox({ placeholderText, label, disabled, name, setFieldValue, defaultValue, bypassMaxHeightLimit,
    ...props }) {
    const editorConfig = {
        namespace: name,
        // The editor theme
        theme: ExampleTheme,
        // Handling of errors during update
        onError(error) {
            throw error;
        },
        // Any custom nodes go here
        nodes: [
            HeadingNode,
            ListNode,
            ListItemNode,
            QuoteNode,
            CodeNode,
            CodeHighlightNode,
            TableNode,
            TableCellNode,
            TableRowNode,
            AutoLinkNode,
            LinkNode
        ]
    };

    const [focused, setFocused] = useState(false);

    const handleBlur = (e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
            updateFocusedState(false, 'handleBlur');
        }
    };

    const onChange = (editorState, editor) => {
        editor.update(() => {
            const raw = $generateHtmlFromNodes(editor, null);
            console.log('setting field value ', name, raw);
            if (setFieldValue) {
                setFieldValue(name, raw);
            }
        })
    }

    const updateFocusedState = (newFocused, method) => {
        console.log('updateFocusedState', newFocused, method)
        setFocused(newFocused);
    }

    const MyOnLoadPlugin = ({ defaultValue, dataLoaded, setDataLoaded, disabled, ...props }) => {
        const [editor] = useLexicalComposerContext();
        useEffect(() => {
            if (defaultValue && dataLoaded === false) {
                editor.setEditable(false);
                editor.update(() => {
                    if (defaultValue.startsWith('<p')) {
                        // console.log('loading default value');
                        // console.log(defaultValue);

                        const parser = new DOMParser();
                        const dom = parser.parseFromString(defaultValue, 'text/html');
                        const nodes = $generateNodesFromDOM(editor, dom);
                        // console.log('nodes', nodes);

                        $insertNodes(nodes);
                        // root.append(...nodes);

                        // console.log('finished loading default value', defaultValue);
                    }
                    else {
                        let selection = $getSelection() || $getPreviousSelection();

                        if (selection === null) {
                            selection = $getRoot().selectEnd();
                        }

                        const paragraphNode = $createParagraphNode();

                        const textNode = $createTextNode(defaultValue);

                        paragraphNode.append(textNode);

                        $insertNodes([paragraphNode]);
                    }

                    setTimeout(() => {
                        editor.setEditable(disabled !== true);
                        editor.blur();
                    }, 100);

                    console.log('setting data to loaded');
                    setDataLoaded(true);
                });
            }
        }, [defaultValue]);
        return null;
    }

    const [dataLoaded, setDataLoaded] = useState(false);
    const focusedClassname = (bypassMaxHeightLimit) ? '' : 'max-height-unfocused-text-field'
    const disabledClassname = (disabled === true && !bypassMaxHeightLimit) ? 'disabled-text-field' : ''

    return (
        <LexicalComposer initialConfig={editorConfig}>
            {label && (<label htmlFor={name}>{label}</label>)}
            <Field name={name}>
                {({ field }) => (
                    <>
                        <div
                            onFocus={() => updateFocusedState(true, 'onFocus')}
                            onBlur={handleBlur}
                            disabled={disabled}
                            className="pb-4 mb-4"
                        >
                            <div
                                className={`editor-container m-0 ${props.className} ${focusedClassname} ${disabledClassname}`}
                            >
                                <div className={`editor-inner ${disabledClassname}`}>
                                    <HistoryPlugin />
                                    <RichTextPlugin
                                        {...field}
                                        maxHeight={'200px'}
                                        // name={name}
                                        contentEditable={<ContentEditable className={`editor-input`} />}
                                        placeholder={<Placeholder placeholderText={placeholderText} />}
                                        ErrorBoundary={LexicalErrorBoundary}
                                    />
                                    <OnChangePlugin onChange={onChange} />
                                    <MyOnLoadPlugin defaultValue={defaultValue} dataLoaded={dataLoaded} setDataLoaded={setDataLoaded} disabled={disabled} />
                                    {/* <TreeViewPlugin /> */}
                                </div>
                            </div>
                            {(focused && disabled !== true) && (<ToolbarPlugin />)}
                            <ErrorMessage component="span" name={field.name} className="error" />
                        </div>
                    </>
                )}
            </Field>
        </LexicalComposer >
    );
}