import React, { Container } from 'react';
import { ErrorMessage, useField } from 'formik';
import './textfield.css';

export const TextField = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className='text-field-wrapper' hidden={props.hidden}>
      <label htmlFor={field.name}>{label}</label>
      <div className="d-flex search-field form-group text-field-input-wrapper">

        <input
          ref={props.inputRef}
          className={`h-auto form-control form-control-lg`}
          {...field} {...props}
          autoComplete="off"
          placeholder={props.placeholder}
        />
      </div>
      <ErrorMessage component="span" name={field.name} className="error" />
    </div>
  )
}