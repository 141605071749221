import React, { useEffect, useState } from "react";

const StickySubNavigation = ({ children, navBarItems, ...props }) => {
    const [pixelDifference, setPixelDifference] = useState(0);

    function adjustStickyPosition(stickyElementId, navbarClass) {
        const stickyElement = document.getElementById(stickyElementId);
        const navbar = document.querySelector(`.${navbarClass}`);

        if (!stickyElement || !navbar) {
            console.error("Elements not found!");
            return;
        }

        const stickyElementPosition = stickyElement.getBoundingClientRect().top;
        const navbarHeight = navbar.offsetHeight;

        if (stickyElementPosition < navbarHeight) {
            const pDiff = navbarHeight - stickyElementPosition;

            stickyElement.style.paddingTop = `${pDiff}px`;
            setPixelDifference(pDiff);
        } else {
            stickyElement.style.paddingTop = "0px";
            setPixelDifference(0);
        }
    }

    window.addEventListener('scroll', () => {
        adjustStickyPosition('scroll-bar-sticky', 'navbar');
    }, true);


    useEffect(() => {
        const handleAnchorClick = (e) => {
            const href = e.target.getAttribute('href');
            if (href && href !== '#' && href.startsWith('#')) {
                e.preventDefault();
                const target = document.querySelector(href);

                if (target) {
                    target.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            }
        };

        document.addEventListener('click', handleAnchorClick);

        return () => {
            document.removeEventListener('click', handleAnchorClick);
        };
    }, []);

    return (
        <>
            <div>
                <ul>
                    {
                        pixelDifference > 0 && (<li><a href="#betaTestHeader"><i class="mdi mdi-arrow-up"></i> Back to Top</a></li>)
                    }
                    {
                        navBarItems.map((item, index) => {
                            return (<li><a style={{ color: item.color ? item.color : '' }} href={item.id}>{(item.icon && (<i className={item.icon}></i>))} {item.name}</a></li>)
                        })
                    }
                </ul>
            </div>
        </>
    )
}

export default StickySubNavigation;