const handleSuccessfulLoginRedirect = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let returnUrl = params.get('returnUrl');
    let hasPreviousReauthentication = window.location.search.includes('hasReauthenticated');
    let hasSubSearchInSearch = search.includes('%3F');
    if (returnUrl && !hasPreviousReauthentication) {
        window.location = `${returnUrl}${hasSubSearchInSearch ? '&' : '?'}hasReauthenticated=true`;
    } else {
        window.location = '/betatests';
    }
}

export default handleSuccessfulLoginRedirect;