import React, { useState, useEffect, } from 'react';
import LoginGithub from '../user/LoginGithub';
import FetchWrapper from '../../util/fetchWrapper';
import { CheckBox } from '../form-elements/CheckBox';
import { TextField } from '../form-elements/TextField';
import LoginAsana from '../user/LoginAsana';
import LoginJira from '../user/LoginJira';

const IntegrationSettings = ({ currentUser, globalSettings, betaTestSettings, modifiedSettings, setModifiedSettings, saveButtonRef, betaTestName, overrideGlobalSettingsChecked, setOverrideGlobalSettingsChecked, dataLoaded,
    asanaRedirectUrl, ...props }) => {
    const queryParams = new URLSearchParams(window.location.search);
    const state = queryParams.get('state');
    const code = queryParams.get('code');
    const [isGithubDisconnecting, setIsGithubDisconnecting] = useState(false);
    const [isAsanaDisconnecting, setIsAsanaDisconnecting] = useState(false);
    const [isJiraDisconnecting, setIsJiraDisconnecting] = useState(false);
    const [isGithubAuthenticated, setIsGithubAuthenticated] = useState(false);
    const [isAsanaAuthenticated, setIsAsanaAuthenticated] = useState(false);
    const [isJiraAuthenticated, setIsJiraAuthenticated] = useState(false);
    const [allowExportToGithubChecked, setAllowExportToGithubChecked] = useState(false);
    const [allowExportToJiraChecked, setAllowExportToJiraChecked] = useState(false);
    const [allowExportToAsanaChecked, setAllowExportToAsanaChecked] = useState(false);
    const [asanaProjectIdText, setAsanaProjectIdText] = useState('');
    const [githubOwnerText, setGithubOwnerText] = useState('');
    const [githubRepositoryText, setGithubRepositoryText] = useState('');
    const [jiraApiTokenText, setJiraApiTokenText] = useState('');
    const [jiraUrlText, setJiraUrlText] = useState('');
    const [jiraEmailText, setJiraEmailText] = useState('');
    const [jiraBugIssueTypeText, setJiraBugIssueTypeText] = useState('');
    const [jiraRequestIssueTypeText, setJiraRequestIssueTypeText] = useState('');
    const [jiraProjectKeyText, setJiraProjectKeyText] = useState('');
    const fw = FetchWrapper();

    const setSettingsToDefault = (alterOverride) => {

        if (setOverrideGlobalSettingsChecked && alterOverride) {
            setOverrideGlobalSettingsChecked(betaTestSettings?.overrideGlobalSettings ?? false);
        }
        let settingsToSet = globalSettings;
        if (overrideGlobalSettingsChecked === true) {
            settingsToSet = betaTestSettings;
        }

        // console.log('updating settings default values with settings', settingsToSet);

        setAllowExportToGithubChecked(settingsToSet?.allowExportToGithub ?? false);
        setAllowExportToAsanaChecked(settingsToSet?.allowExportToAsana ?? false);
        setAllowExportToJiraChecked(settingsToSet?.allowExportToJira ?? false);
        setAsanaProjectIdText(settingsToSet?.asanaProjectId ?? '');
        setGithubOwnerText(settingsToSet?.githubOwner ?? '');
        setGithubRepositoryText(settingsToSet?.githubRepository ?? '');
        setJiraApiTokenText(settingsToSet?.jiraApiToken ?? '');
        setJiraUrlText(settingsToSet?.jiraUrl ?? '');
        setJiraEmailText(settingsToSet?.jiraEmail ?? '');
        setJiraRequestIssueTypeText(settingsToSet?.jiraRequestIssueType ?? '');
        setJiraBugIssueTypeText(settingsToSet?.jiraBugIssueType ?? '');
        setJiraProjectKeyText(settingsToSet?.jiraProjectKey ?? '');
        setIsGithubAuthenticated(settingsToSet?.isGithubAuthenticated ?? false);
        setIsAsanaAuthenticated(settingsToSet?.isAsanaAuthenticated ?? false);
        setIsJiraAuthenticated(settingsToSet?.isJiraAuthenticated ?? false);
    }

    useEffect(() => {
        setSettingsToDefault(true);
    }, [globalSettings, betaTestSettings]);

    const onGithubSuccess = (response) => {
        console.log('github success', response);
        console.log('github betaTestName', betaTestName);
        fw.post('/authentication/github-login', { token: response.code, betaTestName, generateBtmpCookies: false })
            .then(data => {
                if (data.errorMessage) {
                    // setModalMessage(data.errorMessage);
                    // setShowModal(true);
                    return;
                }
                window.location.reload();
            });
    }

    const onGithubFailure = (response) => {
        console.log('github failure', response);
    }

    const onAsanaSuccess = (response) => {
        // console.log('asana success', response);
        fw.post('/authentication/asana-code', { code: response.code, betaTestName, generateBtmpCookies: false })
            .then(data => {
                if (data.errorMessage) {
                    // setModalMessage(data.errorMessage);
                    // setShowModal(true);
                    return;
                }
                window.location.reload();
            });
    }

    const onAsanaFailure = (response) => {
        console.log('asana failure', response);
    }

    const onJiraFailure = (response) => {
        console.log('Jira failure', response);
    };

    const onJiraSuccess = (response) => {
        console.log('Jira success', response);

        fw.post('/authentication/jira-login', { token: response.code, betaTestName, generateBtmpCookies: false })
            .then(data => {
                if (data.errorMessage) {
                    console.error(data.errorMessage);
                }
                else {
                    window.location.reload();
                }
            });
    }

    useEffect(() => {
        if (overrideGlobalSettingsChecked === true && dataLoaded === true) {
            setSettingsToDefault(false);
        }
        else {
            setSettingsToDefault(false);
        }
    }, [overrideGlobalSettingsChecked]);

    const disconnectGithubButtonClass = isGithubDisconnecting
        ? 'btn btn-block btn-github-disconnected auth-form-btn'
        : 'btn btn-block btn-success2 btn-github-disconnect auth-form-btn';

    const disconnectAsanaButtonClass = isAsanaDisconnecting
        ? 'btn btn-block btn-asana-disconnected auth-form-btn'
        : 'btn btn-block btn-success2 btn-asana-disconnect auth-form-btn';

    const disconnectJiraButtonClass = isJiraDisconnecting
        ? 'btn btn-block btn-jira-disconnected auth-form-btn'
        : 'btn btn-block btn-success2 btn-jira-disconnect auth-form-btn';

    useEffect(() => {
        console.log('allowExportToGithubChecked', allowExportToGithubChecked);
    }, [allowExportToGithubChecked]);

    return (
        <div>
            <div className='row pl-0 ml-0 mb-2'>
                <h4>Github </h4>
                <span>
                    <CheckBox
                        displayName='Auto Export to Github'
                        name='allowExportToGithub'
                        checked={allowExportToGithubChecked}
                        onChange={(e) => {
                            if (typeof modifiedSettings?.allowExportToGithub === "boolean") {
                                const modified = modifiedSettings;
                                delete modified.allowExportToGithub;
                                console.log('deleting allowExportToGithub', modified);
                                setModifiedSettings({ ...modified });
                            }
                            else {
                                const updatedSettings = { ...modifiedSettings, allowExportToGithub: !allowExportToGithubChecked };
                                console.log('updated settings', updatedSettings);
                                setModifiedSettings(updatedSettings);
                            }

                            setAllowExportToGithubChecked(!allowExportToGithubChecked);
                        }}
                        formCheckExtras='mt-0 mb-0 ml-2'
                        limitCheckBoxHeight={true}
                        disabled={(typeof betaTestName === 'string' && !overrideGlobalSettingsChecked)}
                    />
                </span>
            </div>

            <div className='row col-12 ml-0 pl-0 pr-0'>
                {!isGithubAuthenticated ?
                    (<span className='col-lg-3 col-md-7 col-sm-12 pl-0 ml-0'>
                        <LoginGithub clientId={process.env.REACT_APP_GITHUB_CLIENT_ID}
                            className="btn btn-block btn-github auth-form-btn"
                            children={(<><i className="mdi mdi-github-circle mr-2"></i>Connect using Github</>)}
                            onSuccess={onGithubSuccess}
                            onFailure={onGithubFailure}
                            scope="repo, user, project"
                            disabled={(allowExportToGithubChecked === false || overrideGlobalSettingsChecked === false)}
                        />
                    </span>)
                    :
                    (<span className='col-lg-3 col-md-7 col-sm-12 pl-0 ml-0'>
                        <button className={disconnectGithubButtonClass}
                            disabled={(allowExportToGithubChecked === false || overrideGlobalSettingsChecked === false)}
                            onClick={(e) => {
                                e.preventDefault();
                                if ((!isGithubDisconnecting) === true) {
                                    setModifiedSettings({ ...modifiedSettings, isGithubAuthenticated: false });
                                }
                                else {
                                    const { isGithubAuthenticated, ...modifiedSettingsWithoutGithub } = modifiedSettings;
                                    setModifiedSettings(modifiedSettingsWithoutGithub);
                                }

                                setIsGithubDisconnecting(!isGithubDisconnecting);
                            }}
                        >
                            <i className="mdi mdi-github-circle mr-2"></i>
                        </button>
                    </span>)}

            </div>
            <div className='row col-12 ml-0 pl-0 pr-0 pt-3'>
                <div className='col-lg-3 col-md-7 col-sm-12 pl-0 ml-0'>
                    <TextField
                        label='Github Owner'
                        name='githubOwner'
                        value={githubOwnerText}
                        onChange={(e) => {
                            if (e.target.value === '') {
                                const modified = modifiedSettings;
                                delete modified.githubOwner;
                                console.log('deleting githubOwner', modified);
                                setGithubOwnerText('');
                                setModifiedSettings({ ...modified });
                                return;
                            }
                            else {
                                setGithubOwnerText(e.target.value);
                                setModifiedSettings({ ...modifiedSettings, githubOwner: e.target.value });
                            }
                        }}
                        disabled={(allowExportToGithubChecked === false || overrideGlobalSettingsChecked === false)}
                    />
                </div>
            </div>
            <div className='row col-12 ml-0 pl-0 pr-0'>
                <div className='col-lg-3 col-md-7 col-sm-12 pl-0 ml-0'>
                    <TextField
                        label='Github Repository'
                        name='githubRepository'
                        value={githubRepositoryText}
                        onChange={(e) => {
                            if (e.target.value === '') {
                                const modified = modifiedSettings;
                                delete modified.githubRepository;
                                console.log('deleting githubRepository', modified);
                                setGithubRepositoryText('');
                                setModifiedSettings({ ...modified });
                                return;
                            }
                            else {
                                setGithubRepositoryText(e.target.value);
                                setModifiedSettings({ ...modifiedSettings, githubRepository: e.target.value });
                            }
                        }}
                        disabled={(allowExportToGithubChecked === false || overrideGlobalSettingsChecked === false)}
                    />
                </div>
            </div>
            <div className='row ml-0 pt-3 pl-0 ml-0'>
                <div className='col-12  pl-0 ml-0'>
                    <div className='card col-5 bg-warning'>
                        <p className='pb-0 mb-0'>You have to install the GitHub app to the account or organization that the repository belongs to. Please use <a href="https://github.com/apps/betatestmyproduct">this link.</a></p>
                    </div>
                </div>
            </div>
            <hr />
            <div className='row pl-0 ml-0 mb-2'>
                <h4>Asana</h4>
                <span>
                    <CheckBox
                        displayName='Auto Export to Asana'
                        name='allowExportToAsana'
                        checked={allowExportToAsanaChecked}
                        onChange={(e) => {
                            if (typeof modifiedSettings?.allowExportToAsana === "boolean") {
                                const modified = modifiedSettings;
                                delete modified.allowExportToAsana;
                                console.log('deleting allowExportToAsana', modified);
                                setModifiedSettings({ ...modified });
                            }
                            else {
                                setModifiedSettings({ ...modifiedSettings, allowExportToAsana: !allowExportToAsanaChecked });
                            }
                            setAllowExportToAsanaChecked(!allowExportToAsanaChecked);
                        }}
                        formCheckExtras='mt-0 mb-0 ml-2'
                        limitCheckBoxHeight={true}
                        disabled={(typeof betaTestName === 'string' && !overrideGlobalSettingsChecked)}
                    />
                </span>
            </div>
            <div className='row col-12 ml-0 pl-0 pr-0'>
                {!isAsanaAuthenticated ?
                    (<span className='col-lg-3 col-md-7 col-sm-12 pl-0 ml-0'>
                        <LoginAsana clientId={process.env.REACT_APP_ASANA_CLIENT_ID}
                            redirectUri={asanaRedirectUrl}
                            className="btn btn-block btn-asana auth-form-btn"
                            children={(<><i className="mdi asana mr-2"></i>Connect using Asana</>)}
                            disabled={(allowExportToAsanaChecked === false || overrideGlobalSettingsChecked === false)}
                            onSuccess={onAsanaSuccess}
                            onFailure={onAsanaFailure}
                            scope="default"
                        />
                    </span>)
                    :
                    (<span className='col-lg-3 col-md-7 col-sm-12 pl-0 ml-0  pb-2 mb-2'>
                        <button className={disconnectAsanaButtonClass}
                            disabled={(allowExportToAsanaChecked === false || overrideGlobalSettingsChecked === false)}
                            onClick={(e) => {
                                e.preventDefault();
                                if ((!isAsanaDisconnecting) === true) {
                                    setModifiedSettings({ ...modifiedSettings, isAsanaAuthenticated: false });
                                }
                                else {
                                    const { isAsanaAuthenticated, ...modifiedSettingsWithoutAsana } = modifiedSettings;
                                    setModifiedSettings(modifiedSettingsWithoutAsana);
                                }

                                setIsAsanaDisconnecting(!isAsanaDisconnecting);
                            }}
                        >
                            <i className="mdi asana mr-2"></i>
                        </button>
                    </span>)}
                <div className='row col-12 ml-0 pl-0 pr-0 mt-3'>
                    <div className='col-lg-3 col-md-7 col-sm-12 pl-0 ml-0'>
                        <TextField
                            label='Asana Project Id'
                            name='asanaProjectId'
                            value={asanaProjectIdText}
                            onChange={(e) => {
                                if (e.target.value === '') {
                                    const modified = modifiedSettings;
                                    delete modified.asanaProjectId;
                                    console.log('deleting asanaProjectId', modified);
                                    setAsanaProjectIdText('');
                                    setModifiedSettings({ ...modified });
                                }
                                else {
                                    setAsanaProjectIdText(e.target.value);
                                    setModifiedSettings({ ...modifiedSettings, asanaProjectId: e.target.value });
                                }
                            }}
                            disabled={(allowExportToAsanaChecked === false || overrideGlobalSettingsChecked === false)}
                        />
                    </div>
                </div>
            </div>
            <hr />
            {false && (<><h4>Jira</h4>
                <div className='card h-100 w-100'>
                    <div className='row col-12 ml-0 pl-0 pr-0 mb-3'>
                        {!isJiraAuthenticated ?
                            (<span className='col-3'>
                                <LoginJira clientId={process.env.REACT_APP_ATLASSIAN_CLIENT_ID}
                                    redirectUrl={process.env.REACT_APP_ASANA_REDIRECT_URL} //this is fine
                                    className="btn btn-block btn-jira auth-form-btn"
                                    children={(<><i className="mdi jira mr-2"></i>Connect using Jira</>)}
                                    onSuccess={onJiraSuccess}
                                    onFailure={onJiraFailure}
                                    scope="read:me offline_access read:jira-work write:jira-work"
                                    responseType="code"
                                />
                            </span>)
                            :
                            (<span className='col-3'>
                                <button className={disconnectJiraButtonClass}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if ((!isJiraDisconnecting) === true) {
                                            setModifiedSettings({ ...modifiedSettings, isJiraAuthenticated: false });
                                        }
                                        else {
                                            const { isJiraAuthenticated, ...modifiedSettingsWithoutJira } = modifiedSettings;
                                            setModifiedSettings(modifiedSettingsWithoutJira);
                                        }

                                        setIsJiraDisconnecting(!isJiraDisconnecting);
                                    }}
                                >
                                    <i className="mdi jira mr-2"></i>
                                </button>
                            </span>)}
                        <div className='col-2 pr-0 mr-0'>
                            <CheckBox
                                displayName='Auto Export to Jira'
                                name='allowExportToJira'
                                checked={allowExportToJiraChecked}
                                onChange={(e) => {
                                    setAllowExportToJiraChecked(!allowExportToJiraChecked);
                                    if (typeof modifiedSettings?.allowExportToJira === "boolean") {
                                        const modified = modifiedSettings;
                                        delete modified.allowExportToJira;
                                        console.log('deleting allowExportToJira', modified);
                                        setModifiedSettings({ ...modified });
                                    }
                                    else {
                                        setModifiedSettings({ ...modifiedSettings, allowExportToJira: !allowExportToJiraChecked });
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12 col-sm-12 row'>
                        <div className='col-lg-6 col-md-12 col-sm-12'>
                            <TextField
                                label="Jira Url"
                                name="jiraUrl"
                                placeholder="ex. btmp or btmp.atlassian.net"
                                value={jiraUrlText}
                                onChange={(e) => {
                                    if (e.target.value === '') {
                                        const modified = modifiedSettings;
                                        delete modified.jiraUrl;
                                        console.log('deleting jiraUrl', modified);
                                        setJiraUrlText('');
                                        setModifiedSettings({ ...modified });
                                        return;
                                    }
                                    else {
                                        setJiraUrlText(e.target.value);
                                        setModifiedSettings({ ...modifiedSettings, jiraUrl: e.target.value });
                                    }
                                }}
                            />
                        </div>
                        <div className='col-lg-6 col-md-12 col-sm-12'>
                            <TextField
                                label="Jira Project Key"
                                name="jiraProjectKey"
                                value={jiraProjectKeyText}
                                onChange={(e) => {
                                    if (e.target.value === '') {
                                        const modified = modifiedSettings;
                                        delete modified.jiraProjectKey;
                                        console.log('deleting jiraProjectKey', modified);
                                        setJiraProjectKeyText('');
                                        setModifiedSettings({ ...modified });
                                        return;
                                    }
                                    else {
                                        setJiraProjectKeyText(e.target.value);
                                        setModifiedSettings({ ...modifiedSettings, jiraProjectKey: e.target.value });
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12 col-sm-12 row'>
                        <div className='col-lg-6 col-md-12 col-sm-12'>
                            <TextField
                                label="Jira Request Issue Type"
                                name="jiraRequestIssueType"
                                value={jiraRequestIssueTypeText}
                                onChange={(e) => {
                                    if (e.target.value === '') {
                                        const modified = modifiedSettings;
                                        delete modified.jiraRequestIssueType;
                                        console.log('deleting jiraRequestIssueType', modified);
                                        setJiraRequestIssueTypeText('');
                                        setModifiedSettings({ ...modified });
                                        return;
                                    }
                                    else {
                                        setJiraRequestIssueTypeText(e.target.value);
                                        setModifiedSettings({ ...modifiedSettings, jiraRequestIssueType: e.target.value });
                                    }
                                }}
                            />
                        </div>
                        <div className='col-lg-6 col-md-12 col-sm-12'>
                            <TextField
                                label="Jira Bug Issue Type"
                                name="jiraBugIssueType"
                                value={jiraBugIssueTypeText}
                                onChange={(e) => {
                                    if (e.target.value === '') {
                                        const modified = modifiedSettings;
                                        delete modified.jiraBugIssueType;
                                        console.log('deleting jiraBugIssueType', modified);
                                        setJiraBugIssueTypeText('');
                                        setModifiedSettings({ ...modified });
                                        return;
                                    }
                                    else {
                                        setJiraBugIssueTypeText(e.target.value);
                                        setModifiedSettings({ ...modifiedSettings, jiraBugIssueType: e.target.value });
                                    }
                                }}
                            />
                        </div>
                    </div>

                </div></>)}
        </div>
    )
}

export default IntegrationSettings;