import React, { useEffect, useState } from "react";

const DashboardCard = ({ title, count, background, fontSize, cardId, minHeight, ...props }) => {
    return (
        <div class="col-auto mb-3" id={cardId}>
            <div class="card text-white shadow w-47" style={{ minHeight: minHeight ? minHeight : '0px' }}>
                <div class={`card-body d-flex flex-column p-3 rounded ${background}`}>
                    <h5 class="card-title mb-auto">{title}</h5>
                    <p class="display-4 mb-0 mt-auto" style={{ fontSize: fontSize ? fontSize : '108px' }}>{count}</p>
                </div>
            </div>
        </div>
    )
}

export default DashboardCard;