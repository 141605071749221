import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import FetchWrapper from '../../util/fetchWrapper';
import DataTable from 'react-data-table-component';
import 'react-tabs/style/react-tabs.css';
import moment from 'moment';

const Betatests = () => {
    const fw = FetchWrapper();
    const [betaTests, setBetaTests] = useState([]);
    const [isTester, setIsTester] = useState(false);
    const [hasAddedColumns, setHasAddedColumns] = useState(false);

    const columns = React.useMemo(
        () => [
            {
                name: 'Title',
                selector: row => row.title,
                sortable: true,
            },
            {
                name: 'Testers',
                selector: row => row.description,
                cell: row => {
                    return <>{row.numberOfTesters}/{row.maxNumbersOfTesters}</>
                }
            },
            {
                name: 'End Date',
                selector: row => row.endDate,
                format: row => moment(row.endDate).format('MM/DD/YYYY'),
                sortable: true,
            },
            {
                name: 'Is Active',
                selector: row => row.isActive,
                cell: row => {
                    return <div style={{ paddingLeft: '1rem' }} className='disabled-checkbox-input'>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input
                                    readOnly={true}
                                    disabled={!row.isActive}
                                    checked={row.isActive}
                                    name={`isActive-${row.id}`}
                                    type="checkbox"
                                    className="form-check-input form-control disabled-checkbox-input"
                                />
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                },
                sortable: true,
            },
        ],
        [betaTests],
    );

    if (betaTests.length > 0 && hasAddedColumns === false) {
        console.log('adding columns');
        columns.push({
            selector: row => row.id,
            sortable: true,
            cell: row => {
                return (
                    <>
                        {/* {isTester ?
                            <Link to={`/betatests/${encodeURIComponent(row.title)}`}><button className='btn btn-sm btn-gradient-primary mr-2'>View</button></Link>
                            : */}
                        <Link to={`/betatests/${encodeURIComponent(row.title)}`}><button className='btn btn-sm btn-gradient-primary mr-2'>View</button></Link>
                        {/* } */}

                    </>
                )
            }
        });
        setHasAddedColumns(true);
    }

    useEffect(() => {
        if (betaTests.length === 0) {
            fw.get('/betatests/get-beta-tests').then((data) => {
                if (data.errorMessage) {
                    console.log(data.errorMessage);
                }
                setIsTester(data.isTester);
                setBetaTests(data.tests);
                console.log(data);
            });
        }
    }, []);

    return (
        <>

            {!isTester && (<div className="row">
                <div className="col-6">
                    <Link to={`/betatests/create`}>
                        <button
                            className="btn btn-sm btn-gradient-primary h-auto"
                        >
                            Create Beta Test
                        </button>
                    </Link>
                </div>
            </div>)}
            {!isTester && (<br />)}
            <DataTable
                title="Beta Tests"
                columns={columns}
                data={betaTests}
                pagination />
        </>
    );
};

export default Betatests;