import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const AlertModal = ({ showModal, setShowModal, onAfterHideModal, ...props }) => {
    // const [show, setShow] = useState();
    const handleHide = () => {
        setShowModal(false);
        if (onAfterHideModal) {
            onAfterHideModal();
        }
    }

    let displayMessage;
    if (typeof props.message === 'string') {
        displayMessage = props.message;
    } else if (typeof props.message === 'object') {
        displayMessage = props.message.map((msg) => {
            return <p>{msg.toString()}</p>
        });
    }
    return (
        <>
            <Modal show={showModal} onHide={handleHide}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{displayMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant='primary' onClick={handleHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AlertModal;