import React, { Component, Suspense, lazy, useState } from 'react';
import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom';

import { Spinner } from '../app/shared/Spinner';

import Dashboard from './dashboard/Dashboard.js';
import Buttons from './basic-ui/Buttons.js';
import Dropdowns from './basic-ui/Dropdowns.js';
import Typography from './basic-ui/Typography.js';


import BasicElements from './form-elements/BasicElements.js';
import BasicTable from './tables/BasicTable.js';
import Mdi from './icons/Mdi.js';
import ChartJs from './charts/ChartJs.js';
import Login from './user/Login.js';
import ResetPassword from './user/ResetPassword';
import Register from './user/Register.js';
import Lockscreen from './user/Lockscreen.js';
import Error404 from './error-pages/Error404.js';
import Error500 from './error-pages/Error500.js';
import BlankPage from './general-pages/BlankPage.js';
import Stripe from './checkout/Stripe.js';
import Roles from './roles/Roles';
import Users from './users/Users';
import AddOrEditUser from './users/AddOrEditUser';
import Permissions from './permissions/Permissions';
import AddOrEditPermission from './permissions/AddOrEditPermissions';
import AddOrEditRole from './roles/AddOrEditRole';
import ReadMessage from './messages/ReadMessage';
import Betatests from './beta-tests/Betatests';
import ModifyBetaTest from './beta-tests/ModifyBetaTest';
import ModifyTicket from './beta-tests/ModifyTicket';
import ModifyTester from './beta-tests/ModifyTester';
import Settings from './usersettings/Settings';
import BetaTestContainer from './beta-tests/BetaTestContainer';
import BetaTestSettings from './beta-tests/BetaTestSettings';
import ViewTickets from './beta-tests/ViewTickets';
import { GoogleOAuthProvider } from '@react-oauth/google';

export const AppRoutes = ({ currentUser, setCurrentUser, setShouldRenderSettings, setSideBarBetaTestName }) => {
  // console.log('in AppRoutes.js');
  const isLocal = process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'local';
  return (
    !currentUser?.isAuthenticated ?
      <Switch>
        <Route path="/user/register" render={(props) => <Register {...props} setUser={setCurrentUser} user={currentUser} />} />
        <Route path="/user/login/" render={(props) =>
          <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
            <Login {...props} setUser={setCurrentUser} user={currentUser} />
          </GoogleOAuthProvider>
        } />
        <Route path="/user/reset-password/" render={(props) => <ResetPassword {...props} setUser={setCurrentUser} user={currentUser} />} />
        {/* <Route path="/user/login/:emailToken" render={(props) => <Login {...props} setUser={setCurrentUser} user={currentUser} />} /> */}
      </Switch>
      :
      <Switch>
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/basic-ui/buttons" component={Buttons} />
        <Route path="/basic-ui/dropdowns" component={Dropdowns} />
        <Route path="/basic-ui/typography" component={Typography} />
        <Route path="/form-Elements/basic-elements" component={BasicElements} />
        <Route path="/tables/basic-table" component={BasicTable} />
        <Route path="/icons/mdi" component={Mdi} />
        <Route path="/charts/chart-js" component={ChartJs} />
        <Route path="/error-pages/error-404" component={Error404} />
        <Route path="/error-pages/error-500" component={Error500} />
        <Route path="/general-pages/blank-page" component={BlankPage} />
        <Route path="/user/lockscreen" component={Lockscreen} />
        <Route path="/checkout" component={Stripe} />
        <Route path="/users/edit/:userId" component={AddOrEditUser} />
        <Route path="/users/add" component={AddOrEditUser} />
        <Route path="/users" component={Users} />
        {/* <Route path="/user/login" render={(props) => <Login {...props} user={currentUser} />} /> */}
        <Route path="/roles/edit/:roleId" component={AddOrEditRole} />
        <Route path="/roles/add" component={AddOrEditRole} />
        <Route path="/roles" component={Roles} />
        <Route path="/permissions/edit/:permissionId" component={AddOrEditPermission} />
        <Route path="/permissions/add" component={AddOrEditPermission} />
        <Route path="/permissions" component={Permissions} />
        <Route path="/messages/view/:messageId" component={ReadMessage} />
        <Route path="/betatests/create" render={(props) => <BetaTestContainer {...props} currentUser={currentUser} isAdd={true} setSideBarBetaTestName={setSideBarBetaTestName} />} />
        <Route path="/betatests/:betaTestName/:ticketType/edit/:ticketId" render={(props) => <BetaTestContainer {...props} currentUser={currentUser} isView={true} setShouldRenderSettings={setShouldRenderSettings} setSideBarBetaTestName={setSideBarBetaTestName} />} />
        <Route path="/betatests/:betaTestName/tickets/:ticketType/edit/:ticketId" render={(props) => <BetaTestContainer {...props} currentUser={currentUser} setShouldRenderSettings={setShouldRenderSettings} setSideBarBetaTestName={setSideBarBetaTestName} viewTickets={true} />} />
        <Route path="/betatests/:betaTestName/tickets" render={(props) => <BetaTestContainer {...props} currentUser={currentUser} setShouldRenderSettings={setShouldRenderSettings} setSideBarBetaTestName={setSideBarBetaTestName} viewTickets={true} />} />
        <Route path="/betatests/:betaTestName/settings" render={(props) => <BetaTestContainer {...props} currentUser={currentUser} isEdit={true} setShouldRenderSettings={setShouldRenderSettings} setSideBarBetaTestName={setSideBarBetaTestName} />} />
        <Route path="/betatests/:betaTestName" render={(props) => <BetaTestContainer {...props} currentUser={currentUser} isView={true} setShouldRenderSettings={setShouldRenderSettings} setSideBarBetaTestName={setSideBarBetaTestName} />} />

        {/* <Route path="/betatests/edit/:betaTestName/settings" render={(props) => <BetaTestSettings {...props} currentUser={currentUser} />} /> */}
        {/* <Route path="/betatests/edit/:betaTestName/:ticketType/edit/:ticketId" render={(props) => <BetaTestContainer {...props} currentUser={currentUser} isEdit={true} setShouldRenderSettings={setShouldRenderSettings} setSideBarBetaTestName={setSideBarBetaTestName} />} /> */}


        <Route path="/betatests" component={Betatests} />
        <Route path="/tickets/view/:ticketId" render={(props) => <ModifyTicket {...props} currentUser={currentUser} isView={true} />} />
        <Route path="/tickets/edit/:ticketId" render={(props) => <ModifyTicket {...props} currentUser={currentUser} isEdit={true} />} />
        <Route path="/user-settings" render={(props) => <Settings {...props} currentUser={currentUser} />} />
        <Route path="/">
          <Redirect to="/betatests" />
        </Route>
        <Route path="*" >
          <Redirect to="/error-pages/error-404" />
        </Route>
      </Switch>
  );

}