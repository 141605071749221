import React, { useState, useEffect } from 'react';
import fetchWrapper from '../../util/fetchWrapper';
import DataTable from 'react-data-table-component';

const Permissions = (props) => {
    const [permissions, setPermissions] = useState(undefined);
    const [displayData, setDisplayData] = useState(undefined);
    const fw = fetchWrapper();
    const [hideFilters, setHideFilters] = useState(true);
    const [showisDeleted, setShowisDeleted] = useState(false);

    const filterData = (data) => {
        return data?.filter(function (permission, index) {
            if ((!showisDeleted && !permission.isGranted)) {
                return;
            }

            return permission;
        });
    }

    useEffect(() => {
        setDisplayData(filterData(permissions));
    }, [showisDeleted]);

    useEffect(() => {
        console.log('attempting to get permissions');
        fw.get(`/permissions/get-permissions`)
            .then(data => {
                console.log(`permissions: ${JSON.stringify(data.permissions)}`);
                setPermissions(data.permissions);
                setDisplayData(filterData(data.permissions));
            });
    }, []);

    const editPermission = (permissionId) => {
        window.location = `/permissions/edit/${permissionId}`;
    }

    const deletePermission = (permissionId) => {
        // fw.post(`${process.env.REACT_APP_API_URL}`)
    }

    const columns = React.useMemo(
        () => [
            {
                name: 'Name',
                selector: row => row.name,
                sortable: true,
            },
            {
                name: 'Description',
                selector: row => row.description,
                sortable: true,
            },
            {
                name: 'Is Active',
                selector: row => row.isGranted,
                cell: row => {
                    return <div style={{ paddingLeft: '1rem' }}>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input
                                    readOnly={true}
                                    checked={row.isGranted}
                                    name={`isActive-${row.id}`}
                                    type="checkbox"
                                    className="form-check-input form-control"
                                />
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                },
                sortable: true,
            },
            {
                selector: row => row.id,
                sortable: true,
                cell: row => {
                    return (
                        <>
                            <button className='btn btn-gradient-primary mr-2' onClick={() => { editPermission(row.id) }}>Edit</button>
                            <button className='btn btn-light' onClick={() => { deletePermission(row.id) }}>Delete</button>
                        </>
                    )
                }
                // omit: true,
            },
        ],
        [permissions],
    );

    const filtersBoxMarginBottom = hideFilters ? { marginBottom: '0rem' } : { marginBottom: '1rem' };
    const showFiltersMarginBottom = hideFilters ? { marginBottom: '1rem' } : { marginBottom: '0rem' };

    return (
        <>
            <div>
                <button className='btn btn-gradient-primary mr-2'
                    style={{ ...showFiltersMarginBottom }}
                    onClick={() => { setHideFilters(!hideFilters) }}>{hideFilters ? 'Show' : 'Hide'} Filters</button>

                <button className='btn btn-gradient-primary mr-2'
                    onClick={() => { window.location = '/permissions/add' }}
                    style={{ ...showFiltersMarginBottom, float: 'right' }}>+ Add Permission</button>
            </div>
            <div className='card' style={{ ...filtersBoxMarginBottom, paddingLeft: '16px', paddingRight: '8px' }} hidden={hideFilters}>
                <div className='row' style={{ marginLeft: '0px' }}>
                    <h5>Filters</h5>
                </div>
                <div className='row' style={{ marginLeft: '0px' }}>
                    <div className="form-check">
                        <label className="form-check-label" style={{ paddingRight: '.5rem' }}>
                            <input
                                name="isDeleted"
                                type="checkbox"
                                className="form-check-input form-control"
                                onChange={(event) => { setShowisDeleted(!showisDeleted) }}
                            />
                            <i className="input-helper"></i>
                            Show Inactive
                        </label>
                    </div>
                </div>
            </div>
            <DataTable
                title="Permissions"
                columns={columns}
                data={displayData}
                pagination />
        </>
    );
}

export default Permissions;