import React, { useState, useEffect } from 'react';
import moment from 'moment';
import fetchWrapper from '../../util/fetchWrapper';
import DataTable from 'react-data-table-component';

const Users = (props) => {
    const [users, setUsers] = useState(undefined);
    const [displayData, setDisplayData] = useState(undefined);
    const [hideFilters, setHideFilters] = useState(true);
    const [showisDeleted, setShowisDeleted] = useState(false);
    const [showIsInActive, setShowIsInActive] = useState(false);
    const fw = fetchWrapper();

    const filterData = (data) => {
        return data?.filter(function (user, index) {
            if ((!showisDeleted && user.isDeleted) || (!showIsInActive && !user.isActive)) {
                return;
            }

            return user;
        });
    }

    useEffect(() => {
        fw.get(`/users/get-users`)
            .then(data => {
                const scrubbedData = data.filter(function (user, index) {
                    user.isActive = user.isActive;
                    console.log(`setting isActive to ${user.isActive} for id: ${user.id}`);
                    return user;
                });
                setUsers(scrubbedData);
                setDisplayData(filterData(scrubbedData));
            });
    }, []);

    useEffect(() => {
        if (showisDeleted || showIsInActive) {
            const scrubbedData = filterData(users);
            setDisplayData(scrubbedData);
        }
        else {
            setDisplayData(filterData(users));
        }
    }, [showisDeleted, showIsInActive]);

    const editUser = (id) => {
        console.log(id);
        window.location = `/users/edit/${id}`;
    };

    const deleteUser = (userId) => {
        console.log(userId);
        fw.post(`/users/delete-user`, { userId });
    };

    const unDeleteUser = (userId) => {

    }

    const columns = React.useMemo(
        () => [
            {
                name: 'Name',
                selector: row => row.fullName,
                sortable: true,
            },
            {
                name: 'Email',
                selector: row => row.emailAddress,
                sortable: true,
            },
            {
                name: 'Is Active',
                selector: row => row.isActive,
                cell: row => {
                    return <div style={{ paddingLeft: '1rem' }}>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input
                                    checked={row.isActive}
                                    name={`isActive-${row.id}`}
                                    type="checkbox"
                                    className="form-check-input form-control"
                                />
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                },
                sortable: true,
            },
            {
                name: 'Member Since',
                selector: row => row.creationTime,
                format: row => moment(row.creationTime).format('lll'),
                sortable: true,
            },
            {
                selector: row => row.id,
                sortable: true,
                cell: row => {
                    return (
                        <>
                            <button className='btn btn-gradient-primary mr-2' onClick={() => { editUser(row.id) }}>Edit</button>
                            <button
                                className='btn btn-light'
                                onClick={() => { deleteUser(row.id) }}
                                disabled={row?.isDeleted}
                            >Delete</button>
                        </>
                    )
                }
            },
        ],
        [users],
    );

    const filtersBoxMarginBottom = hideFilters ? { marginBottom: '0rem' } : { marginBottom: '1rem' };
    const showFiltersMarginBottom = hideFilters ? { marginBottom: '1rem' } : { marginBottom: '0rem' };

    return (
        <>
            <div className=''>
                <button className='btn btn-gradient-primary mr-2'
                    style={{ ...showFiltersMarginBottom }}
                    onClick={() => { setHideFilters(!hideFilters) }}>{hideFilters ? 'Show' : 'Hide'} Filters</button>

                <button className='btn btn-gradient-primary mr-2'
                    onClick={() => { window.location = '/users/add' }}
                    style={{ ...showFiltersMarginBottom, float: 'right' }}>+ Add User</button>
            </div>
            <div className='card' style={{ ...filtersBoxMarginBottom, paddingLeft: '16px', paddingRight: '8px' }} hidden={hideFilters}>
                <div className='row' style={{ marginLeft: '0px' }}>
                    <h5>Filters</h5>
                </div>
                <div className='row' style={{ marginLeft: '0px' }}>
                    <div className="form-check">
                        <label className="form-check-label" style={{ paddingRight: '.5rem' }}>
                            <input
                                name="isDeleted"
                                type="checkbox"
                                className="form-check-input form-control"
                                onChange={(event) => { setShowisDeleted(!showisDeleted) }}
                            />
                            <i className="input-helper"></i>
                            Show Deleted
                        </label>
                    </div>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input
                                name="isActive"
                                type="checkbox"
                                className="form-check-input form-control"
                                onChange={(event) => { setShowIsInActive(!showIsInActive) }}
                            />
                            <i className="input-helper"></i>
                            Show Inactive
                        </label>
                    </div>
                </div>
            </div>
            <DataTable
                title="Users"
                columns={columns}
                data={displayData}
                pagination />
        </>);
}

export default Users;