import React, { useState, useEffect } from 'react';
import DropDown from '../shared/DropDown'
import { TICKET_TYPES } from '../models/enums/ticketsEnum';
import FetchWrapper from '../../util/fetchWrapper';
import DataTable from 'react-data-table-component';
import 'react-tabs/style/react-tabs.css';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import FiltersPanel from '../shared/FiltersPanel';
import AddTicketButtons from './AddTicketButtons';

const ViewTickets = ({
    betaTestName,
    setShouldGoBackOnClose,
    setShowTicketModal,
    setTicketTypeId,
    setTicketIsAdd,
    setTicketIsEdit,
    setTicketIsView }) => {
    const [tickets, setTickets] = useState([]);
    const [ticketFilter, setTicketFilter] = useState(TICKET_TYPES.ALL);
    const fw = FetchWrapper();
    const history = useHistory();
    useEffect(() => {
        fw.get(`/tickets/get-tickets?ticketTypeId=${ticketFilter}&pageSize=10&pageNumber=1&betaTestName=${betaTestName}`)
            .then(response => {
                if (response.errorMessage) {
                    console.error(response.errorMessage);
                    return;
                }
                setTickets(response.tickets);
            })
    }, [ticketFilter]);

    const ticketTypes = [
        <option key={TICKET_TYPES.ALL} value={TICKET_TYPES.ALL}>All</option>,
        <option key={TICKET_TYPES.BUG} value={TICKET_TYPES.BUG}>Bug</option>,
        <option key={TICKET_TYPES.REQUEST} value={TICKET_TYPES.REQUEST}>Request</option>
    ];

    const columns = React.useMemo(
        () =>
            [
                {
                    name: 'Title',
                    selector: row => row.title,
                    sortable: true,
                },
                {
                    name: 'Created by',
                    selector: row => row.fullName,
                    sortable: true,
                },
                {
                    name: 'Created Date',
                    selector: row => row.creationTime,
                    format: row => moment(row.creationTime).format('lll'),
                    sortable: true,
                },
                {
                    name: 'Is Active',
                    selector: row => row.isDeleted,
                    cell: row => {
                        return <div style={{ paddingLeft: '1rem' }}>
                            <div className="form-check">
                                <label className="form-check-label">
                                    <input
                                        readOnly={true}
                                        checked={!row.isDeleted}
                                        name={`isActive-${row.ticketId}`}
                                        type="checkbox"
                                        className="form-check-input form-control"
                                    />
                                    <i className="input-helper"></i>
                                </label>
                            </div>
                        </div>
                    },
                    sortable: true,
                },
                {
                    selector: row => row.ticketId,
                    sortable: true,
                    cell: row => {
                        return (
                            <>
                                <button className='btn btn-sm btn-gradient-primary mr-2'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const newLocation = `/betatests/${betaTestName}/tickets/bug/edit/${row.ticketId}`;
                                        if (window.location.href.includes(newLocation)) {
                                            setShowTicketModal(true);
                                        }
                                        else {
                                            setShouldGoBackOnClose(true);
                                            history.push(newLocation);
                                        }
                                    }}>
                                    Edit
                                </button>
                            </>
                        )
                    }
                },
            ],
        [tickets],
    );

    return (
        <>
            <div className='card'>
                <div className='card-body'>
                    <div className='card-title'>
                        <div className='row'>
                            <div className='col-9'>
                                <h3>View Tickets</h3>
                            </div>
                            <div className='col-3 mr-0 pr-0'>
                                {/* <AddTicketButtons
                                    betaTestName={betaTestName}
                                    setShowTicketModal={setShowTicketModal}
                                    setTicketTypeId={setTicketTypeId}
                                    setTicketIsAdd={setTicketIsAdd}
                                    setTicketIsEdit={setTicketIsEdit}
                                    setTicketIsView={setTicketIsView}
                                    setShouldGoBackOnClose={setShouldGoBackOnClose}
                                /> */}
                                <AddTicketButtons
                                    setTicketTypeId={setTicketTypeId}
                                    setTicketIsAdd={setTicketIsAdd}
                                    setTicketIsEdit={setTicketIsEdit}
                                    setTicketIsView={setTicketIsView}
                                    setShowTicketModal={setShowTicketModal}
                                    setShouldGoBackOnClose={setShouldGoBackOnClose}
                                />
                            </div>
                        </div>
                    </div>
                    <hr />

                    <FiltersPanel
                        showTypeFilter={true}
                        typeFilterOptions={ticketTypes}
                        typeFilterOnChange={setTicketFilter}
                        typeFilterLabel={"Ticket Type"}
                        typeFilterValueProp={ticketFilter}
                    />

                    <DataTable
                        columns={columns}
                        data={tickets}
                        pagination />
                </div>
            </div>
        </>
    )
};

export default ViewTickets;