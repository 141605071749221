import React from 'react';

const DropDown = ({ label, labelFor, options, isDisabled, onChangeCallback, valueProp, inputName, labelId, ...props }) => {
    return (
        <>
            {label && (<label id={labelId} htmlFor={labelFor}>{label}</label>)}
            <select
                className="form-control d-flex search-field form-group text-field-input-wrapper"
                id={labelFor}
                value={valueProp}
                onChange={onChangeCallback}
                disabled={isDisabled}
                name={inputName}
                style={{ height: props.dropDownHeight, minHeight: props.dropDownHeight }}
            >
                {options}
            </select>
        </>
    )
}

export default DropDown;