import React, { useState, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useHistory } from 'react-router-dom';
import { TextField } from '../form-elements/TextField.js';
import { TextArea } from '../form-elements/TextArea.js';
import { CheckBox } from '../form-elements/CheckBox';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FetchWrapper from '../../util/fetchWrapper';
import Comments from '../shared/Comments';
import ConfirmationModal from '../shared/ConfirmationModal';

const ModifyTester = ({ testerId, betaTestId, isAdd, isEdit, isModalRender, setShowTesterModal, ...props }) => {
    const reference = useRef(null);
    const reference2 = useRef(null);
    const [tester, setTester] = useState(undefined);
    const [comments, setComments] = useState([]);
    const [isDisputing, setIsDisputing] = useState(false);
    const fw = FetchWrapper();

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [confirmationModalMessage, setConfirmationModalMessage] = useState('');
    const [confirmationModalTitle, setConfirmationModalTitle] = useState('');
    const [disputeDate, setDisputeDate] = useState(undefined);

    const submitButtonRef = useRef(null);
    const paramTesterId = props?.match?.params?.testerId ?? undefined;
    const paramBetaTestId = props?.match?.params?.betaTestId ?? undefined;
    const history = useHistory();

    const normalizedTesterId = paramTesterId ?? testerId;
    const normalizedBetaTestId = paramBetaTestId ?? betaTestId;

    const loadData = () => {
        if (!isAdd) {
            fw.get(`/testers/get-tester-by-id?testerId=${normalizedTesterId}&betaTestId=${normalizedBetaTestId}`)
                .then(data => {
                    if (data.errorMessage) {
                        //todo show modal
                        console.log('error', data.errorMessage);
                        return;
                    }
                    if (data.tester.disputeDate) {
                        setDisputeDate(moment(data.tester.disputeDate).toDate());
                        setIsDisputing(true);
                    }
                    console.log('tester data:', data);
                    setComments(data.comments);
                    setTester(data.tester);
                });
        }

    }

    useEffect(() => {
        loadData();
    }, []);

    const cancelEdit = (e) => {
        e.preventDefault();
        history.goBack();
    }

    let initialValues = {
        name: tester?.fullName ?? '',
        description: tester?.description ?? '',
        isDeleted: tester?.disputeDate ? true : false,
        joinedDate: [null, undefined, ''].indexOf((tester?.joinedDate ?? null)) === -1 ? moment(tester?.joinedDate).format('l') : moment(new Date()).format('l'),
        disputeDate: tester?.disputeDate ? moment(tester?.disputeDate).format('lll') : null,
        disputeComment: tester?.disputeComment ?? ''
    };

    const displayConfirmationModal = (title, message) => {
        setConfirmationModalMessage(message);
        setConfirmationModalTitle(title);
        setShowConfirmationModal(true);
    }

    const handleSubmit = (values) => {
        console.log(values);
        if (values.isDeleted) {
            displayConfirmationModal('Dispute Tester', 'Are you sure you want to dispute this tester?');
            return;
        }
        const testerData = {
            id: Number(normalizedTesterId),
            betaTestId: normalizedBetaTestId,
            isDeleted: tester?.isDeleted ?? false,
        }

        if (values.comment) {
            testerData.comment = values.comment;
        }

        if (isDisputing) {
            testerData.disputeDate = moment(disputeDate);
            testerData.disputeComment = values.disputeComment;
        }

        fw.post('/testers/update-tester', testerData)
            .then(data => {
                if (data.errorMessage) {
                    //todo show modal
                    console.log('error', data.errorMessage);
                    return;
                }
                setShowTesterModal(false);
            });
    }

    return (
        <>
            <div className='card'>
                <span className='col-12 modal-close my-h-1 my-max-h-1 pr-0'>
                    <div style={{ float: 'right' }}
                        onClick={() => {
                            if (isModalRender) {
                                setShowTesterModal(false);
                            }
                        }}
                    >
                        {isModalRender && (<i className="mdi mdi-close-box-outline icon-md"
                        // style={{ float: 'right', height: 'calc(150%)', width: 'calc(150%)' }}
                        ></i>)}
                    </div>
                </span>
                <div className='card-body'>
                    <div className='card-title'>
                        <h4>
                            Edit Beta Tester:
                        </h4>
                    </div>
                    <Formik
                        initialValues={initialValues}
                        // validationSchema={validate}
                        validateOnChange={true}
                        enableReinitialize={true}
                        onSubmit={values => {
                            handleSubmit(values);
                        }}
                    >
                        <Form
                            ref={reference}>
                            <div className="row">
                                <div className="col-6">
                                    <TextField
                                        className="h-auto form-control form-control-lg"
                                        label="Name"
                                        name="name"
                                        type="text"
                                        placeholder="Name"
                                        disabled
                                    />
                                </div>
                                <div className="col-6">
                                    <TextField
                                        className="h-auto form-control form-control-lg"
                                        label="Joined Date"
                                        name="joinedDate"
                                        type="text"
                                        disabled
                                    />
                                </div>
                            </div>

                            {initialValues?.disputeDate ?
                                <TextField
                                    className="h-auto form-control form-control-lg"
                                    label="Removal Date"
                                    name="disputeDate"
                                    type="text"
                                    placeholder="Removal Date"
                                    disabled
                                />
                                :
                                <>
                                    {!isAdd && (<div className="row">
                                        <div className="col-6 "

                                        >
                                            <div className="row">
                                                <div className="col-3">
                                                    <CheckBox
                                                        name="isDeleted"
                                                        checked={!tester?.isDeleted}
                                                        displayName="Is Active"
                                                        disabled={tester?.disputeDate ? true : false}
                                                        onChange={(e) => {
                                                            // console.log('target', e.target.checked);
                                                            setTester({ ...tester, isDeleted: !e.target.checked });
                                                        }}
                                                    />
                                                </div>

                                                <div className="col-3">
                                                    <CheckBox
                                                        checked={isDisputing}
                                                        name="isDisputing"
                                                        displayName="Remove Tester"
                                                        onChange={(e) => {
                                                            setIsDisputing(e.target.checked);
                                                            if (e.target.checked && !disputeDate) {
                                                                setDisputeDate(new Date());
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <label htmlFor="disputeDate">Removal Date</label>
                                            <span
                                            >
                                                <DatePicker
                                                    selected={disputeDate}
                                                    name="disputeDate"
                                                    className="form-control"
                                                    disabled={!isDisputing}
                                                    onChange={(date) => setDisputeDate(date)}

                                                />
                                            </span>
                                        </div>
                                    </div>)}
                                </>
                            }

                            {!isAdd && (<TextArea
                                className="h-auto form-control form-control-lg"
                                label="Removal Comment"
                                name="disputeComment"
                                type="text"
                                placeholder="Removal Comment"
                                disabled={!isDisputing || tester?.disputeDate}
                                rows={4}
                            />)}

                            <TextArea
                                className="h-auto form-control form-control-lg"
                                label="Comment"
                                name="comment"
                                type="text"
                                placeholder="Comment"
                                reference={reference2}
                                rows={4} />
                            <Comments
                                comments={comments ?? []}
                            />

                            <button type="submit"
                                ref={submitButtonRef}
                                className="btn btn-gradient-primary mr-2">Submit</button>
                            <button className="btn btn-light"
                                style={{ marginRight: '1.5rem' }}
                                onClick={cancelEdit}>Cancel</button>
                            {tester?.disputeDate &&
                                (<button
                                    className="btn btn-gradient-danger btn-fw"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        displayConfirmationModal('Confirmation', `Are you sure you want to Reactivate user: ${initialValues.name}`);
                                    }}
                                >
                                    Reactivate
                                </button>)
                            }
                        </Form>
                    </Formik>
                </div >
            </div >
            {/* <AlertModal showModal={showAlertModal} setShowModal={setShowAlertModal} message={alertModalMessage} title={alertModalTitle} onAfterHideModal={() => {
                    // window.location = '/roles'
                    setShowAlertModal(false);
                    history.goBack();
                }} />
*/}
            <ConfirmationModal showModal={showConfirmationModal} setShowModal={setShowConfirmationModal} message={confirmationModalMessage} title={confirmationModalTitle}
                onAfterHideModal={() => {
                    setShowConfirmationModal(false);
                }}
                handleConfirmationClick={() => {
                    fw.post('/testers/reactivate-tester', { testerId: tester?.id, betaTestId: tester?.betaTestId })
                        .then(data => {
                            if (data.errorMessage) {
                                //todo show modal
                                console.log('error', data.errorMessage);
                                return;
                            }
                            setShowTesterModal(false);
                        });
                }}
            />
        </>
    )
}

export default ModifyTester;