import React, { useState, useEffect, useRef } from 'react';
import FetchWrapper from '../../util/fetchWrapper';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { TextField } from '../form-elements/TextField.js';
import { Button, Modal, Dropdown } from 'react-bootstrap';

const AddTesterModal = ({ currentUser, betaTestId, showAddTesterModal, setShowAddTesterModal, keyboard, backdrop, onAfterHideModal, ...props }) => {
    const emailRef = useRef();
    const [addErrorMessage, setAddErrorMessage] = useState(undefined);
    const fw = FetchWrapper();
    const handleHide = () => {
        showAddTesterModal(false);
        if (onAfterHideModal) {
            onAfterHideModal();
        }
    }

    const handleSubmit = (values) => {
        if (values.email) {
            fw.post('/testers/invite-tester', { betaTestId, email: values.email })
                .then(data => {
                    if (data.errorMessage) {
                        //todo show alert
                        setAddErrorMessage(data.errorMessage);
                        return;
                    }
                    setShowAddTesterModal(false);
                })
        }
    }

    const cancelEdit = (e) => {
        e.preventDefault();
        setShowAddTesterModal(false);
    }
    const initialValues = {
        email: ''
    }
    return (
        <div className={showAddTesterModal}>
            <Modal show={showAddTesterModal} onShow={() => { (emailRef.current && emailRef.current.focus()) }} onHide={handleHide} backdrop={backdrop} keyboard={keyboard}
                dialogClassName="modal-40w"
                contentClassName="modal-40h"
            >
                <Modal.Body
                    style={{
                        maxHeight: 'calc(100vh)',
                        overflowY: 'auto'
                    }}
                >
                    <div className='card'>
                        <div className='card-body'>
                            <div className='card-title'>
                                <h4>
                                    Invite Beta Tester:
                                </h4>
                            </div>
                            <Formik
                                validateOnChange={true}
                                enableReinitialize={true}
                                initialValues={initialValues}
                                onSubmit={values => {
                                    handleSubmit(values);
                                }}
                            >
                                <Form>
                                    <div className="row">
                                        <div className="col-12">
                                            <TextField
                                                inputRef={emailRef}
                                                className="h-auto form-control form-control-lg"
                                                label="Email"
                                                name="email"
                                                type="text"
                                                placeholder="Email"
                                            />
                                            {addErrorMessage && (<div className="alert alert-danger" role="alert">{addErrorMessage}</div>)}
                                        </div>
                                    </div>


                                    <button type="submit"
                                        className="btn btn-gradient-primary mr-2">Send</button>
                                    <button className="btn btn-light"
                                        style={{ marginRight: '1.5rem' }}
                                        onClick={cancelEdit}>Cancel</button>
                                </Form>
                            </Formik>
                        </div >
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default AddTesterModal;