import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const ConfirmationModal = ({ showModal, setShowModal, onAfterHideModal, confirmText, cancelText, title, message, modalBody, backdrop, keyboard, ...props }) => {
    const handleHide = () => {
        setShowModal(false);
        if (onAfterHideModal) {
            onAfterHideModal();
        }
    }

    let displayMessage;
    if (typeof message === 'string') {
        displayMessage = message;
    } else if (typeof message === 'object') {
        displayMessage = message.map((msg) => {
            return <p>{msg.toString()}</p>
        });
    }

    const displayConfirmationText = confirmText ? confirmText : "Save";
    const displayCancelText = cancelText ? cancelText : "Cancel";

    // console.log('title', title);
    // console.log('displayMessage', displayMessage);

    return (
        <>
            <Modal show={showModal} onHide={handleHide} backdrop={backdrop} keyboard={keyboard}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                {modalBody ?
                    <Modal.Body>{modalBody}</Modal.Body>
                    :
                    <Modal.Body>{displayMessage}</Modal.Body>
                }
                <Modal.Footer>
                    <Button variant='primary' onClick={props.handleConfirmationClick}>{displayConfirmationText}</Button>
                    <Button variant='secondary' onClick={handleHide}>{displayCancelText}</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ConfirmationModal;