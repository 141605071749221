import React, { useState, useEffect } from 'react';
import useCollapse from 'react-collapsed';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import FiltersPanel from '../shared/FiltersPanel';

const TestersCollapse = ({
    currentUser,
    isAdd,
    isView,
    isEdit,
    betaTestId,
    setShowEditTesterModal,
    setIsAddTesterModal,
    setIsEditTesterModal,
    setTesterModalId,
    setShowAddTesterModal,
    ...props }) => {
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
    const [showIsDeleted, setShowIsDeleted] = useState(false);
    const [showIsInactive, setShowIsInactive] = useState(false);

    const columns = React.useMemo(
        () => [
            {
                name: 'Name',
                selector: row => row.fullName,
                sortable: true,
            },
            {
                name: 'Joined Date',
                selector: row => row.joinedDate,
                format: row => moment(row.joinedDate).format('lll'),
                sortable: true,
            },
            {
                name: 'Is Active',
                selector: row => row.isDeleted,
                cell: row => {
                    return <div>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input
                                    readOnly={true}
                                    checked={!row.isDeleted}
                                    name={`isActive-${row.id}`}
                                    type="checkbox"
                                    className="form-check-input form-control"
                                />
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                }
            },
            {
                selector: row => row.id,
                sortable: true,
                cell: row => {
                    return (
                        <>
                            {isView && (<button className='btn btn-sm btn-gradient-primary mr-2'
                                onClick={(e) => {
                                    e.preventDefault();
                                    setTesterModalId(row.id);
                                    setShowEditTesterModal(true);
                                    setIsAddTesterModal(false);
                                }}
                            >View</button>)}

                            {isEdit && (
                                <button className='btn btn-sm btn-gradient-primary mr-2'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setTesterModalId(row.id);
                                        setShowEditTesterModal(true);
                                        setIsAddTesterModal(false);
                                    }}
                                >Edit</button>
                            )}
                        </>
                    )
                }
            }
        ]);

    const addUserButton =
        <a href={`/testers/find?betaTestId=${betaTestId}`}>
            <button
                className="btn btn-sm btn-gradient-primary h-auto"
                style={{ marginLeft: '1rem' }}
                onClick={(e) => {
                    e.preventDefault();
                    setIsAddTesterModal(true);
                    setShowAddTesterModal(true);
                    // window.location = `/testers/find?betaTestId=${betaTestId}`;

                }}
            >
                Add Tester
            </button>
        </a>;

    return (
        <div className="collapsible">
            <h4 className="header" {...getToggleProps()}>
                Testers {isExpanded ? <i className="mdi mdi-arrow-up"></i> : <i className=" mdi mdi-arrow-down"></i>}
            </h4>
            <div {...getCollapseProps()}>
                <div className="content">
                    <FiltersPanel
                        showIsDeleted={showIsDeleted}
                        setShowIsDeleted={setShowIsDeleted}
                        showIsInActive={showIsInactive}
                        setShowIsInActive={setShowIsInactive}
                        otherButtons={[addUserButton]}
                        showInactiveFilter={true}
                        showDeletedFilter={true}
                    />
                    {props.testers ?
                        <>
                            <DataTable
                                columns={columns}
                                data={props.testers}
                                pagination />
                        </>
                        :
                        <>
                            <p>No Testers yet!</p>
                        </>}
                </div>
            </div>
        </div>
    );
}

export default TestersCollapse;