import * as Yup from 'yup';
import moment from 'moment';

const viewBetaTestImpl = ({
    currentUser,
    betaTest,
    setCanUploadFiles,
    setBetaTestPrivacyModes,
    setTesters,
    setIsTesterJoined,
    setIsActiveChecked,
    setUploadedFiles,
    setBetaTest,
    history,
    fw,
    queryParamCode,
    setAllowedUrl,
    setShouldRenderSettings,
    ...props }) => {
    const onPageLoad = (data) => {
        console.log('view beta test data', data)
        if (data.redirectUrl) {
            window.location.href = data.redirectUrl;
        }
        setShouldRenderSettings(true);
        setCanUploadFiles(data.canUploadFiles);
        setBetaTestPrivacyModes(data.statuses);
        setTesters(data.testers);
        setIsTesterJoined(data.isTesterJoined);
        setIsActiveChecked(data.betaTest.isActive);
        setUploadedFiles(data.files);
        setAllowedUrl(data.approvedUrlOrigin);
        setBetaTest(data.betaTest);
    }

    const getInitialValues = (data) => {
        return {
            title: data?.title ?? '',
            description: data?.description ?? '',
            isDeleted: data?.isDeleted ?? false,
            incentives: data?.incentives ?? '',
            startDate: moment(data?.startDate).toDate(),
            endDate: moment(data?.endDate).toDate(),
            numberOfTesters: data?.maxNumbersOfTesters ?? 0,

        };
    }

    const getValidation = () => {
        return Yup.object({
            title: Yup.string()
                .required('Title is required'),
            description: Yup.string()
                .required('Description is required'),
            startDate: Yup.date()
                .nullable()
                .required('Start date is required'),
            endDate: Yup.date()
                .nullable()
                .required('End date is required'),
            numberOfTesters: Yup.number('This must be a number between 1-1000000')
                .min(1, 'This must be greater than 0')
                .required('Number of testers is required'),
        });
    }

    const saveButtonClick = () => { }

    return {
        onPageLoad,
        getInitialValues,
        getValidation,
        saveButtonClick
    };
}

export default viewBetaTestImpl;