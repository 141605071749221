import React, { useEffect, useState } from 'react';
import fetchWrapper from '../../util/fetchWrapper';

const GetCurrentUser = () => {
  const [currentUser, setCurrentUser] = useState(undefined);
  const fw = fetchWrapper();

  useEffect(() => {
    if (currentUser) {
      console.log(`returning currentUser from userService.js: ${JSON.stringify(currentUser)} from getCurrentUser`);
      return;
    }

    fw.post(`/authentication/get-logged-in-user`)
      .then(user => {
        // const user = JSON.parse(data);
        if (user.id) {
          setCurrentUser(user);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return currentUser;
};

export default GetCurrentUser;