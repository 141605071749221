import React, { Container, useRef } from 'react';
import { ErrorMessage, useField } from 'formik';
import './textfield.css';

export const TextArea = ({ label, onClearText, clearText, reference, ...props }) => {
    const [field, meta] = useField(props);
    return (
        <div className='text-field-wrapper'>
            <label htmlFor={field.name}>{label}</label>
            <div className="d-flex search-field form-group text-field-input-wrapper">

                <textarea
                    ref={reference}
                    className={`h-auto form-control form-control-lg`}
                    {...field} {...props}
                    autoComplete="off"
                    placeholder={props.placeholder}
                />
            </div>
            <ErrorMessage component="span" name={field.name} className="error" />
        </div>
    )
}