import React, { useState, useEffect, useRef } from 'react';
import FetchWrapper from '../../util/fetchWrapper';
import FileUpload from "../shared/FileUpload";
import { CheckBox } from '../form-elements/CheckBox';
import useFileUpload from 'react-use-file-upload';
import { Formik, Form } from 'formik';
import { useHistory } from 'react-router-dom';
import { TextField } from '../form-elements/TextField.js';
import AlertModal from '../shared/AlertModal';
import ConfirmationModal from '../shared/ConfirmationModal';
import TestersCollapse from './TestersCollapse';
import DropDown from '../shared/DropDown';
import { Dropdown, ButtonGroup } from 'react-bootstrap';
import useMargin from '../shared/useMargin';
import useHeight from '../shared/useHeight';
import addBetaTestImpl from './addBetaTestImpl';
import { FILE_TYPE } from '../models/enums/fileTypeEnum';
import ModifyTesterModal from './ModifyTesterModal';
import AddTesterModal from './AddTesterModal';
import { ROLES } from '../models/enums/rolesEnum';
import BetaTestGeneralInformation from './BetaTestGeneralInformation';
import editBetaTestImpl from './editBetaTestImpl';
import blankBetaTestImpl from './blankBetaTestImpl';
import StickySubNavigation from '../shared/StickySubNavigation';
import IntegrationSettings from '../shared/IntegrationSettings';

const BetaTestOwnerSettings = ({
    isAdd,
    isView,
    isEdit,
    currentUser,
    setShouldRenderSettings,
    betaTestName,
    betaTest,
    setBetaTest,
    bugs,
    setBugs,
    requests,
    setRequests,
    rawBetaTestData,
    setTicketTypeId,
    setTicketModalId,
    setShowTicketModal,
    setTicketIsAdd,
    setTicketIsEdit,
    setTicketIsView,
    setShouldGoBackOnClose,
    isOwner,
    ...props }) => {
    const [dataLoaded, setDataLoaded] = useState(false);
    const [settingsDataLoaded, setSettingsDataLoaded] = useState(false);
    const queryParams = new URLSearchParams(window.location.search);
    const acceptInvitaiton = queryParams.get('acceptInvitation');
    const queryParamCode = queryParams.get('code');
    const fw = FetchWrapper();
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [alertModalMessage, setAlertModalMessage] = useState('');
    const [alertModalTitle, setAlertModalTitle] = useState('');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [confirmationModalMessage, setConfirmationModalMessage] = useState('');
    const [confirmationCodalTitle, setConfirmationModalTitle] = useState('');
    const history = useHistory();
    const [testers, setTesters] = useState([]);
    const [isTesterJoined, setIsTesterJoined] = useState(false);
    const [isActiveChecked, setIsActiveChecked] = useState(false);
    const [canUploadFiles, setCanUploadFiles] = useState(false);
    const [modalShouldGoBack, setModalShouldGoBack] = useState(true);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [betaTestPrivacyModes, setBetaTestPrivacyModes] = useState([]);
    const [showTesterModal, setShowEditTesterModal] = useState(false);
    const [isAddTesterModal, setIsAddTesterModal] = useState(false);
    const [isEditTesterModal, setIsEditTesterModal] = useState(false);
    const [testerModalId, setTesterModalId] = useState(0);
    const [showAddTesterModal, setShowAddTesterModal] = useState(false);
    const [lastUrlPermissionsTested, setLastUrlPermissionsTested] = useState('');
    const [shouldExitOnSave, setShouldExitOnSave] = useState(isEdit);
    const [allowedUrl, setAllowedUrl] = useState('');
    const paddingForSaveButton = useMargin('DropDownLabel');
    const heightForLeaveButton = useHeight('numberOfTesters');
    const [globalSettings, setGlobalSettings] = useState({});
    const [betaTestSettings, setBetaTestSettings] = useState({});
    const form = useRef(null);
    const submitForm = () => { form.current.handleSubmit(); };
    const saveButtonRef = useRef(null);
    const [modifiedSettings, setModifiedSettings] = useState({ dummy: 'dummy' });
    const [overrideGlobalSettingsChecked, setOverrideGlobalSettingsChecked] = useState(false);
    let dataImpl = blankBetaTestImpl();
    if (isEdit) {
        dataImpl = editBetaTestImpl(
            {
                currentUser,
                betaTest,
                setCanUploadFiles,
                setBetaTestPrivacyModes,
                setIsTesterJoined,
                setIsActiveChecked,
                setUploadedFiles,
                setBetaTest,
                fw,
                setAllowedUrl,
                setShouldRenderSettings,
                ...props
            });
    }

    if (isAdd) {
        dataImpl = addBetaTestImpl({
            currentUser,
            betaTest,
            setCanUploadFiles,
            setBetaTestPrivacyModes,
            setIsTesterJoined,
            setIsActiveChecked,
            setUploadedFiles,
            setBetaTest,
            history,
            fw,
            setAlertModalMessage,
            setAlertModalTitle,
            setShowAlertModal,
            ...props
        });
    }

    let initialValues = React.useMemo(() => dataImpl.getInitialValues(betaTest));
    const validate = React.useMemo(() => dataImpl.getValidation());

    const areBetaTestInputsDisabled = () => {
        // console.log('areBetaTestInputsDisabled', isAdd, betaTest?.userId, currentUser?.id, !(isAdd === true), betaTest?.userId !== currentUser?.id);
        return !(isAdd === true) && (betaTest?.userId && betaTest?.userId !== currentUser?.id);
    }


    useEffect(() => {
        if (dataLoaded === false && rawBetaTestData !== null && rawBetaTestData !== undefined && Object.keys(rawBetaTestData).length > 0) {
            dataImpl.onPageLoad(rawBetaTestData);
            setDataLoaded(true);
        }

    }, [rawBetaTestData]);

    useEffect(() => {
        if (acceptInvitaiton && queryParamCode) {
            fw.post(`/testers/accept-invitation`, { code: queryParamCode })
                .then((data) => {
                    if (data.success) {
                        window.location.href = `/betatests/${data.betaTestId}`;
                    }
                })
                .catch((err) => {
                    console.log('error accepting invitation', err);
                });
        }
    }, [acceptInvitaiton]);

    useEffect(() => {
        fw.get(`/betatestsettings/get-beta-test-settings?betaTestName=${betaTestName}`)
            .then(data => {
                if (data.errorMessage) {
                    console.error(data.errorMessage);
                    return;
                }

                setTesters(data.testers);
                setGlobalSettings(data.betaTestSettings.settings);
                setBetaTestSettings(data.betaTestSettings.betaTestSettings);

                setTimeout(() => {
                    setSettingsDataLoaded(true);
                }, 150);
            })
    }, [betaTestName]);

    const {
        files,
        fileNames,
        handleDragDropEvent,
        clearAllFiles,
        createFormData,
        setFiles,
        removeFile,
    } = useFileUpload();

    const submitFiles = () => {
        if (fileNames.length === 0) {
            console.log('No files to upload');
            return;
        }

        const formData = createFormData();
        formData.append('linkingId', betaTest?.id);
        formData.append('fileTypeId', FILE_TYPE.BETA_TEST);
        console.log('submitting files');
        fw.postForm(`/files/upload-file`, formData)
            .then(da => {
                console.log('response files', JSON.stringify(da));
            });
    }

    const handleSubmit = async (values) => {
        values.isActive = isActiveChecked;
        const result = await dataImpl.saveButtonClick(values);
        console.log('submission result', result);
        if (result.success) {
            console.log('submitting files');
            submitFiles();
        }

        saveIntegrationSettings();
        
        if (shouldExitOnSave && result.url) {
            // console.log('redirecting to', result.url);
            window.location.pathname = result.url;
        } else {
            // console.log('not redirecting, refreshing');
            window.location.reload();
        }
    }

    const deactivateBetaTest = () => {
        fw.post(`/betatests/deactivate-beta-test`, { betaTestName })
            .then((data) => {
                if (data.success) {
                    history.push('/betatests');
                }
            })
            .catch((err) => {
                console.log('error deactivating beta test', err);
            });
    }

    const reactivateBetaTest = () => {
        fw.post(`/betatests/reactivate-beta-test`, { betaTestName })

            .then((data) => {
                if (data.success) {
                    history.push(`/betatests/${betaTestName}`);
                }
            })
            .catch((err) => {
                console.log('error reactivating beta test', err);
            });
    }

    const disconnectGithub = () => {
        return fw.post('/authentication/github-disconnect', { betaTestName })
            .then(data => {
                if (data.errorMessage) {
                    // setModalMessage(data.errorMess``age);
                    // setShowModal(true);
                }
                return data.success;
            });
    }

    const disconnectAsana = () => {
        return fw.post('/authentication/asana-disconnect', { betaTestName })
            .then(data => {
                if (data.errorMessage) {
                    // setModalMessage(data.errorMess``age);
                    // setShowModal(true);
                }
                return data.success;
            });
    }

    const disconnectJira = () => {
        return fw.post('/authentication/jira-disconnect', { betaTestName })
            .then(data => {
                if (data.errorMessage) {
                    // setModalMessage(data.errorMess``age);
                    // setShowModal(true);
                }
                return data.success;
            });
    }

    const updateCoreSettings = (modifiedSettingsWithoutDummy) => {
        console.log('running updateCoreSettings');
        return fw.post('/betatestsettings/update-core-settings', {betaTestSettings: modifiedSettingsWithoutDummy, betaTestName})
            .then(data => {
                if (data.errorMessage) {
                    console.log(data.errorMessage);
                    return false;
                }
                return true;
            })
    }

    const saveIntegrationSettings = () => {
        console.log('running saveIntegrationSettings');
        const { dummy, ...modifiedSettingsWithoutDummy } = modifiedSettings;
        let continueSave = true;

        if (modifiedSettings.isGithubAuthenticated === false) {
            continueSave = disconnectGithub();
            delete modifiedSettingsWithoutDummy.isGithubAuthenticated;
        }

        if (modifiedSettings.isAsanaAuthenticated === false) {
            continueSave = disconnectAsana();
            delete modifiedSettingsWithoutDummy.isAsanaAuthenticated;
        }

        if (modifiedSettings.isJiraAuthenticated === false) {
            continueSave = disconnectJira();
            delete modifiedSettingsWithoutDummy.isJiraAuthenticated;
        }

        if (Object.keys(modifiedSettingsWithoutDummy).length > 0) {
            continueSave = updateCoreSettings(modifiedSettingsWithoutDummy);
        }

        if (continueSave) {
            // window.location.reload();
        }
    }


    const uploadTextFormatted =
        <>
            <p>Please use the form to your right to upload any file(s) of your choosing.</p>
            <p className='bold-font'>These files will be provided to the tester when they join the beta test.</p>
        </>;

    const navBarItems = [
        { id: '#generalInformation', name: 'General Information' },
        { id: '#testerConfiguration', name: 'Tester Configuration' },
        { id: '#integrationSettings', name: 'Integration Settings' }
    ];

    if (isEdit) {
        navBarItems.push({ id: '#dangerZone', name: 'Danger Zone', color: 'red' });
    }

    const title = `${isAdd ? 'Add' : isView ? 'Viewing' : 'Edit'} Beta Test: ${betaTest?.title}`;
    return (
        <>
            <div className='card'>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validate}
                    validateOnChange={true}
                    enableReinitialize={true}
                    onSubmit={values => {
                        handleSubmit(values);
                    }}
                    onSubmittingCallback={(values) => {
                        console.log('callback', values);
                    }}
                    innerRef={form}
                >
                    {({ setFieldValue }) =>
                    (
                        <Form>
                            <div className='card-body'>
                                <div className='card-title'>
                                    <div className="row">
                                        <div className="col-8">
                                            <h4 id="betaTestHeader" style={{ textTransform: 'none' }}>
                                                {isAdd ? 'Add' : isView ? 'Viewing' : 'Edit'} Beta Test: {betaTest?.title}
                                            </h4>
                                        </div>
                                        <div className='col-2 ml-auto d-flex justify-content-end'>
                                            <Dropdown as={ButtonGroup} disabled={areBetaTestInputsDisabled()}>
                                                <button type="button" className="btn btn-sm btn-primary" onClick={(e) => {
                                                    console.log('clicked');
                                                    setShouldExitOnSave(true);
                                                    submitForm();
                                                }} disabled={areBetaTestInputsDisabled()}>Save and Exit</button>
                                                <Dropdown.Toggle variant="btn btn-primary dropdown-toggle-split" id="dropdownMenuSplitButton1" disabled={areBetaTestInputsDisabled()}>
                                                    <span className="sr-only">Toggle Dropdown</span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item disabled={areBetaTestInputsDisabled()} onClick={(e) => {
                                                        console.log('clicked2');
                                                        setShouldExitOnSave(false);
                                                        submitForm();
                                                    }}>Save</Dropdown.Item>
                                                    <Dropdown.Item disabled={areBetaTestInputsDisabled()} onClick={(e) => {
                                                        history.goBack();
                                                    }}>Exit</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-2 side-navigation" id='scroll-bar-sticky' style={{}}>
                                        <StickySubNavigation navBarItems={navBarItems} />
                                    </div>
                                    <div className='col-lg-10 col-md-10 col-sm-10'>
                                        <BetaTestGeneralInformation
                                            isAdd={isAdd}
                                            betaTest={betaTest}
                                            areBetaTestInputsDisabled={areBetaTestInputsDisabled}
                                            displayIncentives={true}
                                            setFieldValue={setFieldValue}
                                            renderFullText={true}
                                        />
                                        <h4 id="testerConfiguration">Tester Configuration</h4>
                                        {/* <hr /> */}
                                        {
                                            !isAdd ?
                                                <>
                                                    {currentUser?.roles.filter((role) => role.id === ROLES.COMPANY && role.isGranted).length > 0
                                                        ? <>
                                                            <hr />
                                                            <TestersCollapse
                                                                betaTestId={betaTest?.id}
                                                                testers={testers}
                                                                setShowAlertModal={setShowAlertModal}
                                                                setAlertModalTitle={setAlertModalTitle}
                                                                setAlertModalMessage={setAlertModalMessage}
                                                                isAdd={isAdd}
                                                                isView={isView}
                                                                isEdit={isEdit}
                                                                setIsAddTesterModal={setIsAddTesterModal}
                                                                setShowEditTesterModal={setShowEditTesterModal}
                                                                setIsEditTesterModal={setIsEditTesterModal}
                                                                setTesterModalId={setTesterModalId}
                                                                setShowAddTesterModal={setShowAddTesterModal}
                                                            />
                                                        </>
                                                        : <></>}

                                                    <hr />
                                                </>
                                                :
                                                <div style={{ marginBottom: '1rem', height: '1px' }}></div>
                                        }
                                        <div className='col-lg-8 col-md-12 col-sm-12 pl-0 ml-0'>
                                            <div className='col-12 pl-0 ml-0 pb-0 mb-0'>
                                                <TextField
                                                    id='numberOfTesters'
                                                    className="col-lg-4 col-md-12 col-sm-12 h-auto form-control form-control-lg"
                                                    label="Maximum Testers"
                                                    name="numberOfTesters"
                                                    type="text"
                                                    placeholder="Number of Testers"
                                                    disabled={areBetaTestInputsDisabled()}
                                                />

                                                {isAdd && (
                                                    <span className='col-lg-4 col-md-12 col-sm-12 pl-0 ml-0 mr-0 pr-0' style={{ display: 'block' }}>
                                                        <DropDown
                                                            dropDownHeight={heightForLeaveButton}
                                                            isDisabled={areBetaTestInputsDisabled()}
                                                            options={betaTestPrivacyModes?.map((status, index) => {
                                                                return <option key={status.id} value={status.id} selected={betaTest?.privacyStatusId === status.id}>{status.name}</option>
                                                            })}
                                                            onChangeCallback={(e) => {
                                                                const newBetaTest = { ...betaTest, privacyStatusId: e.target.value };
                                                                setBetaTest(newBetaTest)
                                                            }}
                                                            inputName="privacyStatusId"
                                                            label="Privacy Status"
                                                            valueProp={betaTest?.privacyStatusId}
                                                            labelFor="betaTestPrivacyMode"
                                                            labelId="DropDownLabel"
                                                        />

                                                    </span>)}
                                            </div>
                                            <div className='col-lg-8 col-md-12 col-sm-12 pt-2 ml-0 pl-0'>
                                                {!isAdd && (<FileUpload //TODO make this work on add
                                                    uploadDisabled={areBetaTestInputsDisabled()}
                                                    uploadText={uploadTextFormatted}
                                                    files={files}
                                                    fileNames={fileNames}
                                                    handleDragDropEvent={handleDragDropEvent}
                                                    clearAllFiles={clearAllFiles}
                                                    createFormData={createFormData}
                                                    setFiles={setFiles}
                                                    removeFile={removeFile}
                                                    alreadyUploadedFiles={uploadedFiles}
                                                    linkingId={betaTest?.id}
                                                    fileType={FILE_TYPE.BETA_TEST}
                                                    hasUploadPermission={canUploadFiles}
                                                />)}
                                            </div>
                                        </div>


                                        <div className='row pl-0 ml-0 mt-3'>
                                            <h4 id="integrationSettings" className=''>Integration Settings</h4>
                                            <>
                                                <CheckBox
                                                    displayName='Override Global Settings'
                                                    name='overrideGlobalSettings'
                                                    checked={overrideGlobalSettingsChecked}
                                                    onChange={(e) => {
                                                        console.log('overrideGlobalSettingsChecked', overrideGlobalSettingsChecked, 'setting to', !overrideGlobalSettingsChecked);
                                                        if (typeof modifiedSettings?.overrideGlobalSettings === "boolean") {
                                                            const modified = modifiedSettings;
                                                            delete modified.overrideGlobalSettings;
                                                            console.log('deleting overrideGlobalSettings', modified);
                                                            setModifiedSettings({ ...modified });
                                                        }
                                                        else {
                                                            setModifiedSettings({ ...modifiedSettings, overrideGlobalSettings: !overrideGlobalSettingsChecked });
                                                        }
                                                        setOverrideGlobalSettingsChecked(!overrideGlobalSettingsChecked);
                                                    }}
                                                    formCheckExtras='mt-0 mb-0 ml-2'
                                                    limitCheckBoxHeight={true}
                                                />
                                            </>
                                        </div>
                                        <hr />
                                        <div className='col-lg-12 col-md-12 col-sm-12 pl-0 ml-0'>
                                            <IntegrationSettings
                                                betaTestName={betaTestName}
                                                overrideGlobalSettingsChecked={overrideGlobalSettingsChecked}
                                                setOverrideGlobalSettingsChecked={setOverrideGlobalSettingsChecked}
                                                globalSettings={globalSettings}
                                                betaTestSettings={betaTestSettings}
                                                currentUser={currentUser}
                                                modifiedSettings={modifiedSettings}
                                                setModifiedSettings={setModifiedSettings}
                                                saveButtonRef={saveButtonRef}
                                                dataLoaded={settingsDataLoaded}
                                                asanaRedirectUrl={process.env.REACT_APP_ASANA_SETTINGS_REDIRECT_URL}
                                            />
                                        </div>
                                        {!isAdd && (
                                            <>
                                                <h4 id="dangerZone" className="text-danger">Danger Zone</h4>
                                                <hr className="" style={{ color: '#fe7c96', backgroundColor: '#fe7c96' }} />
                                                <div className='col-lg-8 col-md-12 col-sm-12 pl-0 ml-0 pb-2 mb-2'>
                                                    <span className='col-lg-4 col-md-12 col-sm-12 pl-0 ml-0 mr-0 pr-0' style={{ display: 'block' }}>
                                                        <DropDown
                                                            dropDownHeight={heightForLeaveButton}
                                                            isDisabled={areBetaTestInputsDisabled()}
                                                            options={betaTestPrivacyModes?.map((status, index) => {
                                                                return <option key={status.id} value={status.id} selected={betaTest?.privacyStatusId === status.id}>{status.name}</option>
                                                            })}
                                                            onChangeCallback={(e) => {
                                                                const newBetaTest = { ...betaTest, privacyStatusId: e.target.value };
                                                                setBetaTest(newBetaTest)
                                                            }}
                                                            inputName="privacyStatusId"
                                                            label="Privacy Status"
                                                            valueProp={betaTest?.privacyStatusId}
                                                            labelFor="betaTestPrivacyMode"
                                                            labelId="DropDownLabel"
                                                        />
                                                    </span>
                                                </div>

                                                <h5 className="pb-2">
                                                    {betaTest.isActive ? 'Deactivate Beta Test?' : 'Reactivate Beta Test!'}
                                                </h5>
                                                <div className="col-3 pl-0 ml-0">
                                                    <button type="submit" className={`btn btn-sm btn-fw btn-sm ${betaTest.isActive ? 'btn-gradient-danger' : 'btn-gradient-success'}`}
                                                        onClick={(e) => {
                                                            const message = betaTest.isActive ? 'Deactivating this beta test will prevent activity such as new testers joining and new tickets being created. Are you sure you want to deactivate this beta test?'
                                                                : 'Reactivating this beta test will activity such as testers joining and tickets being entered to resume. Are you sure you want to reactivate this beta test?';
                                                            e.preventDefault();
                                                            setConfirmationModalMessage(message)
                                                            setConfirmationModalTitle(betaTest.isActive ? 'Deactivate Beta Test?' : 'Reactivate Beta Test!');
                                                            setShowConfirmationModal(true);
                                                        }}
                                                    >{betaTest.isActive ? 'Deactivate' : 'Reactivate'}</button>
                                                </div>
                                            </>)}
                                    </div>
                                </div>
                            </div>
                            <AlertModal showModal={showAlertModal} setShowModal={setShowAlertModal} message={alertModalMessage} title={alertModalTitle} onAfterHideModal={() => {
                                if (modalShouldGoBack) {
                                    history.goBack();
                                    setShowAlertModal(false);
                                }
                                else {
                                    window.location.reload();
                                }

                            }} />
                        </Form>
                    )}
                </Formik>
                <ModifyTesterModal
                    showModal={showTesterModal}
                    setShowModal={setShowEditTesterModal}
                    backdrop='static'
                    keyboard={false}
                    currentUser={currentUser}
                    isAdd={isAddTesterModal}
                    isEdit={isEditTesterModal}
                    testerId={testerModalId}
                    betaTestId={betaTestName}
                />

                <AddTesterModal
                    setShowAddTesterModal={setShowAddTesterModal}
                    showAddTesterModal={showAddTesterModal}
                    currentUser={currentUser}
                    betaTestId={betaTestName}
                />

                <AlertModal
                    showModal={showAlertModal}
                    setShowModal={setShowAlertModal}
                    message={alertModalMessage}
                    title={alertModalTitle}
                />

                <ConfirmationModal
                    showModal={showConfirmationModal}
                    setShowModal={setShowConfirmationModal}
                    title={confirmationCodalTitle}
                    message={confirmationModalMessage}
                    confirmText="Yes"
                    cancelText="No"
                    handleConfirmationClick={() => {
                        setShowConfirmationModal(false);
                        if (betaTest.isActive === false) {
                            reactivateBetaTest();
                        }
                        else {
                            deactivateBetaTest();
                        }

                    }}
                />
            </div>
        </>
    );
}

export default BetaTestOwnerSettings;
