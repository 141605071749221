import React, { Component, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { TextField } from '../form-elements/TextField.js';
import * as Yup from 'yup';
import fetchWrapper from '../../util/fetchWrapper.js';
import AlertModal from '../shared/AlertModal.js';


const ResetPassword = (props) => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("resetToken");
    const fw = fetchWrapper();
    const [emailToken, setEmailToken] = useState('');
    const [confirmedEmail, setConfirmedEmail] = useState(undefined);
    const [modalMessage, setModalMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('Error');
    const [displayEmailSubmission, setDisplayEmailSubmission] = useState(false);
    const [displayResetPassword, setDisplayResetPassword] = useState(false);
    const [hasVerificationAttemptProcessed, setHasVerificationAttemptProcessed] = useState(false);
    let validationSchema = {
        email: Yup.string()
            .email('Email is invalid')
            .required('Email is required')
    };

    if (token) {
        validationSchema.password = Yup.string()
            .min(3, 'Password must be at least 6 charaters')
            .required('Password is required');
        validationSchema.confirmPassword = Yup.string()
            .oneOf([Yup.ref('password'), null], 'Password must match')
            .required('Confirm password is required');
    }


    let validate = Yup.object(validationSchema);

    useEffect(() => {
        if (token && emailToken === '') {
            setEmailToken(token);
            console.log('setting token');
        }
    });

    useEffect(() => {
        if (!hasVerificationAttemptProcessed && emailToken !== '') {
            console.log('attempting verification with token');
            setHasVerificationAttemptProcessed(true);
            fw.post('/authentication/verify-email-for-password-reset', { token: emailToken })
                .then(data => {
                    if (data.errorMessage) {
                        setModalMessage(data.errorMessage);
                        setShowModal(true);
                    }
                    setConfirmedEmail(data.ConfirmedEmailAddress);
                    setDisplayResetPassword(true);
                });
        }

    }, [emailToken]);

    let initialValues;
    let emailStyles;
    if (confirmedEmail) {
        initialValues = {
            email: confirmedEmail !== undefined ? confirmedEmail : '',
            password: '',
            confirmPassword: ''
        };
        emailStyles = { backgroundColor: '#e9ecef', opacity: '1' };
    } else {
        initialValues = {
            email: ''
        }
        emailStyles = {};
    }


    return (
        <div>
            <div className="d-flex align-items-center auth px-0">
                <div className="row w-100 mx-0">
                    <div className="col-lg-4 mx-auto">
                        <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                            <div className="brand-logo">
                                <img src={require("../../assets/images/logo.svg")} alt="logo" />
                            </div>
                            {
                                displayEmailSubmission ?
                                    <div className='card bg-danger'
                                        style={{ textAlign: 'center' }}
                                    >
                                        <p style={{ fontSize: '120%' }}>Your reset password link has been sent to your email</p>
                                        <p>Please click the link to reset your password</p>
                                        <p>You may close this page.</p>
                                    </div>
                                    :
                                    <></>
                            }
                            <h4>Sorry to hear you're having trouble</h4>
                            <h6 className="font-weight-light">Please provide the email for the account you wish to reset.</h6>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validate}
                                validateOnChange={true}
                                enableReinitialize={true}
                                onSubmit={values => {
                                    if (!token) {
                                        fw.post(`/authentication/get-reset-password-code`, values)
                                            .then(data => {

                                                if (data.success) {
                                                    setDisplayEmailSubmission(true);
                                                }

                                                if (data.errorMessage) {
                                                    setModalMessage(data.errorMessage);
                                                    setShowModal(true);
                                                }
                                            });
                                        return;
                                    }
                                    else {
                                        values.token = token;
                                        console.log(values);
                                        fw.post('/authentication/reset-password', values)
                                            .then(data => {
                                                if (data.errorMessage) {
                                                    setModalMessage(data.errorMessage);
                                                    setModalTitle('Error updating password');
                                                    setShowModal(true);
                                                    return;
                                                }
                                                if (data.success) {
                                                    window.location.href = '/user/login?changedPassword=true';
                                                    return;
                                                }
                                            });
                                    }

                                }}
                            >
                                <Form
                                    className="pt-3">
                                    <TextField
                                        className="h-auto form-control form-control-lg"
                                        label="Email"
                                        name="email"
                                        type="email"
                                        placeholder="Email"
                                        disabled={confirmedEmail !== undefined}
                                        style={emailStyles}
                                    />
                                    {displayResetPassword ?

                                        <>
                                            <TextField
                                                className="h-auto form-control form-control-lg"
                                                label="Password"
                                                name="password"
                                                placeholder="Password"
                                                type="password"
                                            />
                                            <TextField
                                                className="h-auto form-control form-control-lg"
                                                label="Confirm Password"
                                                placeholder="Confirm Password"
                                                name="confirmPassword"
                                                type="password"
                                            />
                                        </>
                                        :
                                        <>

                                        </>
                                    }
                                    <div className="mt-3">
                                        <button
                                            variant='primary'
                                            type='submit'
                                            className='btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn'
                                            disabled={displayEmailSubmission}
                                        >
                                            Reset Password
                                        </button>
                                    </div>
                                    <div className="text-center mt-4 font-weight-light">
                                        Looking to login? <Link to="/user/login" className="text-primary">Log in</Link>
                                    </div>
                                </Form>
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
            <AlertModal setShowModal={setShowModal} showModal={showModal} title={modalTitle} message={modalMessage} />
        </div>
    );
}

export default ResetPassword;