import React, { useState, useEffect } from 'react';
import ModifyTicket from './ModifyTicket';
import { Button, Modal, Dropdown } from 'react-bootstrap';
import FetchWrapper from '../../util/fetchWrapper';

const ModifyTicketModal = ({ ticketId,
    isAdd,
    isEdit,
    isView,
    backdrop,
    keyboard,
    setShowModal,
    onAfterHideModal,
    showModal,
    currentUser,
    ticketTypeId,
    betaTestId,
    shouldGoBackOnClose,
    viewTickets,
    ...props }) => {
    const handleHide = () => {
        setShowModal(false);
        if (onAfterHideModal) {
            onAfterHideModal();
        }
    }

    return (
        <div className={showModal}>
            <Modal show={showModal} onHide={handleHide} backdrop={backdrop} keyboard={keyboard}
                dialogClassName="modal-90w"
                contentClassName="modal-90h"
            >
                <Modal.Body
                    style={{
                        // maxHeight: 'calc(100vh)',
                        overflowY: 'hidden'
                    }}
                >
                    <ModifyTicket
                        ticketId={ticketId}
                        currentUser={currentUser}
                        isModalRender={true}
                        setShowTicketModal={setShowModal}
                        isAdd={isAdd}
                        isEdit={isEdit}
                        isView={isView}
                        ticketTypeId={ticketTypeId}
                        betaTestId={betaTestId}
                        shouldGoBackOnClose={shouldGoBackOnClose}
                        viewTickets={viewTickets}
                    />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ModifyTicketModal;