import React, { Container } from 'react';
import './textfield.css';
import { Field, ErrorMessage, useField } from 'formik';

export const CheckBox = ({ label, outerStyles, labelClassName, formCheckExtras, limitCheckBoxHeight, ...props }) => {
    const [field, meta] = useField(props);
    return (
        <div className={`form-check ${formCheckExtras ?? ''}`} style={{ marginRight: '1rem', ...outerStyles }}>
            <label className={`form-check-label text-nowrap ${labelClassName ?? ''}`} >
                <Field
                    name={props.name}
                    {...field} {...props}
                    type="checkbox"
                    checked={props.checked}
                    className={`form-check-input form-control ${limitCheckBoxHeight ? 'limited-checkbox-height' : ''}`}
                // onChange={props.onChange}
                />
                <i className="input-helper"></i>
                {props.displayName}
            </label>
            <ErrorMessage component="span" name={field.name} className="error" />
        </div>
    )
}