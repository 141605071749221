import React, { useState, useEffect } from 'react';
import FetchWrapper from '../../util/fetchWrapper';
import ModifyBetaTest from './ModifyBetaTest';
import ModifyTicketModal from './ModifyTicketModal';
import ViewBetaTest from './ViewBetaTest';
import { FILE_TYPE } from '../models/enums/fileTypeEnum';
import { TICKET_TYPES } from '../models/enums/ticketsEnum';
import ViewTickets from './ViewTickets';

const BetaTestContainer = ({
    isAdd,
    isView,
    isEdit,
    viewTickets,
    currentUser,
    setShouldRenderSettings,
    setSideBarBetaTestName,
    ...props }) => {
    const [shouldGoBackOnClose, setShouldGoBackOnClose] = useState(true);
    const [userTesterObject, setUserTesterObject] = useState({});
    const [betaTest, setBetaTest] = useState({});
    const [bugs, setBugs] = useState([]);
    const [requests, setRequests] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [rawBetaTestData, setRawBetaTestData] = useState({});
    const fw = FetchWrapper();
    const [showTicketModal, setShowTicketModal] = useState(false);
    const [ticketModalId, setTicketModalId] = useState(0);
    const [ticketIsAdd, setTicketIsAdd] = useState(false);
    const [ticketIsEdit, setTicketIsEdit] = useState(false);
    const [ticketIsView, setTicketIsView] = useState(false);
    const [ticketTypeId, setTicketTypeId] = useState(0);
    const [isOwner, setIsOwner] = useState(false);
    const { betaTestName, ticketId } = props.match.params;
    const editBugPattern = /(bug)\/edit/;
    const viewBugPattern = /(bug)\/view/;
    const addBugPattern = /(bug)\/add/;
    const editRequestPattern = /(request)\/edit/;
    const viewRequestPattern = /(request)\/view/;
    const addRequestPattern = /(request)\/add/;
    useEffect(() => {
        if (dataLoaded === false && isAdd !== true) {
            console.log('calling get beta test by id endpoint', dataLoaded, isAdd, betaTestName);
            fw.get(`/betatests/get-beta-test-by-id?betaTestId=${betaTestName}`)
                .then((response) => {
                    if (response.redirectUrl) {
                        window.location.href = response.redirectUrl;
                    }
                    // console.log('container beta test', response, response.isOwner);
                    setUserTesterObject(response.testers.filter(t => t.userId === currentUser.id && !t.isDeleted && t.isActive)[0]);
                    setIsOwner(response.isOwner);
                    setBugs(response.bugs);
                    setRequests(response.requests);
                    setBetaTest(response.betaTest);
                    setRawBetaTestData(response);
                    setDataLoaded(true);
                });
        }
        else if (dataLoaded === false && viewTickets !== true && isAdd === true) {
            fw.get(`/betatests/get-beta-test-statuses`)
                .then((response) => {
                    if (response.redirectUrl) {
                        window.location.href = response.redirectUrl;
                    }
                    setBetaTest(response.betaTest);
                    setRawBetaTestData(response);
                    setDataLoaded(true);
                });
        }

        setSideBarBetaTestName(betaTestName);
    }, [betaTestName, window.location.pathname]);



    useEffect(() => {
        if ([0, null, undefined, ''].indexOf(ticketId) === -1) {
            const tt = getTicketType();
            const isEdit = editBugPattern.test(window.location.pathname) || editRequestPattern.test(window.location.pathname);
            const isAdd = addBugPattern.test(window.location.pathname) || addRequestPattern.test(window.location.pathname);
            const isView = viewBugPattern.test(window.location.pathname) || viewRequestPattern.test(window.location.pathname);
            setTicketModalId(ticketId);
            setTicketTypeId(tt);
            setTicketIsEdit(isEdit);
            setTicketIsAdd(isAdd);
            setTicketIsView(isView);
            setShowTicketModal(true);
        }
    }, [ticketId]);

    const getTicketType = () => {
        if (addBugPattern.test(window.location.pathname) || editBugPattern.test(window.location.pathname) || viewBugPattern.test(window.location.pathname)) {
            return TICKET_TYPES.BUG;
        }
        else if (addRequestPattern.test(window.location.pathname) || editRequestPattern.test(window.location.pathname) || viewRequestPattern.test(window.location.pathname)) {
            return TICKET_TYPES.REQUEST;
        }
        else {
            return 0;
        }
    }

    return (
        <>
            <ModifyTicketModal
                ticketId={ticketModalId}
                showModal={showTicketModal}
                setShowModal={setShowTicketModal}
                backdrop='static'
                keyboard={false}
                currentUser={currentUser}
                isAdd={ticketIsAdd}
                isEdit={ticketIsEdit}
                isView={ticketIsView}
                ticketTypeId={ticketTypeId}
                betaTestId={betaTestName}
                shouldGoBackOnClose={shouldGoBackOnClose}
                viewTickets={viewTickets}
            />

            {viewTickets && (<ViewTickets
                betaTestName={betaTestName}
                setShouldGoBackOnClose={setShouldGoBackOnClose}
                setTicketTypeId={setTicketTypeId}
                setTicketIsAdd={setTicketIsAdd}
                setTicketIsEdit={setTicketIsEdit}
                setTicketIsView={setTicketIsView}
                setShowTicketModal={setShowTicketModal}
            />)}

            {isView &&
                (<ViewBetaTest
                    isView={isView}
                    currentUser={currentUser}
                    setShouldRenderSettings={setShouldRenderSettings}
                    betaTestName={betaTestName}
                    betaTest={betaTest}
                    setBetaTest={setBetaTest}
                    bugs={bugs}
                    setBugs={setBugs}
                    requests={requests}
                    setRequests={setRequests}
                    rawBetaTestData={rawBetaTestData}
                    setTicketTypeId={setTicketTypeId}
                    setTicketModalId={setTicketModalId}
                    setShowTicketModal={setShowTicketModal}
                    setTicketIsAdd={setTicketIsAdd}
                    setTicketIsEdit={setTicketIsEdit}
                    setTicketIsView={setTicketIsView}
                    setShouldGoBackOnClose={setShouldGoBackOnClose}
                    isOwner={isOwner}
                    setIsOwner={setIsOwner}
                    setUserTesterObject={setUserTesterObject}
                    {...props}
                />)
            }
            {(isAdd || isEdit) &&
                (<ModifyBetaTest
                    isView={isView}
                    isEdit={isEdit}
                    isAdd={isAdd}
                    currentUser={currentUser}
                    setShouldRenderSettings={setShouldRenderSettings}
                    betaTestName={betaTestName}
                    betaTest={betaTest}
                    setBetaTest={setBetaTest}
                    bugs={bugs}
                    setBugs={setBugs}
                    requests={requests}
                    setRequests={setRequests}
                    rawBetaTestData={rawBetaTestData}
                    setTicketTypeId={setTicketTypeId}
                    setTicketModalId={setTicketModalId}
                    setShowTicketModal={setShowTicketModal}
                    setTicketIsAdd={setTicketIsAdd}
                    setTicketIsEdit={setTicketIsEdit}
                    setTicketIsView={setTicketIsView}
                    setShouldGoBackOnClose={setShouldGoBackOnClose}
                    isOwner={isOwner}
                    userTesterObject={userTesterObject}
                    {...props}
                />)
            }
        </>
    )
}

export default BetaTestContainer;