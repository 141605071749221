import React, { Component, useEffect, useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import './App.scss';
import { AppRoutes } from './AppRoutes';
import { Navbar } from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import { SettingsPanel } from './shared/SettingsPanel';
import { Footer } from './shared/Footer';
import { withTranslation } from "react-i18next";
import GetCurrentUser from '../services/user/userService.js';
import { useIdleTimer } from 'react-idle-timer';

const App = (props) => {
  const [isFullPageLayout, setIsFullPageLayout] = useState(true);
  const [location, setLocation] = useState(props.location);
  const [shouldRenderSettings, setShouldRenderSettings] = useState(false);
  const [sideBarBetaTestName, setSideBarBetaTestName] = useState('');
  const user = GetCurrentUser();
  let lastLocation = '';

  document.title = 'Beta Test My Product';

  useEffect(() => {
    if (location.location !== props.location && (lastLocation.localeCompare('') === 0 || props.location.pathname.localCompare(lastLocation) !== 0)) {
      lastLocation = props.location.pathname;
      onRouteChanged();
      setLocation(props.location);
    }
  }, [props]);

  const onRouteChanged = () => {
    console.log("ROUTE CHANGED");
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = ['/user/login', '/user/reset-password', '/user/register', '/user/lockscreen', '/error-pages/error-404', '/error-pages/error-500', '/general-pages/landing-page'];
    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      if (props.location.pathname === fullPageLayoutRoutes[i]) {
        console.log('changing to fullpage layout');
        setIsFullPageLayout(true);
        document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
        break;
      } else {
        console.log('changing to not full page layout');
        setIsFullPageLayout(false);
        document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
      }
    }
  }


  let navbarComponent = !isFullPageLayout ? <Navbar {...props} currentUser={user} /> : '';
  let sidebarComponent = !isFullPageLayout ? <Sidebar {...props} currentUser={user} shouldRenderSettings={shouldRenderSettings} sideBarBetaTestName={sideBarBetaTestName} /> : '';
  let SettingsPanelComponent = !isFullPageLayout ? <SettingsPanel /> : '';
  let footerComponent = !isFullPageLayout ? <Footer /> : '';
  return (
    <div className="container-scroller">
      {navbarComponent}
      <div className="container-fluid page-body-wrapper">
        {sidebarComponent}
        <div className="main-panel">
          <div className="content-wrapper">
            <AppRoutes currentUser={user} setShouldRenderSettings={setShouldRenderSettings} setSideBarBetaTestName={setSideBarBetaTestName} />
            {SettingsPanelComponent}
          </div>
          {footerComponent}
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(withRouter(App));

