import FetchWrapper from "../../util/fetchWrapper";
import { useHistory } from "react-router-dom";
import * as Yup from 'yup';
import moment from 'moment';

const addBetaTestImpl = ({
    currentUser,
    betaTest,
    setCanUploadFiles,
    setBetaTestPrivacyModes,
    setIsTesterJoined,
    setIsActiveChecked,
    setUploadedFiles,
    setBetaTest,
    history,
    fw,
    setShowAlertModal,
    setAlertModalMessage,
    setAlertModalTitle,
    ...props }) => {
    const onPageLoad = (data) => {
        console.log('on page load called');
        fw.get(`/betatests/get-beta-test-statuses`)
            .then((data) => {
                if (data.redirectUrl) {
                    history.push(data.redirectUrl);
                }
                console.log(data);
                setCanUploadFiles(true);
                setBetaTestPrivacyModes(data.statuses);
                setIsTesterJoined(false);
                setIsActiveChecked(true);
                setUploadedFiles([]);
                setBetaTest(data.betaTest);
                console.log('beta test', data.betaTest);
            })
            .catch((error) => console.log('error fetching beta test', error));
    }

    const getInitialValues = (data) => {
        return {
            title: '',
            description: '',
            isDeleted: false,
            incentives: '',
            startDate: moment(betaTest?.startDate).toDate(),
            endDate: moment(betaTest?.endDate).toDate(),
            numberOfTesters: 10,
            privacyStatusId: 1
        };
    }

    const getValidation = () => {
        return Yup.object({
            title: Yup.string()
                .required('Title is required'),
            description: Yup.string().required('Please provide a Description').notOneOf(['<p class="editor-paragraph"><br></p>'], 'Please provide a Description'),
            startDate: Yup.date()
                .nullable()
                .required('Start date is required'),
            endDate: Yup.date()
                .nullable()
                .required('End date is required'),
            numberOfTesters: Yup.number('This must be a number between 1-1000000')
                .min(1, 'This must be greater than 0')
                .required('Number of testers is required'),
        });
    }

    const saveButtonClick = (values) => {
        return new Promise((resolve, reject) => {
            console.log('beta test submitting', betaTest);
            console.log('values test submitting', values);
            const newBetaTest = {
                title: values.title,
                description: values.description,
                startDate: values.startDate,
                endDate: values.endDate,
                maxNumbersOfTesters: values.numberOfTesters,
                isDeleted: !values.isDeleted,
                incentives: values.incentives,
                privacyStatusId: betaTest.privacyStatusId,
            }
            fw.post('/betatests/create-beta-test', newBetaTest)
                .then(data => {
                    if (data.errorMessage) {
                        setShowAlertModal(true);
                        setAlertModalMessage(data.errorMessage);
                        setAlertModalTitle('Error');
                        reject(data.errorMessage);
                        return;
                    }

                    resolve({ success: data.success, url: `/betatests/${data.id}/settings` });
                })
        });
    }

    return {
        onPageLoad,
        getInitialValues,
        getValidation,
        saveButtonClick
    };
}

export default addBetaTestImpl;