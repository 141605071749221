import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import fetchWrapper from '../../util/fetchWrapper';
import moment from 'moment';
import AlertModal from './AlertModal';
import { googleLogout } from '@react-oauth/google';

export const Navbar = ({ currentUser, ...props }) => {
  const fw = fetchWrapper();
  const [notifications, setNotifications] = useState([]);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [notificationModalText, setNotificationModalText] = useState('');
  const [notificationModalTitle, setNotificationModalTitle] = useState('');
  const [viewingNotificationId, setViewingNotificationId] = useState(0);

  const loadData = () => {
    fw.get('/notifications/all')
      .then(data => {
        if (data.errorMessage) {
          //show modal
        }
        console.log('notifications', data);
        setNotifications(data);
      });

    fw.get('/messages/all')
      .then(data => {
        if (data.errorMessage) {
          //show modal
          console.log('error', data.errorMessage);
          return;
        }
        console.log('message', data);
        setMessages(data);
      });
  };

  useEffect(() => {
    if (loading && currentUser) {
      loadData();
      setLoading(false);
    }
  }, [currentUser]);

  useEffect(() => {
    if (!loading, props.location.pathname === '/dashboard') {
      loadData();
    }
  }, [props.location.pathname]);

  const toggleOffcanvas = () => {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  };
  const toggleRightSidebar = () => {
    document.querySelector('.right-sidebar').classList.toggle('open');
  };

  const redirectUrl = '/betatests';
  return (
    <nav id="navbar" className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <Link className="brand-logo" to={redirectUrl}>
          {/* <img src={require('../../assets/images/btmp-logo-img-purple.png')} alt="logo" height={35} width={35} /> */}
          <img src={require("../../assets/images/full-logo-purple.png")} alt="logo" />
        </Link>
        <a className="navbar-brand brand-logo-mini" href={redirectUrl}><img src={require('../../assets/images/btmp-logo-img-purple.png')} alt="logo" /></a>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-stretch">
        <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={() => document.body.classList.toggle('sidebar-icon-only')}>
          <span className="mdi mdi-menu"></span>
        </button>
        <div className="search-field d-none d-md-block">
          <form className="d-flex align-items-center h-100" action="#">
            <div className="input-group">
              <div className="input-group-prepend bg-transparent">
                <i className="input-group-text border-0 mdi mdi-magnify"></i>
              </div>
              <input type="text" className="form-control bg-transparent border-0" placeholder="Search projects" />
            </div>
          </form>
        </div>
        <ul className="navbar-nav navbar-nav-right">
          <li className="nav-item nav-profile">
            <Dropdown alignRight>
              <Dropdown.Toggle className="nav-link">
                <div className="nav-profile-img">
                  <img src={require("../../assets/images/faces/face1.jpg")} alt="user" />
                  <span className="availability-status online"></span>
                </div>
                <div className="nav-profile-text">
                  <p className="mb-1 text-black"><Trans>{currentUser?.name + ' ' + currentUser?.surname}</Trans></p>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu className="navbar-dropdown">
                <Dropdown.Item href="!#" onClick={evt => evt.preventDefault()}>
                  <i className="mdi mdi-cached mr-2 text-success"></i>
                  <Trans>Activity Log</Trans>
                </Dropdown.Item>
                <Dropdown.Item onClick={evt => evt.preventDefault()}>
                  <Link to='/user-settings'>
                    <i className="mdi mdi-settings mr-2 text-success"></i>
                    <Trans>Settings</Trans>
                  </Link>
                </Dropdown.Item>
                {currentUser?.isGoogleRegistration ?
                  <>
                    <Dropdown.Item onClick={evt => {
                      fw.post(`/authentication/logout`)
                        .then(response => {
                          window.location = '/user/login';
                        });
                      googleLogout();
                    }}>
                      <i className="mdi mdi-logout mr-2 text-primary"></i>
                      <Trans>Signout</Trans>
                    </Dropdown.Item>
                  </>
                  :
                  <>
                    <Dropdown.Item onClick={evt => {
                      fw.post(`/authentication/logout`)
                        .then(response => {
                          window.location = '/user/login';
                        });
                    }}>
                      <i className="mdi mdi-logout mr-2 text-primary"></i>
                      <Trans>Signout</Trans>
                    </Dropdown.Item>
                  </>
                }

              </Dropdown.Menu>
            </Dropdown>
          </li>
          <li className="nav-item">
            <Dropdown alignRight>
              <Dropdown.Toggle className={'nav-link ' + (messages?.filter((m) => { if (m.hasSeen === false) return m; }).length > 0 ? 'count-indicator' : '')}>
                <i className="mdi mdi-email-outline"></i>
                <span className="count-symbol bg-warning"></span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="preview-list navbar-dropdown">
                <h6 className="p-3 mb-0"><Trans>Messages</Trans></h6>
                <div className="dropdown-divider"></div>

                {
                  messages?.map((message) => {
                    return (
                      <>
                        <Dropdown.Item
                          className="dropdown-item preview-item"
                          onClick={evt => {
                            evt.preventDefault();
                            window.location = `/messages/view/${message.id}`;
                          }}
                        >
                          <div className="preview-thumbnail">
                            <img src={require("../../assets/images/faces/face4.jpg")} alt="user" className="profile-pic" />
                          </div>
                          <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                            <h6 className="preview-subject ellipsis mb-1 font-weight-normal"><Trans>{message.fullName} sent you a message</Trans></h6>
                            <p className="text-gray mb-0">
                              {
                                message.hasSeen ?
                                  <>Read </>
                                  :
                                  <>Unread </>
                              }
                              <>{moment(message.creationTime).format('lll')}</>
                            </p>
                          </div>
                        </Dropdown.Item>
                        <div className="dropdown-divider"></div>
                      </>
                    )
                  })
                }
                <h6 className="p-3 mb-0 text-center cursor-pointer">{messages.filter((message) => {
                  if (!message.hasSeen) {
                    return message;
                  }
                }).length} <Trans>new messages</Trans></h6>
              </Dropdown.Menu>
            </Dropdown>
          </li>
          <li className="nav-item">
            <Dropdown alignRight>
              <Dropdown.Toggle className={'nav-link ' + (notifications?.filter((m) => { if (m.hasSeen === false) return m; }).length > 0 ? 'count-indicator' : '')}>
                <i className="mdi mdi-bell-outline"></i>
                <span className="count-symbol bg-danger"></span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu navbar-dropdown preview-list">
                <h6 className="p-3 mb-0"><Trans>Notifications</Trans></h6>
                <div className="dropdown-divider"></div>
                {notifications.map((notification) => {
                  const backgroundClass = notification.hasSeen ? 'preview-icon bg-success' : 'preview-icon bg-warning';
                  const icon = notification.hasSeen ? 'mdi mdi-check' : 'mdi mdi-exclamation';
                  console.log('notification', notification)
                  return (
                    <>
                      <Dropdown.Item className="dropdown-item preview-item"
                        onClick={e => {
                          e.preventDefault();
                          setViewingNotificationId(notification.notificationId);
                          fw.get(`/notifications/view?notificationId=${notification.userNotificaitonId}`)
                            .then(data => {
                              console.log('notification response', data);
                              setNotificationModalTitle(data.title);
                              setNotificationModalText(data.text);
                              setShowNotificationModal(true);
                            })
                          // window.location = `/notifications/view/${notification.id}`;
                        }}>
                        <div className="preview-thumbnail">
                          <div className={backgroundClass}>
                            <i className={icon}></i>
                          </div>
                        </div>
                        <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                          <h6 className="preview-subject font-weight-normal mb-1">{notification.title}</h6>
                          <p className="text-gray ellipsis mb-0">
                            {notification.text}
                          </p>
                        </div>
                      </Dropdown.Item>
                      <div className="dropdown-divider"></div>
                    </>
                  )
                })
                }
                <div className="dropdown-divider"></div>
                <h6 className="p-3 mb-0 text-center cursor-pointer"><Trans>See all notifications</Trans></h6>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
        <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={toggleOffcanvas}>
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
      <AlertModal
        setShowModal={setShowNotificationModal}
        showModal={showNotificationModal}
        title={notificationModalTitle}
        message={notificationModalText}
        onAfterHideModal={() => {
          // fw.post('/notifications/markAsSeen', { id: viewingNotificationId });
        }}
      />
    </nav>
  );
}