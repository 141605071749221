import React, { useRef, useState } from 'react';
import { TextField } from '../form-elements/TextField.js';
import { TextArea } from '../form-elements/TextArea.js';
import { DateSelector } from "../form-elements/DateSelector";
import moment from 'moment';
import RichTextBox from '../form-elements/RichTextBox.js';

const BetaTestGeneralInformation = ({ betaTest, areBetaTestInputsDisabled, displayIncentives, setFieldValue, renderFullText, isAdd, ...props }) => {
    return (
        <>
            <h4 id="generalInformation">General Information</h4>
            <hr />

            {(renderFullText && !isAdd) && (<div className='card  col-lg-10 col-md-12 col-sm-12 pl-0 ml-0'>
                <div className='text-field-wrapper bg-warning rounded'>
                    <p className='text-center mt-2'>
                        This information will be displayed to testers at <a target='_blank' href={`https://www.betatestmyproduct.com/${betaTest.title}`}>https://www.betatestmyproduct.com/{betaTest.title}</a>
                    </p>
                </div>
            </div>
            )}

            <div className='col-lg-10 col-md-12 col-sm-12 pl-0 ml-0'>
                {renderFullText && (
                    <>
                        <TextField
                            className="h-auto form-control form-control-lg"
                            label="Name"
                            name="title"
                            type="text"
                            placeholder="Name"
                            disabled={areBetaTestInputsDisabled()}
                        />

                        <RichTextBox
                            className="h-auto form-control"
                            placeholder="Description"
                            label="Description"
                            name="description"
                            disabled={areBetaTestInputsDisabled()}
                            setFieldValue={setFieldValue}
                            defaultValue={betaTest?.description}
                        />

                        {displayIncentives && (
                            <RichTextBox
                                className="h-auto form-control"
                                placeholder="Incentives"
                                label="Incentives"
                                name="incentives"
                                disabled={areBetaTestInputsDisabled()}
                                setFieldValue={setFieldValue}
                                defaultValue={betaTest?.incentives}
                            />)}
                    </>
                )}
                <div className='col-12 row pl-0 ml-0'>
                    <span className="col-lg-4 col-md-6 col-sm-6 pl-0 ml-0">
                        <DateSelector
                            selectorDisabled={!isAdd}
                            label="Start Date"
                            selected={moment(betaTest?.startDate ?? new Date()).toDate()}
                            name="startDate"
                            className="form-control"
                        />
                    </span>
                    <span className="col-lg-4 col-md-6 col-sm-6">
                        <DateSelector
                            selectorDisabled={areBetaTestInputsDisabled()}
                            label="End Date"
                            selected={moment(betaTest?.endDate ?? new Date()).toDate()}
                            name="endDate"
                            className="form-control"
                        />
                    </span>
                </div>
            </div>
        </>
    )
}

export default BetaTestGeneralInformation;