import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Form, Field, errorMessage } from 'formik';
import { TextField } from '../form-elements/TextField.js';
import Cookies from 'universal-cookie';
import fetchWrapper from '../../util/fetchWrapper.js';
import AlertModal from '../shared/AlertModal.js';
import { CheckBox } from '../form-elements/CheckBox.js';
import handleSuccessfulLoginRedirect from './handleSuccessfulLogin.js';
import { ROLES } from '../models/enums/rolesEnum.js';
import { RadioButton } from '../form-elements/RadioButton.js';

const Register = (props) => {
  console.log(window.location.search);
  const queryParams = new URLSearchParams(window.location.search);
  const returnUrl = queryParams.get('returnUrl');
  const preFilledEmail = queryParams.get('preFilledEmail');
  const subQuery = new URLSearchParams(`?${returnUrl?.split('?')[1]}`);
  const code = subQuery.get('code');
  const acceptInvitation = subQuery.get('acceptInvitation');
  const [modalMessage, setModalMessage] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [displayEmail, setDisplayEmail] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);
  const fw = fetchWrapper();
  const [accountType, setAccountType] = useState(preFilledEmail ? ROLES.TESTER : ROLES.COMPANY);
  const confirmationRef = useRef(null);
  const validate = Yup.object({
    firstName: Yup.string()
      .min(3, 'Must be 3-15 characters')
      .max(15, 'Must be 3-15 characters')
      .required('First Name Required'),
    lastName: Yup.string()
      .min(1, 'Must be 1-20 characters')
      .max(20, 'Must be 1-20 characters')
      .required('Last Name Required'),
    email: Yup.string()
      .email('Email is invalid')
      .required('Email is required'),
    password: Yup.string()
      .min(3, 'Password must be at least 6 charaters')
      .required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Password must match')
      .required('Confirm password is required'),
    tos: Yup.boolean().oneOf([true], 'You must accept the terms and conditions'),
    companyName: Yup.string().test(
      'companyName',
      'Company name is required',
      function (value) {
        if (accountType === ROLES.COMPANY && !value) {
          return false;
        }
        return true;
      }
    ),
  });
  return (
    <div>
      <div className="d-flex align-items-center auth px-0">
        <div className="row w-100 mx-0">
          <div className="col-lg-4 mx-auto">
            <div className="auth-form-light text-left py-5 px-4 px-sm-5">
              <div className='mb-3 pb-3'>
                <a href="https://www.betatestmyproduct.com/">
                  <img src={require("../../assets/images/full-logo-purple.png")} alt="logo" />
                </a>
              </div>
              {
                showConfirmationMessage ?
                  <a href={'/user/login'}
                    style={{ textDecoration: 'none', color: 'black' }}
                    ref={confirmationRef}
                  >
                    <div className='card bg-warning'
                      style={{ textAlign: 'center' }}
                    >
                      <p style={{ fontSize: '120%' }}>Thank you for signing up!</p>
                      {/* <br /> */}
                      <p>Please confirm your email sent to <a href={'mailto:' + displayEmail}>{displayEmail}</a></p>
                      {/* <br /> */}
                      <p>Click on this banner to return to the login page.</p>
                    </div>
                  </a>
                  :
                  <></>
              }

              <h4>New here?</h4>
              <h6 className="font-weight-light">Signing up is easy. It only takes a few steps</h6>
              <Formik
                initialValues={{
                  firstName: '',
                  lastName: '',
                  email: preFilledEmail ?? '',
                  companyName: '',
                  password: '',
                  confirmPassword: '',
                  tos: false
                }}
                validationSchema={validate}
                onSubmit={values => {
                  values.accountType = accountType;
                  console.log(values);
                  values.code = code;
                  values.acceptInvitation = acceptInvitation;
                  if (accountType === ROLES.TESTER) {
                    delete values.companyName;
                  }
                  fw.post(`/authentication/register`, values)
                    .then(data => {
                      if (data.errorMessage) {
                        setModalTitle('Registration Error');
                        setModalMessage(data.errorMessage);
                        setShowModal(true);
                      }

                      if (data.userEmailTaken) {
                        setModalTitle('Registration Error');
                        setModalMessage('email address is already taken!');
                        setShowModal(true);
                      }
                      console.log(`returned data from registration: ${JSON.stringify(data)}`);
                      // if (data.token) {
                      //   if (data?.isAuthenticated === true) {
                      //     window.location = '/dashboard';
                      //   }
                      // }

                      if (data.isAuthenticated === true) {
                        handleSuccessfulLoginRedirect();
                      }

                      if (data.confirmEmail) {
                        setModalTitle('Please confirm email');
                        setDisplayEmail(values.email);
                        setShowConfirmationMessage(true);
                        confirmationRef.current.scrollIntoView({ behavior: 'smooth' });
                      }
                    });
                }}
              >
                <Form className="pt-3">
                  <TextField label="First Name" placeholder="First Name" name="firstName" type="text" />
                  <TextField label="Last Name" placeholder="Last Name" name="lastName" type="text" />
                  <TextField label="Company Name" placeholder="Company Name" name="companyName" type="text" hidden={accountType === ROLES.TESTER} />
                  <TextField disabled={preFilledEmail !== null} label="Email" placeholder="Email" name="email" type="email" />
                  <TextField label="Password" placeholder="Password" name="password" type="password" />
                  <TextField label="Confirm Password" placeholder="Confirm Password" name="confirmPassword" type="password" />
                  <label className="form-check-label ">Account Type</label>
                  <div className='row col-12'>
                    <RadioButton
                      className="form-check col-6 text-justify"
                      name="accountType"
                      id="accountType1"
                      label="Company"
                      onClick={() => { console.log('company clicked'); setAccountType(ROLES.COMPANY); }}
                      checked={accountType == ROLES.COMPANY}
                      disabled={preFilledEmail !== null}
                    />
                    <RadioButton
                      className="form-check col-6 text-justify"
                      name="accountType"
                      id="accountType2"
                      label="Tester"
                      onClick={() => { console.log('tester clicked'); setAccountType(ROLES.TESTER); }}
                      checked={accountType == ROLES.TESTER}
                      disabled={preFilledEmail !== null}
                    />
                  </div>
                  <CheckBox
                    // checked={isTosChecked ?? false}
                    name="tos"
                    displayName="I agree to all Terms & Conditions"
                    className="text-muted"
                  />
                  <div className="mt-3">
                    <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" to="/dashboard">SIGN UP</button>
                  </div>
                  <div className="text-center mt-4 font-weight-light">
                    Already have an account? <Link to={`/user/login${returnUrl ? `?returnUrl=${encodeURIComponent(returnUrl)}` : ''}`} className="text-primary">Login</Link>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <AlertModal title={modalTitle} message={modalMessage} showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
}

export default Register;