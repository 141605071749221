import React, { useState, useEffect } from 'react';
import fetchWrapper from '../../util/fetchWrapper';
import * as Yup from 'yup';
import { Formik, Form, Field, errorMessage } from 'formik';
import { TextField } from '../form-elements/TextField.js';
import AlertModal from '../shared/AlertModal';
import { useHistory } from 'react-router-dom';
import { CheckBox } from '../form-elements/CheckBox';


const AddOrEditRole = (props) => {
    const [role, setRole] = useState(undefined);
    const [permissions, setPermissions] = useState(undefined);
    const [rolePermissions, setRolePermissions] = useState(undefined);
    const { roleId } = props.match.params;
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const fw = fetchWrapper();
    const history = useHistory();
    const isAdd = window.location.toString().includes('/roles/add');

    const cancelEdit = (e) => {
        e.preventDefault();
        history.goBack();
    }

    useEffect(() => {
        if (isAdd) {
            fw.get(`/roles/get-permissions-for-add`)
                .then(data => {
                    console.log(data);
                    setRolePermissions(data.rolePermissions);
                    setPermissions(data.permissions);
                });
            return;
        }
        fw.post(`/roles/get-role-by-id`, { roleId })
            .then(data => {
                console.log('roles', data);
                console.log('permissions', data.permissions);
                setRole(data.role);
                setRolePermissions(data.rolePermissions);
                setPermissions(data.permissions);
            })
    }, []);

    let validate;
    if (isAdd) {
        validate = Yup.object({
            name: Yup.string()
                .required('Name is required'),
            description: Yup.string()
                .required('Description is required'),
        });
    } else {
        validate = Yup.object({
            name: Yup.string()
                .required('Name is required'),
            description: Yup.string()
                .required('Description is required'),
        });
    }


    let initialValues;
    if (isAdd) {
        initialValues = {
            name: '',
            description: '',
            isActive: true,
        };
    } else {
        initialValues = {
            name: role?.name ?? '',
            description: role?.description ?? '',
            isActive: role?.isActive ?? true,
        };
    }

    const handleSubmit = (values) => {
        if (isAdd) {
            console.log(`add user values: ${JSON.stringify(values)}`);
            const newRole = {
                name: values.name,
                description: values.description,
                isActive: values.isActive,
                grantedPermissions: rolePermissions
            };

            console.log(newRole);

            fw.post(`/roles/save-role`, newRole)
                .then(response => {
                    if (response.id) {
                        window.location = `/roles/edit/${response.id}`;
                        return;
                    }
                });

            return;
        }

        console.log(`edit users values: ${JSON.stringify(values)}`);
        const updatedRole = {
            id: role.id,
            name: values.name,
            description: values.description,
            isActive: values.isActive,
            grantedPermissions: rolePermissions
        };
        console.log(`role: ${JSON.stringify(updatedRole)}`);
        fw.post(`/roles/update-role`, updatedRole)
            .then(response => {
                console.log('response');
                if (response.errorMessage) {
                    console.log('in error state');
                    setModalTitle('Error');
                    setModalMessage(response.errorMessage);
                    setShowModal(true);
                    return;
                }
                console.log('attempting ot open success modal');
                setModalTitle('Success');
                setModalMessage('Role was updated!');
                setShowModal(true);
            });
    }


    return (
        <div className='card'>
            <Formik
                initialValues={initialValues}
                validationSchema={validate}
                validateOnChange={true}
                enableReinitialize={true}
                onSubmit={values => {
                    handleSubmit(values);
                }}
            >
                <Form>
                    <div className='card-body'>
                        <div className='card-title'>
                            <div className='row'>
                                <div className='col-10'>
                                    <h4>
                                        {isAdd ? 'Add' : 'Edit'} Role: {role?.name}
                                    </h4>
                                </div>
                                <div className='row form-group col-2'>
                                    <CheckBox
                                        checked={role?.isActive ?? false}
                                        name="isActive"
                                        displayName="Is Active"
                                        onChange={(e) => {
                                            setRole({ ...role, isActive: !role?.isActive ?? false })
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <TextField className="h-auto form-control form-control-lg" label="Name" name="name" type="text" placeholder="Name" />
                        <TextField className="h-auto form-control form-control-lg" label="Description" name="description" type="text" placeholder="Description" />

                        <h4>Permissions</h4>
                        {permissions ?
                            <div className='row form-group' style={{ marginLeft: '0px' }}>
                                {permissions.map(function (permission) {
                                    // const checked = rolePermissions !== undefined ? rolePermissions[permission.name] : false;
                                    return (
                                        <CheckBox
                                            checked={rolePermissions[permission.name]}
                                            name={permission.name}
                                            displayName={permission.name}
                                            onChange={(e) => {
                                                const updatedRoles = rolePermissions;
                                                updatedRoles[e.target.name] = e.target.checked;
                                                setRolePermissions({
                                                    ...updatedRoles,
                                                });
                                            }}
                                        />
                                    )
                                })}
                            </div>
                            : <></>}
                        <button type="submit" className="btn btn-gradient-primary mr-2">Submit</button>
                        <button className="btn btn-light" onClick={cancelEdit}>Cancel</button>

                    </div>
                    <AlertModal showModal={showModal} setShowModal={setShowModal} message={modalMessage} title={modalTitle} onAfterHideModal={() => {
                        // window.location = '/roles'
                        setShowModal(false);
                        history.goBack();
                    }} />
                </Form>
            </Formik>
        </div>
    );
}

export default AddOrEditRole;
