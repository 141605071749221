import { Formik, Form } from 'formik';
import React, { useState, useEffect } from 'react';
import FetchWrapper from '../../util/fetchWrapper';
import { TextField } from '../form-elements/TextField';
const BetaTestSettings = ({ currentUser, ...props }) => {
    const fw = FetchWrapper();
    const { betaTestName } = props.match.params;

    useEffect(() => {
        fw.get('/betatests/get-beta-test-settings?betaTestName=' + betaTestName)
            .then(data => {
                console.log(data);
            })
    }, [betaTestName]);
    const initialValues = {

    }

    const handleSubmit = (values) => {
    }

    return (
        <div className='card'>
            <div className='card-body'>
                <Formik initialValues={initialValues}
                    onSubmit={() => {

                    }}>
                    <Form onSubmit={handleSubmit}>
                        <TextField
                            label="Title"
                            name="title"
                            type="text"
                            placeholder="Title"
                        />
                    </Form>
                </Formik>
            </div>
        </div>
    )
}

export default BetaTestSettings;