import React, { useState, useEffect } from 'react';
import fetchWrapper from '../../util/fetchWrapper';
import * as Yup from 'yup';
import { Formik, Form, Field, errorMessage } from 'formik';
import { TextField } from '../form-elements/TextField.js';
import { CheckBox } from '../form-elements/CheckBox.js';
import AlertModal from '../shared/AlertModal';
import { useHistory } from 'react-router-dom';

const AddOrEditPermission = (props) => {
    const [permission, setPermission] = useState(undefined);
    const { permissionId } = props.match.params;
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const fw = fetchWrapper();
    const history = useHistory();
    const isAdd = window.location.toString().includes('/permissions/add');
    const cancelButtonClick = (e) => {
        e.preventDefault();
        history.goBack();
    }

    useEffect(() => {
        if (isAdd) {
            return;
        }
        fw.post(`/permissions/get-permission-by-id`, { permissionId })
            .then(data => {
                if (data.errorMessage) {
                    setModalMessage(data.errorMessage);
                    setModalTitle('Error');
                    setShowModal(true);
                    return;
                }

                setPermission(data.permission);
            })
    }, []);

    const handleSubmit = (values) => {
        if (isAdd) {
            const newPermission = {
                name: values.name,
                description: values.description,
                isGranted: values.isGranted,
                frontEndUrl: values.frontEndUrl,
                apiUrl: values.apiUrl,
            }

            fw.post(`/permissions/save-permission`, newPermission)
                .then(res => {
                    if (res.errorMessage) {
                        setModalMessage(res.errorMessage);
                        setModalTitle('Error!');
                        setShowModal(true);
                        return;
                    }
                    setModalMessage('Permission successfully saved!');
                    setModalTitle('Success!');
                    setShowModal(true);
                });
            return;
        }

        const updatedPermission = {
            id: permission.id,
            name: values.name,
            description: values.description,
            isGranted: values.isGranted,
            frontEndUrl: values.frontEndUrl,
            apiUrl: values.apiUrl
        }

        console.log(`permission object: ${JSON.stringify(updatedPermission)}`);

        fw.post(`/permissions/update-permission`, updatedPermission)
            .then(res => {
                if (res.errorMessage) {
                    setModalMessage(res.errorMessage);
                    setModalTitle('Error');
                    setShowModal(true);
                    return;
                }
                setModalMessage('Permission successfully updated!');
                setModalTitle('Success!');
                setShowModal(true);

            });
    }

    let validate;
    if (isAdd) {
        validate = Yup.object({
            name: Yup.string()
                .required('Name is required'),
            description: Yup.string()
                .required('Description is required'),
            frontEndUrl: Yup.string()
                .required('Front End Url is required'),
            apiUrl: Yup.string()
                .required('API Url is required')

        });
    } else {
        validate = Yup.object({
            name: Yup.string()
                .required('Name is required'),
            description: Yup.string()
                .required('Description is required'),
            frontEndUrl: Yup.string()
                .required('Front End Url is required'),
            apiUrl: Yup.string()
                .required('API Url is required')
        });
    }

    let initialValues;
    if (isAdd) {
        initialValues = {
            name: permission?.name ?? '',
            description: permission?.description ?? '',
            roleName: permission?.roleName ?? '',
            apiUrl: '',
            frontEndUrl: '',
            isGranted: true,
        };
    } else {
        initialValues = {
            name: permission?.name ?? '',
            description: permission?.description ?? '',
            roleName: permission?.roleName ?? '',
            apiUrl: permission?.apiUrl ?? '',
            frontEndUrl: permission?.frontEndUrl ?? '',
            isGranted: permission?.isGranted ?? true
        };
    }

    return (
        <div className='card'>
            <Formik
                initialValues={initialValues}
                validationSchema={validate}
                validateOnChange={true}
                enableReinitialize={true}
                onSubmit={values => {
                    handleSubmit(values);
                }}
            >
                <Form>
                    <div className='card-body'>
                        <div className='card-title'>
                            <div className='row'>
                                <div className='col-10'>
                                    <h4>
                                        {isAdd ? 'Add' : 'Edit'} Permission: {permission?.name}
                                    </h4>
                                </div>
                                <div className='row form-group col-2'>
                                    <CheckBox
                                        checked={permission?.isGranted}
                                        name="isGranted"
                                        displayName="Is Active"
                                        onChange={(e) => {
                                            const permissionData = permission;
                                            console.log('permissionData: ', permissionData);
                                            permissionData["isGranted"] = e.target.checked;
                                            setPermission({
                                                ...permissionData,
                                            });
                                        }}
                                    />

                                </div>
                            </div>
                        </div>

                        <TextField className="h-auto form-control form-control-lg" label="Name" name="name" type="text" placeholder="Name" />
                        <TextField className="h-auto form-control form-control-lg" label="Description" name="description" type="text" placeholder="Description" />
                        <TextField className="h-auto form-control form-control-lg" label="API URL" name="apiUrl" type="text" placeholder="API URL" />
                        <TextField className="h-auto form-control form-control-lg" label="Front End URL" name="frontEndUrl" type="text" placeholder="Front End URL" />

                        <button type="submit" className="btn btn-gradient-primary mr-2">Submit</button>
                        <button className="btn btn-light" onClick={cancelButtonClick}>Cancel</button>

                    </div>
                    <AlertModal showModal={showModal} setShowModal={setShowModal} message={modalMessage} title={modalTitle} onAfterHideModal={() => { history.goBack() }} />
                </Form>
            </Formik>
        </div>
    )
}

export default AddOrEditPermission;