import { useState, useEffect } from 'react';

const useMargin = (nodeId) => {
    const [marginBottom, setMarginBottom] = useState(0);
    // const [renderCount, setRenderCount] = useState(0);

    useEffect(() => {
        const updateMargin = () => {
            const element = document.getElementById(nodeId);
            if (!element) return;

            const computedStyle = window.getComputedStyle(element);
            const h = computedStyle.getPropertyValue('height');
            const mb = computedStyle.getPropertyValue('margin-bottom');
            // console.log('calculating margin for setting marginBottom', h, mb);
            const marginBottomValue = (Number(mb.replace('px', '')) + (Number(h.replace('px', ''))));
            // console.log(`calculating margin for ${nodeId} element`, element, element.offsetHeight);
            // console.log('calculating margin for setting marginBottom', marginBottom, Number(mb.substring(0, mb.length - 2)), element.offsetHeight)
            setMarginBottom(marginBottomValue);
        };

        // Use double requestAnimationFrame for the initial calculation
        // requestAnimationFrame(() => {
        //     requestAnimationFrame(() => {
        updateMargin();
        //     });
        // });

        // Add event listener for window resize
        window.addEventListener('resize', updateMargin);

        // Cleanup the event listener on unmount
        return () => {
            window.removeEventListener('resize', updateMargin);
        };
    }, [nodeId]);

    // Force a re-render after a delay
    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         console.log('calculating margin for setting marginBottom rerender', renderCount);
    //         setRenderCount(prev => prev + 1);
    //     }, 500); // 500ms delay

    //     return () => clearTimeout(timer);
    // }, []);

    return marginBottom;
};

export default useMargin;
