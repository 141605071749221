import React, { Component } from 'react';
import PropTypes from 'prop-types';

import PopupWindow from './PopupWindow';
import { toQuery } from '../util/utils';

class LoginJira extends Component {
    static propTypes = {
        buttonText: PropTypes.string,
        children: PropTypes.node,
        className: PropTypes.string,
        clientId: PropTypes.string.isRequired,
        onRequest: PropTypes.func,
        onSuccess: PropTypes.func,
        onFailure: PropTypes.func,
        popupHeight: PropTypes.number,
        popupWidth: PropTypes.number,
        redirectUrl: PropTypes.string,
        scope: PropTypes.string,
        disabled: PropTypes.bool
    }

    static defaultProps = {
        buttonText: 'Sign in with Jira',
        redirectUrl: '',
        scope: 'read:jira-work write:jira-work read:me offline_access',
        // scope: 'read:jira-work manage:jira-project read:jira-user write:jira-work offline_access',
        popupHeight: 650,
        popupWidth: 500,
        onRequest: () => { },
        onSuccess: () => { },
        onFailure: () => { },
    }

    onBtnClick = (e) => {
        e.preventDefault();
        const { clientId, scope, redirectUrl, popupHeight, popupWidth, responseType } = this.props;

        const search = toQuery({
            client_id: clientId,
            scope: encodeURIComponent(scope),
            redirect_uri: encodeURIComponent(redirectUrl),
            state: ('jira_' +
                Math.random().toString(36) +
                Date.now().toString(36)),
            response_type: responseType,
            prompt: 'consent'
        });
        const authUrl = `https://auth.atlassian.com/authorize?audience=api.atlassian.com&${search}`;
        console.log('jira');
        console.dir(authUrl);

        // To fix issues with window.screen in multi-monitor setups, the easier option is to
        // center the pop-up over the parent window.
        const top = window.top.outerHeight / 2 + window.top.screenY - (popupHeight / 2);
        const left = window.top.outerWidth / 2 + window.top.screenX - (popupWidth / 2);
        const popup = this.popup = PopupWindow.open(
            'jira-oauth-authorize',
            authUrl,
            {
                height: popupHeight,
                width: popupWidth,
                top: top,
                left: left
            }
        );

        this.onRequest();
        popup.then(
            data => this.onSuccess(data),
            error => this.onFailure(error)
        );
    }

    onRequest = () => {
        this.props.onRequest();
    }

    onSuccess = (data) => {
        console.log('jira data raw ', data);

        if (data.returnUrl) {
            const queryParams = new URLSearchParams(decodeURIComponent(data.returnUrl).substring(2));
            data.code = queryParams.get('code');
        }

        if (!data.code) {
            return this.onFailure(new Error('\'code\' not found'));
        }

        this.props.onSuccess(data);
    }

    onFailure = (error) => {
        console.error('raw jira failure', error);
        this.props.onFailure(error);
    }

    render() {
        const { className, buttonText, children, disabled } = this.props;
        const attrs = {
            onClick: this.onBtnClick,
            className: className || '',
            disabled: disabled || false
        };
        return <button {...attrs}>{children || buttonText}</button>;
    }
}

export default LoginJira;
