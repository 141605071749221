import React, { useState, useEffect } from "react";
import { Spinner } from '../shared/Spinner';
import fetchWrapper from "../../util/fetchWrapper";

const Stripe = () => {
    const [products, setProducts] = useState(undefined);
    const fw = fetchWrapper();
    useEffect(() => {
        fw.get(`/checkout/get-products`)
            .then((response) => {
                console.log(response);
                setProducts(response);
            })
            .catch(err => console.log(err));
    }, []);

    const handlePurchaseClick = (event, priceId) => {
        event.preventDefault();
        console.log(event.target);
        console.log(priceId);
        fw.post(`/checkout/create-checkout-session`, { priceId })
            .then((response) => {
                console.log(response);
                window.location = response.url;
            });

    };

    return (
        products !== undefined ?
            <div className="row">
                {products.data.map((product, index) => {
                    return <div className="col-md-3 grid-margin stretch-card" key={product.id}>
                        <div className="card col-12 align-content-center">
                            <div className="card-body text-center mx-auto">
                                <h4 className="card-title">{product.product.name}</h4>
                                <div className="row">
                                    <p className="lead text-info col-12"><i className="mdi mdi-currency-usd" />{(Number(product.unit_amount) * .01).toFixed(2)}</p>
                                </div>
                                <div className="row">
                                    <button className="btn btn-gradient-primary btn-fw col-12" onClick={(e) => handlePurchaseClick(e, product.id)}>Purchase</button>
                                </div>
                            </div>
                        </div>
                    </div>
                })}
            </div> :
            <Spinner />
    );
};

export default Stripe;