import React, { Component, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { TextField } from '../form-elements/TextField.js';
import * as Yup from 'yup';
import fetchWrapper from '../../util/fetchWrapper.js';
import AlertModal from '../shared/AlertModal.js';
import ConfirmationModal from '../shared/ConfirmationModal.js';
import LoginGithub from './LoginGithub.js';
import LoginAsana from './LoginAsana.js';
import LoginJira from './LoginJira.js';
import { CheckBox } from '../form-elements/CheckBox.js';
import handleSuccessfulLoginRedirect from './handleSuccessfulLogin.js';
import { ROLES } from '../models/enums/rolesEnum.js';

import { useGoogleLogin } from '@react-oauth/google';
import './Login.css';
const Login = (props) => {
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");
  const changedPassword = queryParams.get("changedPassword");
  const returnUrl = queryParams.get('returnUrl');
  const state = queryParams.get('state');
  const code = queryParams.get('code');
  const [isSubmittingGoogle, setIsSubmittingGoogle] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tokenToVerify, setTokenToVerify] = useState('');
  const [hasChangedPassword, setHasChangedPassword] = useState('');
  const fw = fetchWrapper();
  const [confirmedEmailAddress, setConfirmedEmailAddress] = useState('');
  const [showConfirmedEmailAddress, setShowConfirmedEmailAddress] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('Error');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationModalMessage, setConfirmationModalMessage] = useState('');
  const [confirmationModalTitle, setConfirmationModalTitle] = useState('');
  const [oneClickSignUpAccountType, setOneClickSignUpAccountType] = useState(3);
  const [hasSuccessfullThirdPartyLogin, setHasSuccessfullThirdPartyLogin] = useState(false);
  const validate = Yup.object({
    email: Yup.string()
      .email('Email is invalid')
      .required('Email is required'),
    password: Yup.string()
      .min(3, 'Password must be at least 6 charaters')
      .required('Password is required'),
  });

  useEffect(() => {
    if (token && tokenToVerify === '') {
      setTokenToVerify(token);
    }

    if (changedPassword && hasChangedPassword === '') {
      setHasChangedPassword(changedPassword);
    }
  });

  useEffect(() => {
    if (tokenToVerify !== '') {
      fw.post('/authentication/verify-email-for-signup', { token: tokenToVerify })
        .then(data => {
          console.log('verify token data', data);
          if (data.errorMessage) {
            setModalMessage(data.errorMessage);
            setShowModal(true);
          }
          setConfirmedEmailAddress(data.ConfirmedEmailAddress);
          setShowConfirmedEmailAddress(true);
        });
    }

  }, [tokenToVerify]);

  useEffect(() => {
    function receiveMessage(event) {
      if (!event.data.receivedUrl || !event.data.receivedUrl.includes('?')) {
        return;
      }
      console.log("Received message from child:", event.data);
      const queryParams = new URLSearchParams(event.data.receivedUrl.split('?')[1]);
      const code = queryParams.get('code');
      fw.post('/authentication/asana-code', { code: code, generateBtmpCookies: true })
        .then(data => {
          if (data.errorMessage) {
            setModalMessage(data.errorMessage);
            setShowModal(true);
          }
          else {
            // handleSuccessfulLoginRedirect();
            setHasSuccessfullThirdPartyLogin(true);
            if (data.bypassModal === false) {
              setConfirmationModalTitle('What type of account do you want to create?');
              setShowConfirmationModal(true);
            }
            if (data.bypassModal === true) {
              handleSuccessfulLoginRedirect();
            }
          }
        })
    }

    window.addEventListener("message", receiveMessage, false);

    // Clean up the listener when the component unmounts
    return () => {
      window.removeEventListener("message", receiveMessage, false);
    };
  }, []);

  const onSuccessGoogleResponse = (response) => {
    console.log('google response', response);
    fw.post('/authentication/google-login', { accessToken: response.access_token })
      .then(data => {
        if (data.errorMessage) {
          setModalMessage(data.errorMessage);
          setShowModal(true);
        }
        else {
          // handleSuccessfulLoginRedirect();
          setHasSuccessfullThirdPartyLogin(true);
          if (data.bypassModal === false) {
            setConfirmationModalTitle('What type of account do you want to create?');
            setShowConfirmationModal(true);
          }
          if (data.bypassModal === true) {
            handleSuccessfulLoginRedirect();
          }
        }
      });
  };

  const onFailureGoogleResponse = (response) => {
    console.log('error response', response);
    if (!isSubmittingGoogle) {
      return;
    }
    setModalTitle('Google Login Failure');
    setModalMessage('Google login failed. Please try again.');
    setShowModal(true);
    return;
  };

  const googleLoginClick = useGoogleLogin({
    onSuccess: onSuccessGoogleResponse,
    onError: onFailureGoogleResponse,
    // flow: 'auth-code',
  });
  const onJiraFailure = (response) => {
    console.log('Jira failure', response);
  };

  const onJiraSuccess = (response) => {
    console.log('Jira success', response);

    fw.post('/authentication/jira-login', { token: response.code, generateBtmpCookies: true })
      .then(data => {
        if (data.errorMessage) {
          setModalMessage(data.errorMessage);
          setShowModal(true);
        }
        else {
          // handleSuccessfulLoginRedirect();
          setHasSuccessfullThirdPartyLogin(true);
          if (data.bypassModal === false) {
            setConfirmationModalTitle('What type of account do you want to create?');
            setShowConfirmationModal(true);
          }
          if (data.bypassModal === true) {
            handleSuccessfulLoginRedirect();
          }
        }
      });
  }

  const onGithubFailure = (response) => {
    console.log('github failure', response);
  };

  const onGithubSuccess = (response) => {
    console.log('github success', response);
    fw.post('/authentication/github-login', { token: response.code, generateBtmpCookies: true })
      .then(data => {
        if (data.errorMessage) {
          setModalMessage(data.errorMessage);
          setShowModal(true);
        }
        else {
          // handleSuccessfulLoginRedirect();
          setHasSuccessfullThirdPartyLogin(true);
          if (data.bypassModal === false) {
            setConfirmationModalTitle('What type of account do you want to create?');
            setShowConfirmationModal(true);
          }
          if (data.bypassModal === true) {
            handleSuccessfulLoginRedirect();
          }
        }
      });
  };

  const onAsanaSuccess = (response) => {
    fw.post('/authentication/asana-code', { code: response.code, generateBtmpCookies: true })
      .then(data => {
        if (data.errorMessage) {
          setModalMessage(data.errorMessage);
          setShowModal(true);
        }
        else {
          // handleSuccessfulLoginRedirect();
          setHasSuccessfullThirdPartyLogin(true);
          if (data.bypassModal === false) {
            setConfirmationModalTitle('What type of account do you want to create?');
            setShowConfirmationModal(true);
          }
          if (data.bypassModal === true) {
            handleSuccessfulLoginRedirect();
          }
        }
      });
  }

  const onAsanaFailure = (response) => {
    console.log('asana failure', response);
  }

  const modalBody = (
    <>
      <div className='row col-12'>
        <div className="form-check col-6 text-justify">
          <label className="form-check-label">
            <input type="radio" className="form-check-input" name="optionsRadios" id="optionsRadios1" value="" onClick={() => { setOneClickSignUpAccountType(ROLES.COMPANY); }} checked={oneClickSignUpAccountType == ROLES.COMPANY} />
            <i className="input-helper"></i>
            Company
          </label>
        </div>
        <div className="form-check col-6 text-justify">
          <label className="form-check-label">
            <input type="radio" className="form-check-input" name="optionsRadios" id="optionsRadios2" value="" onClick={() => { setOneClickSignUpAccountType(ROLES.TESTER); }} checked={oneClickSignUpAccountType == ROLES.TESTER} />
            <i className="input-helper"></i>
            Tester
          </label>
        </div>
      </div>
      <div className='rol col-12' hidden={oneClickSignUpAccountType === ROLES.COMPANY}>

      </div>
    </>
  );

  return (
    <div>
      <i className="asana mr-2"></i>
      <div className="d-flex align-items-center auth px-0">
        <div className="row w-100 mx-0">
          <div className="col-lg-4 mx-auto">
            <div className="auth-form-light text-left py-5 px-4 px-sm-5">
              <div
                // className="brand-logo"
                className='mb-3 pb-3'
              >
                <a href="https://www.betatestmyproduct.com/">
                  <img src={require("../../assets/images/full-logo-purple.png")} alt="logo" />
                </a>
              </div>
              {
                showConfirmedEmailAddress ?
                  <div className='card bg-warning'
                    style={{ textAlign: 'center' }}
                  >
                    <p style={{ fontSize: '120%' }}>Thank you for confirming your email!</p>
                    <p>You can now log in by entering your credentials below for {confirmedEmailAddress}</p>
                  </div>
                  :
                  <></>
              }
              {
                hasChangedPassword ?
                  <>
                    <div className='card bg-success'
                      style={{ textAlign: 'center' }}
                    >
                      <p style={{ fontSize: '120%' }}>Your password has been changed</p>
                      <p>You may log in with the new credentials</p>
                    </div>
                  </>
                  :
                  <></>
              }
              <h4>Hello! let's get started</h4>
              <h6 className="font-weight-light">Sign in to continue.</h6>
              <Formik
                initialValues={{
                  email: '',
                  password: ''
                }}
                validationSchema={validate}
                validateOnBlur={false}
                onSubmit={values => {
                  if (!isSubmitting) {
                    return;
                  }
                  fw.post(`/authentication/login`, values)
                    .then(data => {
                      if (data.isAuthenticated) {
                        handleSuccessfulLoginRedirect();

                      } else if (data.errorMessage) {
                        setModalMessage(data.errorMessage);
                        setShowModal(true);
                      }
                    });
                }}
              >
                <Form
                  className="pt-3">
                  <TextField className="h-auto form-control form-control-lg" label="Email" name="email" type="email" placeholder="Email" />
                  <TextField className="h-auto form-control form-control-lg" label="Password" name="password" type="password" placeholder="Password" />
                  <div className="mt-3">
                    <button
                      variant='primary'
                      type='submit'
                      className='btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn'
                      onClick={(e) => { setIsSubmitting(true) }}
                    >
                      Sign In
                    </button>
                  </div>
                  <div className="my-2 d-flex justify-content-between align-items-center">
                    <CheckBox
                      name="keepMeSignedIn"
                      displayName="Keep me signed in"
                      labelClassName="form-check-label text-muted"
                    />
                    <Link to="/user/reset-password" className="auth-link text-black">Forgot password?</Link>
                  </div>
                  <div className="mb-2">

                    <div id="signInButton">
                      <button type="button" className="btn btn-block btn-google auth-form-btn"
                        onClick={() => googleLoginClick()}
                      >
                        <i className="mdi mdi-google mr-2"></i>Connect using Google
                      </button>
                    </div>
                  </div>
                  <div className="mb-2">
                    <LoginGithub clientId={process.env.REACT_APP_GITHUB_CLIENT_ID}
                      className="btn btn-block btn-github auth-form-btn"
                      children={(<><i className="mdi mdi-github-circle mr-2"></i>Connect using Github</>)}
                      onSuccess={onGithubSuccess}
                      onFailure={onGithubFailure}
                      scope="repo, user, project"
                    />
                  </div>
                  <div className='mb-2'>
                    {/* <button type="button" className="btn btn-block btn-asana auth-form-btn"
                    // onClick={() => googleLoginClick()}
                    >
                      <i className="mdi asana mr-2"></i>Connect using Asana
                    </button> */}

                    <LoginAsana clientId={process.env.REACT_APP_ASANA_CLIENT_ID}
                      redirectUri={process.env.REACT_APP_ASANA_REDIRECT_URL}
                      className="btn btn-block btn-asana auth-form-btn"
                      children={(<><i className="mdi asana mr-2"></i>Connect using Asana</>)}
                      onSuccess={onAsanaSuccess}
                      onFailure={onAsanaFailure}
                      scope="default"
                    />

                  </div>
                  {false && (<div className='mb-2'>
                    {/* <button type="button" className="btn btn-block btn-asana auth-form-btn"
                    // onClick={() => googleLoginClick()}
                    >
                      <i className="mdi asana mr-2"></i>Connect using Asana
                    </button> */}

                    <LoginJira clientId={process.env.REACT_APP_ATLASSIAN_CLIENT_ID}
                      redirectUrl={process.env.REACT_APP_ASANA_REDIRECT_URL} //this is fine
                      className="btn btn-block btn-jira auth-form-btn"
                      children={(<><i className="mdi jira mr-2"></i>Connect using Jira</>)}
                      onSuccess={onJiraSuccess}
                      onFailure={onJiraFailure}
                      scope="read:jira-work write:jira-work read:me offline_access"
                      // scope="read:jira-work manage:jira-project manage:jira-configuration read:jira-user write:jira-work manage:jira-webhook manage:jira-data-provider offline_access"
                      responseType="code"
                    />
                  </div>)}
                  {/* <div className="mb-2">
                    <button type="button" className="btn btn-block btn-twitter auth-form-btn">
                      <i className="mdi mdi-twitter mr-2"></i>Connect using Twitter
                    </button>
                  </div> */}
                  <div className="text-center mt-4 font-weight-light">
                    Don't have an account? <Link to={`/user/register${returnUrl ? `?returnUrl=${encodeURIComponent(returnUrl)}` : ''}`} className="text-primary">Create</Link>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <AlertModal setShowModal={setShowModal} showModal={showModal} title={modalTitle} message={modalMessage} />
      <ConfirmationModal
        setShowModal={setShowConfirmationModal}
        showModal={showConfirmationModal}
        title={confirmationModalTitle}
        modalBody={modalBody}
        backdrop='static'
        keyboard={false}
        handleConfirmationClick={() => {
          fw.post('/users/update-account-type', { accountType: oneClickSignUpAccountType })
            .then(data => {
              if (data.success) {
                handleSuccessfulLoginRedirect();
              }
              else if (data.errorMessage) {
                setModalMessage(data.errorMessage);
                setShowModal(true);

              }
            })
        }}
        onAfterHideModal={() => {
          if (hasSuccessfullThirdPartyLogin) {
            handleSuccessfulLoginRedirect();
          }
        }}
      />
    </div >
  );
}

export default Login;