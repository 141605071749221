import React, { useState, useEffect } from 'react';
import fetchWrapper from '../../util/fetchWrapper';
import DataTable from 'react-data-table-component';

const Roles = (props) => {
    const [roles, setRoles] = useState(undefined);
    const [displayData, setDisplayData] = useState(undefined);
    const fw = fetchWrapper();
    const [hideFilters, setHideFilters] = useState(true);
    const [showIsInActive, setShowIsInActive] = useState(false);

    const filterData = (data) => {
        return data?.filter(function (role, index) {
            if ((!showIsInActive && !role.isActive)) {
                return;
            }

            return role;
        });
    }

    useEffect(() => {
        fw.get(`/roles/get-roles`)
            .then(data => {
                console.log(data);
                setRoles(data);
                setDisplayData(filterData(data));
            });
    }, []);

    useEffect(() => {
        setDisplayData(filterData(roles));
    }, [showIsInActive]);

    const editRole = (id) => {
        console.log(id);
        window.location = `/roles/edit/${id}`;
    };

    const deleteRole = (id) => {
        console.log(id);
    };

    const columns = React.useMemo(
        () => [
            {
                name: 'Name',
                selector: row => row.name,
                sortable: true,
            },
            {
                name: 'Description',
                selector: row => row.description,
                sortable: true,
            },
            {
                name: 'Is Active',
                selector: row => row.isActive,
                cell: row => {
                    return <div style={{ paddingLeft: '1rem' }}>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input
                                    checked={row.isActive}
                                    name={`isDeleted-${row.id}`}
                                    type="checkbox"
                                    className="form-check-input form-control"
                                />
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                },
                sortable: true,
            },
            {
                selector: row => row.id,
                sortable: true,
                cell: row => {
                    return (
                        <>
                            <button className='btn btn-gradient-primary mr-2' onClick={() => { editRole(row.id) }}>Edit</button>
                            <button className='btn btn-light' onClick={() => { deleteRole(row.id) }}>Delete</button>
                        </>
                    )
                }
                // omit: true,
            },
        ],
        [roles],
    );

    const filtersBoxMarginBottom = hideFilters ? { marginBottom: '0rem' } : { marginBottom: '1rem' };
    const showFiltersMarginBottom = hideFilters ? { marginBottom: '1rem' } : { marginBottom: '0rem' };

    return (
        <>
            <div>
                <button className='btn btn-gradient-primary mr-2'
                    style={{ ...showFiltersMarginBottom }}
                    onClick={() => { setHideFilters(!hideFilters) }}>{hideFilters ? 'Show' : 'Hide'} Filters</button>

                <button className='btn btn-gradient-primary mr-2'
                    onClick={() => { window.location = '/roles/add' }}
                    style={{ ...showFiltersMarginBottom, float: 'right' }}>+ Add Role</button>
            </div>
            <div className='card' style={{ ...filtersBoxMarginBottom, paddingLeft: '16px', paddingRight: '8px' }} hidden={hideFilters}>
                <div className='row' style={{ marginLeft: '0px' }}>
                    <h5>Filters</h5>
                </div>
                <div className='row' style={{ marginLeft: '0px' }}>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input
                                name="isActive"
                                type="checkbox"
                                className="form-check-input form-control"
                                onChange={(event) => { setShowIsInActive(!showIsInActive) }}
                            />
                            <i className="input-helper"></i>
                            Show Inactive
                        </label>
                    </div>
                </div>
            </div>
            <DataTable title="Roles" columns={columns} data={displayData} pagination />
        </>);
}

export default Roles;