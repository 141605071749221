import FetchWrapper from "../../util/fetchWrapper";
import { useHistory } from "react-router-dom";
import * as Yup from 'yup';
import moment from 'moment';

const editBetaTestImpl = ({
    currentUser,
    betaTest,
    setCanUploadFiles,
    setBetaTestPrivacyModes,
    setIsTesterJoined,
    setIsActiveChecked,
    setUploadedFiles,
    setBetaTest,
    history,
    fw,
    setAllowedUrl,
    setShouldRenderSettings,
    ...props }) => {
    const onPageLoad = (data) => {
        setShouldRenderSettings(true);
        setCanUploadFiles(data.canUploadFiles);
        setBetaTestPrivacyModes(data.statuses);
        setIsTesterJoined(data.isTesterJoined);
        setIsActiveChecked(data.betaTest.isActive);
        setUploadedFiles(data.files);
        setAllowedUrl(data.approvedUrlOrigin);
        console.log('done loading data', data);
    }

    const getInitialValues = (data) => {
        return {
            title: data?.title ?? '',
            description: data?.description ?? '',
            isDeleted: data?.isDeleted ?? false,
            incentives: data?.incentives ?? '',
            startDate: moment(data?.startDate ?? new Date()).toDate(),
            endDate: moment(data?.endDate ?? new Date()).toDate(),
            numberOfTesters: data?.maxNumbersOfTesters ?? 0,

        };
    }

    const getValidation = () => {
        return Yup.object({
            title: Yup.string()
                .required('Title is required'),
            description: Yup.string().required('Please provide a Description').notOneOf(['<p class="editor-paragraph"><br></p>'], 'Please provide a Description'),
            startDate: Yup.date()
                .nullable()
                .required('Start date is required'),
            endDate: Yup.date()
                .nullable()
                .required('End date is required'),
            numberOfTesters: Yup.number('This must be a number between 1-1000000')
                .min(1, 'This must be greater than 0')
                .required('Number of testers is required'),
        });
    }

    const saveButtonClick = (values) => {
        return new Promise((resolve, reject) => {
            const updatedBetaTest = {
                id: betaTest.id,
                title: values.title,
                description: values.description,
                startDate: values.startDate,
                endDate: values.endDate,
                maxNumbersOfTesters: values.numberOfTesters,
                isDeleted: !values.isDeleted,
                incentives: values.incentives,
                privacyStatusId: betaTest.privacyStatusId,
                isActive: values.isActive,
            }
            fw.post('/betatests/update-beta-test', updatedBetaTest)
                .then(data => {
                    if (data.errorMessage) {
                        console.log(data.errorMessage);
                        reject(data.errorMessage);
                    }

                    resolve({ success: data.success, url: window.location.pathname.replace('betatests/edit', 'betatests/view') });
                });
        });
    }

    return {
        onPageLoad,
        getInitialValues,
        getValidation,
        saveButtonClick
    };
}

export default editBetaTestImpl;