import React, { useEffect, useState } from "react";
import moment from "moment";
import RichTextBox from '../form-elements/RichTextBox.js';

const Comments = ({ comments, ...props }) => {

    return (
        <>
            <label htmlFor="comments">Comments</label>
            <ul name="comments" className="list-group" style={{ minHeight: '110px', marginBottom: '1.5rem' }}>
                {comments ?
                    comments.map(function (comment) {
                        return (
                            <li className="list-group-item" key={comment.id}>
                                <p className="col-8" style={{ float: 'left' }}>
                                    <RichTextBox
                                        // className="h-auto form-control"
                                        placeholder="Comment"
                                        name="comment"
                                        disabled={true}
                                        defaultValue={comment.comment}
                                        bypassMaxHeightLimit={true}
                                    />
                                    {/* {comment.comment} */}
                                </p>
                                <p className="col-4" style={{ float: 'right', textAlign: 'right' }}>
                                    {moment(comment.creationTime).format('lll')}
                                    <br />
                                    Created by: {comment?.fullName}
                                </p>
                            </li>
                        );
                    })
                    :
                    <>
                        <p>None</p>
                    </>
                }
            </ul>
        </>
    )
}

export default Comments;