import React, { Component } from 'react'

export const Spinner = () => {
    return (
      <div>
        <div className="spinner-wrapper">
          <div className="donut"></div>
        </div>
      </div>
    );
}
