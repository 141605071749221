import React, { useState, useEffect } from "react";
import { TICKET_TYPES } from '../models/enums/ticketsEnum';

const AddTicketButtons = (
    {
        setTicketTypeId,
        setTicketIsAdd,
        setTicketIsEdit,
        setTicketIsView,
        setShowTicketModal,
        setShouldGoBackOnClose,
        ...props
    }) => {
    return (
        <>
            <button
                className="btn btn-sm btn-gradient-primary h-auto"
                style={{ marginLeft: '1rem' }}
                onClick={(e) => {
                    e.preventDefault();

                    setTicketTypeId(TICKET_TYPES.BUG);
                    setTicketIsAdd(true);
                    setTicketIsEdit(false);
                    setTicketIsView(false);
                    setShowTicketModal(true);
                    setShouldGoBackOnClose(false);
                }}
            >
                <i class="mdi mdi-bug"></i>
                Add Bug
            </button>
            <button
                className="btn btn-sm btn-gradient-primary h-auto"
                style={{ marginLeft: '1rem' }}
                onClick={(e) => {
                    e.preventDefault();
                    setTicketTypeId(TICKET_TYPES.REQUEST);
                    setTicketIsAdd(true);
                    setTicketIsEdit(false);
                    setTicketIsView(false);
                    setShowTicketModal(true);
                    setShouldGoBackOnClose(false);
                }}
            >
                <i class="mdi mdi-clipboard-text"></i>
                Add Request
            </button>
        </>
    )
};

export default AddTicketButtons;