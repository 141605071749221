import React, { useState, useEffect } from 'react';
import fetchWrapper from '../../util/fetchWrapper';
import * as Yup from 'yup';
import { Formik, Form, Field, errorMessage } from 'formik';
import { TextField } from '../form-elements/TextField.js';
import AlertModal from '../shared/AlertModal';
import { useHistory } from 'react-router-dom';
import { CheckBox } from '../form-elements/CheckBox';

const EditUser = (props) => {
    const [user, setUser] = useState(undefined);
    const [userRolesData, setUserRolesData] = useState(undefined);
    const [allRoles, setAllRoles] = useState(undefined);
    const { userId } = props.match.params;
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const fw = fetchWrapper();
    const history = useHistory();
    const isAdd = window.location.toString().includes('/users/add');
    useEffect(() => {
        if (isAdd) {
            return;
        }
        fw.post(`/users/get-user-by-id`, { userId })
            .then(data => {
                console.log(data);
                const userRolesObj = {};
                const roles = [];
                data.userRoles.forEach(function (role) {
                    userRolesObj[role.displayName] = role.isGranted;
                    roles.push(role);
                })
                console.log('userRolesObj', userRolesObj);
                setUserRolesData(userRolesObj);
                setUser(data);
            })
    }, []);
    let validate;
    if (isAdd) {
        validate = Yup.object({
            email: Yup.string()
                .email('Email is invalid')
                .required('Email is required'),
            firstName: Yup.string()
                .required('First Name is required'),
            lastName: Yup.string()
                .required('Last Name is required'),
            password: Yup.string()
                .min(3, 'Password must be at least 6 charaters')
                .required('Password is required'),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Password must match')
                .required('Confirm password is required'),
        });
    } else {
        validate = Yup.object({
            email: Yup.string()
                .email('Email is invalid')
                .required('Email is required'),
            firstName: Yup.string()
                .required('First Name is required'),
            lastName: Yup.string()
                .required('Last Name is required'),
        });
    }

    let initialValues;
    if (isAdd) {
        initialValues = {
            email: '',
            firstName: '',
            lastName: '',
            password: ''
        };
    } else {
        initialValues = {
            email: user?.emailAddress ?? '',
            firstName: user?.name ?? '',
            lastName: user?.surname ?? '',
            isDeleted: user?.isDeleted,
            isActive: user?.isActive
        };
    }

    const handleSubmit = (values) => {
        if (isAdd) {
            console.log(`add user values: ${JSON.stringify(values)}`);
            const newUser = {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                password: values.password,
                confirmPassword: values.password,
                isActive: true,
                isDeleted: false,
                isAdminAddedUser: true,
                accountType: 1,
                UserRoles: userRolesData
            };

            fw.post(`/authentication/register`, newUser)
                .then(response => {
                    console.log('register', response);
                    if (response.id) {
                        window.location = `/users/edit/${response.id}`;
                        return;
                    }
                });

            return;
        }

        console.log(`edit users values: ${JSON.stringify(values)}`);
        const updatedUser = {
            ...user,
            name: values.firstName,
            surname: values.lastName,
            emailAddress: values.email,
            isActive: values.isActive,
            isDeleted: values.isDeleted,
            UserRoles: userRolesData
        };
        console.log(`update-user: ${JSON.stringify(updatedUser)}`);
        fw.post(`/users/update-user`, updatedUser)
            .then(response => {
                console.log('response');
                if (response.errorMessage) {
                    console.log('in error state');
                    setModalTitle('Error');
                    setModalMessage(response.errorMessage);
                    setShowModal(true);
                    return;
                }
                console.log('attempting ot open success modal');
                setModalTitle('Success');
                setModalMessage('User was updated!');
                setShowModal(true);
            });
    }

    const cancelEdit = (e) => {
        e.preventDefault();
        history.goBack();
    }

    return (
        <div className='card'>
            <Formik
                initialValues={initialValues}
                validationSchema={validate}
                validateOnChange={true}
                enableReinitialize={true}
                onSubmit={values => {
                    handleSubmit(values);
                }}
            >
                <Form>
                    <div className='card-body'>
                        <div className='card-title'>
                            <div className='row'>
                                <div className='col-10'>
                                    <h4>
                                        {isAdd ? 'Add' : 'Edit'} User: {user?.emailAddress}
                                    </h4>
                                </div>
                                <div className='col-2 row form-group'>
                                    <CheckBox
                                        checked={user?.isActive}
                                        name="isActive"
                                        displayName="Is Active"
                                        onChange={(e) => {
                                            const userData = user;
                                            userData['isActive'] = e.target.checked;
                                            setUser({
                                                ...userData
                                            });
                                        }}
                                    />
                                    <CheckBox
                                        checked={user?.isDeleted}
                                        name="isDeleted"
                                        displayName="Is Deleted"
                                        onChange={(e) => {
                                            const userData = user;
                                            userData['isDeleted'] = e.target.checked;
                                            setUser({
                                                ...userData
                                            });
                                        }}
                                    />
                                </div>
                            </div>

                        </div>

                        <TextField className="h-auto form-control form-control-lg" label="Email" name="email" type="email" placeholder="Email" />
                        <TextField className="h-auto form-control form-control-lg" label="First Name" name="firstName" type="text" placeholder="First Name" />
                        <TextField className="h-auto form-control form-control-lg" label="Last Name" name="lastName" type="text" placeholder="Last Name" />
                        {isAdd ?
                            <>
                                <TextField label="Password" placeholder="Password" name="password" type="password" />
                                <TextField label="Confirm Password" placeholder="Confirm Password" name="confirmPassword" type="password" />
                            </> :
                            <></>
                        }
                        <div className='row form-group' style={{ marginLeft: '0px' }}>
                            {
                                user?.userRoles?.map(function (role) {
                                    return (
                                        <CheckBox
                                            checked={
                                                userRolesData[role.displayName]}
                                            name={role.displayName}
                                            displayName={role.displayName}
                                            onChange={(e) => {
                                                const roleData = userRolesData;
                                                roleData[e.target.name] = e.target.checked;
                                                setUserRolesData({
                                                    ...roleData,
                                                });
                                            }}
                                        />
                                    )
                                })
                            }
                        </div>
                        <button type="submit" className="btn btn-gradient-primary mr-2">Submit</button>
                        <button className="btn btn-light" onClick={cancelEdit}>Cancel</button>

                    </div>
                    <AlertModal showModal={showModal} setShowModal={setShowModal} message={modalMessage} title={modalTitle} onAfterHideModal={() => { history.goBack() }} />
                </Form>
            </Formik>
        </div>
    )
}

export default EditUser;