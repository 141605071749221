import React from 'react';
import { Field, useField } from 'formik';

export const RadioButton = ({
    name,
    value,
    onClick,
    onBlur,
    checked,
    disabled,
    id,
    label,
    className,
    ...props
}) => {
    return (
        <div className={className}>
            <label className="form-check-label">
                <Field
                    type="radio"
                    className="form-check-input"
                    name={name}
                    id={id}
                    value={id}
                    onClick={onClick}
                    checked={checked}
                    disabled={disabled} />
                <i className="input-helper"></i>
                {label}
            </label>
        </div >
    );
};