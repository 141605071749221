import React, { useState, useEffect, useRef } from 'react';
import BetaTestOwnerSettings from './BetaTestOwnerSettings';
import BetaTesterSettings from './BetaTesterSettings';

const ModifyBetaTest = ({
    isAdd,
    isView,
    isEdit,
    currentUser,
    setShouldRenderSettings,
    betaTestName,
    betaTest,
    setBetaTest,
    bugs,
    setBugs,
    requests,
    setRequests,
    rawBetaTestData,
    setTicketTypeId,
    setTicketModalId,
    setShowTicketModal,
    setTicketIsAdd,
    setTicketIsEdit,
    setTicketIsView,
    setShouldGoBackOnClose,
    isOwner,
    userTesterObject,
    ...props }) => {
    console.log('isOwner', isOwner)
    if (isOwner || isAdd) {
        return (<BetaTestOwnerSettings
            isView={isView}
            isEdit={isEdit}
            isAdd={isAdd}
            currentUser={currentUser}
            setShouldRenderSettings={setShouldRenderSettings}
            betaTestName={betaTestName}
            betaTest={betaTest}
            setBetaTest={setBetaTest}
            bugs={bugs}
            setBugs={setBugs}
            requests={requests}
            setRequests={setRequests}
            rawBetaTestData={rawBetaTestData}
            setTicketTypeId={setTicketTypeId}
            setTicketModalId={setTicketModalId}
            setShowTicketModal={setShowTicketModal}
            setTicketIsAdd={setTicketIsAdd}
            setTicketIsEdit={setTicketIsEdit}
            setTicketIsView={setTicketIsView}
            setShouldGoBackOnClose={setShouldGoBackOnClose}
            isOwner={isOwner}
            {...props}
        />);
    }
    else {
        return (<BetaTesterSettings
            currentUser={currentUser}
            betaTestName={betaTestName}
            betaTest={betaTest}
            rawBetaTestData={rawBetaTestData}
            isOwner={isOwner}
            userTesterObject={userTesterObject}
        />);
    }

}

export default ModifyBetaTest;
