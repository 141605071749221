import React, { Component, useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { ROLES } from '../models/enums/rolesEnum';
import { useHistory } from 'react-router-dom';

const Sidebar = ({ shouldRenderSettings, sideBarBetaTestName, ...props }) => {
  const history = useHistory();
  const { betaTestName2 } = props.match.params;
  console.log('split sidebar betaTestName2', betaTestName2);
  const [basicUiMenuOpen, setBasicUiMenuOpen] = useState(true);
  const [tablesMenuOpen, setTablesMenuOpen] = useState(false);
  const [iconsMenuOpen, setIconsMenuOpen] = useState(false);
  const [chartsMenuOpen, setChartsMenuOpen] = useState(false);
  const [userPagesMenuOpen, setUserPagesMenuOpen] = useState(false);
  const [errorPagesMenuOpen, setErrorPagesMenuOpen] = useState(false);
  const [generalPagesMenuOpen, setGeneralPagesMenuOpen] = useState(false);
  const [formElementsMenuOpen, setFormElementsMenuOpen] = useState(false);
  const [betaTestsMenuOpen, setBetaTestsMenuOpen] = useState(true);
  const [location, setLocation] = useState(props.location);
  const [eventsAdded, setEventsAdded] = useState(false);
  const [showTemplateEntries, setShowTemplateEntries] = useState(false);
  const isAdmin = props.currentUser?.roles?.filter(function (role) {
    return role.roleId === ROLES.ADMIN && role.isGranted === true;
  }).length === 1 ?? false;


  useEffect(() => {
    const conditional = props?.location?.pathname !== location?.location?.pathname;
    if (conditional) {
      setLocation(props.location);
      onRouteChanged();

    }
  }, [props]);
  const isPathActive = (path) => {
    return props.location.pathname.startsWith(path);
  }

  useEffect(() => {
    if (!eventsAdded) {
      // console.log('adding event listeners in Sidebar.js');
      onRouteChanged();
      // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
      const body = document.querySelector('body');
      document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
        const mouseOverFunc = function () {
          if (body.classList.contains('sidebar-icon-only')) {
            el.classList.add('hover-open');
          }
        };
        el.removeEventListener('mouseover', mouseOverFunc);
        el.addEventListener('mouseover', mouseOverFunc);
        const mouseOutFunc = function () {
          if (body.classList.contains('sidebar-icon-only')) {
            el.classList.remove('hover-open');
          }
        };
        el.removeEventListener('mouseout', mouseOutFunc);
        el.addEventListener('mouseout', mouseOutFunc);
      });
      setEventsAdded(true);
    }
  }, []);

  const onRouteChanged = () => {
    // console.log('in sidebar onRouteChanged');
    document.querySelector('#sidebar').classList.remove('active');

    const dropdownPaths = [
      { path: '/basic-ui', state: (val) => { setBasicUiMenuOpen(val) }, getState: () => { console.log(`in sidebar basicUiMenuOpen: ${basicUiMenuOpen}`) } },
      { path: '/form-elements', state: (val) => { setFormElementsMenuOpen(val) }, getState: () => { console.log(`in sidebar formElementsMenuOpen: ${formElementsMenuOpen}`) } },
      { path: '/tables', state: (val) => { setTablesMenuOpen(val) }, getState: () => { console.log(`in sidebar tablesMenuOpen: ${tablesMenuOpen}`) } },
      { path: '/icons', state: (val) => { setIconsMenuOpen(val) }, getState: () => { console.log(`in sidebar iconsMenuOpen: ${iconsMenuOpen}`) } },
      { path: '/charts', state: (val) => { setChartsMenuOpen(val) }, getState: () => { console.log(`in sidebar chartsMenuOpen: ${chartsMenuOpen}`) } },
      { path: '/user/', state: (val) => { setUserPagesMenuOpen(val) }, getState: () => { console.log(`in sidebar userPagesMenuOpen: ${userPagesMenuOpen}`) } },
      { path: '/error-pages', state: (val) => { setErrorPagesMenuOpen(val) }, getState: () => { console.log(`in sidebar errorPagesMenuOpen: ${errorPagesMenuOpen}`) } },
      { path: '/general-pages', state: (val) => { setGeneralPagesMenuOpen(val) }, getState: () => { console.log(`in sidebar generalPagesMenuOpen: ${generalPagesMenuOpen}`) } },
      { path: '/betatests', state: (val) => { setBetaTestsMenuOpen(val) }, getState: () => { console.log(`in sidebar betaTestsMenuOpen: ${betaTestsMenuOpen}`) } },
    ];

    // dropdownPaths[0].getState();
    dropdownPaths.forEach((obj => {
      if (isPathActive(obj.path)) {
        // console.log(`setting ${obj.path} as active in sidebar`);
        obj.state(true);
      } else {
        obj.state(false);
      }

      // obj.getState();
    }));

  }

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        <li className="nav-item nav-profile">
          <a href="!#" className="nav-link" onClick={evt => evt.preventDefault()}>
            <div className="nav-profile-image">
              <img src={require("../../assets/images/faces/face1.jpg")} alt="profile" />
              <span className="login-status online"></span> {/* change to offline or busy as needed */}
            </div>
            <div className="nav-profile-text">
              <span className="font-weight-bold mb-2"><Trans>David Grey. H</Trans></span>
              <span className="text-secondary text-small"><Trans>Project Manager</Trans></span>
            </div>
            <i className="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
          </a>
        </li>
        {window.location.pathname.endsWith('/betatests') || !window.location.pathname.startsWith('/betatests/') || window.location.pathname.startsWith('/betatests/create')
          ?
          (
            <li className={isPathActive('/betatests') ? 'nav-item active' : 'nav-item'}>
              <Link className="nav-link" to="/betatests">
                <span className="menu-title"><Trans>Beta Tests</Trans></span>
                <i className="mdi mdi-test-tube menu-icon"></i>
              </Link>
            </li>)
          :
          (<li className={'nav-item'}>
            <Link to={`/betatests/${sideBarBetaTestName}`} className={betaTestsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} data-toggle="collapse">
              <span className="menu-title"><Trans>{sideBarBetaTestName}</Trans></span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-test-tube menu-icon"></i>
            </Link>
            <Collapse in={betaTestsMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link className={window.location.pathname === `/betatests/${sideBarBetaTestName}` ? 'nav-link active' : 'nav-link'} to={`/betatests/${sideBarBetaTestName}`}>
                    Dashboard
                  </Link></li>
                <li className='nav-item'>
                  <Link className={isPathActive(`/betatests/${sideBarBetaTestName}/tickets`) ? 'nav-link active' : 'nav-link'} to={`/betatests/${sideBarBetaTestName}/tickets`}>
                    Tickets
                  </Link>
                </li>
                <li className="nav-item"> <Link className={isPathActive(`/betatests/${sideBarBetaTestName}/settings`) ? 'nav-link active' : 'nav-link'} to={`/betatests/${sideBarBetaTestName}/settings`}><Trans>Settings</Trans></Link></li>
              </ul>
            </Collapse>
          </li>)
        }

        {showTemplateEntries && (
          <>
            <li className={isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
              <Link className="nav-link" to="/dashboard">
                <span className="menu-title"><Trans>Dashboard</Trans></span>
                <i className="mdi mdi-home menu-icon"></i>
              </Link>
            </li>
            <li className={isPathActive('/basic-ui') ? 'nav-item active' : 'nav-item'}>
              <div className={basicUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => setBasicUiMenuOpen(!basicUiMenuOpen)} data-toggle="collapse">
                <span className="menu-title"><Trans>Basic UI Elements</Trans></span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-crosshairs-gps menu-icon"></i>
              </div>
              <Collapse in={basicUiMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={isPathActive('/basic-ui/buttons') ? 'nav-link active' : 'nav-link'} to="/basic-ui/buttons"><Trans>Buttons</Trans></Link></li>
                  <li className="nav-item"> <Link className={isPathActive('/basic-ui/dropdowns') ? 'nav-link active' : 'nav-link'} to="/basic-ui/dropdowns"><Trans>Dropdowns</Trans></Link></li>
                  <li className="nav-item"> <Link className={isPathActive('/basic-ui/typography') ? 'nav-link active' : 'nav-link'} to="/basic-ui/typography"><Trans>Typography</Trans></Link></li>
                </ul>
              </Collapse>
            </li>
            <li className={isPathActive('/form-elements') ? 'nav-item active' : 'nav-item'}>
              <div className={formElementsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => setFormElementsMenuOpen(!formElementsMenuOpen)} data-toggle="collapse">
                <span className="menu-title"><Trans>Form Elements</Trans></span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-format-list-bulleted menu-icon"></i>
              </div>
              <Collapse in={formElementsMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={isPathActive('/form-elements/basic-elements') ? 'nav-link active' : 'nav-link'} to="/form-elements/basic-elements"><Trans>Basic Elements</Trans></Link></li>
                </ul>
              </Collapse>
            </li>
            <li className={isPathActive('/tables') ? 'nav-item active' : 'nav-item'}>
              <div className={tablesMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => setTablesMenuOpen(!tablesMenuOpen)} data-toggle="collapse">
                <span className="menu-title"><Trans>Tables</Trans></span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-table-large menu-icon"></i>
              </div>
              <Collapse in={tablesMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={isPathActive('/tables/basic-table') ? 'nav-link active' : 'nav-link'} to="/tables/basic-table"><Trans>Basic Table</Trans></Link></li>
                </ul>
              </Collapse>
            </li>
            <li className={isPathActive('/icons') ? 'nav-item active' : 'nav-item'}>
              <div className={iconsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => setIconsMenuOpen(!iconsMenuOpen)} data-toggle="collapse">
                <span className="menu-title"><Trans>Icons</Trans></span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-contacts menu-icon"></i>
              </div>
              <Collapse in={iconsMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={isPathActive('/icons/mdi') ? 'nav-link active' : 'nav-link'} to="/icons/mdi"><Trans>Material</Trans></Link></li>
                </ul>
              </Collapse>
            </li>
            <li className={isPathActive('/charts') ? 'nav-item active' : 'nav-item'}>
              <div className={chartsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => setChartsMenuOpen(~chartsMenuOpen)} data-toggle="collapse">
                <span className="menu-title"><Trans>Charts</Trans></span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-chart-bar menu-icon"></i>
              </div>
              <Collapse in={chartsMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={isPathActive('/charts/chart-js') ? 'nav-link active' : 'nav-link'} to="/charts/chart-js"><Trans>Chart Js</Trans></Link></li>
                </ul>
              </Collapse>
            </li>
            <li className={isPathActive('/userd/') ? 'nav-item active' : 'nav-item'}>
              <div className={userPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => setUserPagesMenuOpen(!userPagesMenuOpen)} data-toggle="collapse">
                <span className="menu-title"><Trans>User Pages</Trans></span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-lock menu-icon"></i>
              </div>
              <Collapse in={userPagesMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={isPathActive('/user/login') ? 'nav-link active' : 'nav-link'} to="/user/login"><Trans>Login</Trans></Link></li>
                  <li className="nav-item"> <Link className={isPathActive('/user/register') ? 'nav-link active' : 'nav-link'} to="/user/register"><Trans>Register</Trans></Link></li>
                  <li className="nav-item"> <Link className={isPathActive('/user/lockscreen') ? 'nav-link active' : 'nav-link'} to="/user/lockscreen"><Trans>Lockscreen</Trans></Link></li>
                </ul>
              </Collapse>
            </li>
            <li className={isPathActive('/error-pages') ? 'nav-item active' : 'nav-item'}>
              <div className={errorPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => setErrorPagesMenuOpen(!errorPagesMenuOpen)} data-toggle="collapse">
                <span className="menu-title"><Trans>Error Pages</Trans></span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-security menu-icon"></i>
              </div>
              <Collapse in={errorPagesMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={isPathActive('/error-pages/error-404') ? 'nav-link active' : 'nav-link'} to="/error-pages/error-404">404</Link></li>
                  <li className="nav-item"> <Link className={isPathActive('/error-pages/error-500') ? 'nav-link active' : 'nav-link'} to="/error-pages/error-500">500</Link></li>
                </ul>
              </Collapse>
            </li>
            <li className={isPathActive('/general-pages') ? 'nav-item active' : 'nav-item'}>
              <div className={generalPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => setGeneralPagesMenuOpen(!generalPagesMenuOpen)} data-toggle="collapse">
                <span className="menu-title"><Trans>General Pages</Trans></span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-medical-bag menu-icon"></i>
              </div>
              <Collapse in={generalPagesMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={isPathActive('/general-pages/blank-page') ? 'nav-link active' : 'nav-link'} to="/general-pages/blank-page"><Trans>Blank Page</Trans></Link></li>
                </ul>
              </Collapse>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="http://bootstrapdash.com/demo/purple-react-free/documentation/documentation.html" rel="noopener noreferrer" target="_blank">
                <span className="menu-title"><Trans>Documentation</Trans></span>
                <i className="mdi mdi-file-document-box menu-icon"></i>
              </a>
            </li>
            <li className={isPathActive('/checkout') ? 'nav-item active' : 'nav-item'}>
              <Link className="nav-link" to="/checkout">
                <span className="menu-title"><Trans>Checkout</Trans></span>
                <i className="mdi mdi-cards-outline menu-icon"></i>
              </Link>
            </li>
          </>
        )}
        {isAdmin ?
          <>
            <li className={isPathActive('/users') ? 'nav-item active' : 'nav-item'}>
              <Link className="nav-link" to="/users">
                <span className="menu-title"><Trans>Users</Trans></span>
                <i className="mdi mdi-account-multiple menu-icon"></i>
              </Link>
            </li>
            <li className={isPathActive('/roles') ? 'nav-item active' : 'nav-item'}>
              <Link className="nav-link" to="/roles">
                <span className="menu-title"><Trans>Roles</Trans></span>
                <i className="mdi mdi-account-key menu-icon"></i>
              </Link>
            </li>
            <li className={isPathActive('/permissions') ? 'nav-item active' : 'nav-item'}>
              <Link className="nav-link" to="/permissions">
                <span className="menu-title"><Trans>Permissions</Trans></span>
                <i className="mdi mdi-tie menu-icon"></i>
              </Link>
            </li>
          </> :
          <></>}
      </ul>
    </nav>
  );
}

export default withRouter(Sidebar);