import React, { useState, useEffect, useRef } from 'react';
import FetchWrapper from '../../util/fetchWrapper';
import viewBetaTestImpl from './viewBetaTestImpl';
import FileUpload from "../shared/FileUpload";
import useFileUpload from 'react-use-file-upload';
import { Formik, Form } from 'formik';
import { useHistory } from 'react-router-dom';
import AlertModal from '../shared/AlertModal';
import ConfirmationModal from '../shared/ConfirmationModal';
import BugsCollapse from './BugsCollapse';
import RequestsCollapse from './RequestsCollapse';
import ModifyTicketModal from './ModifyTicketModal';
import useMargin from '../shared/useMargin';
import useHeight from '../shared/useHeight';
import { FILE_TYPE } from '../models/enums/fileTypeEnum';
import BetaTestGeneralInformation from './BetaTestGeneralInformation';
import AddTicketButtons from './AddTicketButtons';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import DashboardCard from './DashboardCard';

const ViewBetaTest = ({
    isView,
    currentUser,
    setShouldRenderSettings,
    betaTestName,
    betaTest,
    setBetaTest,
    bugs,
    setBugs,
    requests,
    setRequests,
    rawBetaTestData,
    setTicketTypeId,
    setTicketModalId,
    setShowTicketModal,
    setTicketIsAdd,
    setTicketIsEdit,
    setTicketIsView,
    setShouldGoBackOnClose,
    isOwner,
    ...props }) => {
    const [dataLoaded, setDataLoaded] = useState(false);
    const queryParams = new URLSearchParams(window.location.search);
    const acceptInvitaiton = queryParams.get('acceptInvitation');
    const queryParamCode = queryParams.get('code');
    const fw = FetchWrapper();
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [alertModalMessage, setAlertModalMessage] = useState('');
    const [alertModalTitle, setAlertModalTitle] = useState('');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [confirmationModalMessage, setConfirmationModalMessage] = useState('');
    const [confirmationCodalTitle, setConfirmationModalTitle] = useState('');
    const history = useHistory();
    const [testers, setTesters] = useState([]);

    const [isTesterJoined, setIsTesterJoined] = useState(false);
    const [isActiveChecked, setIsActiveChecked] = useState(false);
    const [canUploadFiles, setCanUploadFiles] = useState(false);
    const [modalShouldGoBack, setModalShouldGoBack] = useState(true);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [betaTestPrivacyModes, setBetaTestPrivacyModes] = useState([]);
    const [allowedUrl, setAllowedUrl] = useState('');
    const form = useRef(null);
    const submitForm = () => { form.current.handleSubmit(); };
    const [bugCount, setBugCount] = useState(0);
    const [requestCount, setRequestCount] = useState(0);
    const [totalBugCount, setTotalBugCount] = useState(0);
    const [totalRequestCount, setTotalRequestCount] = useState(0);
    const [testersCount, setTestersCount] = useState(0);
    const [filesSizeUsed, setFilesSizeUsed] = useState(0);
    const cardHeight = useHeight('dashboard-card');

    const dataImpl = viewBetaTestImpl(
        {
            currentUser,
            betaTest,
            setCanUploadFiles,
            setBetaTestPrivacyModes,
            setTesters,
            setIsTesterJoined,
            setIsActiveChecked,
            setUploadedFiles,
            setBetaTest,
            fw,
            queryParamCode,
            setAllowedUrl,
            setShouldRenderSettings,
            ...props
        });


    let initialValues = React.useMemo(() => dataImpl.getInitialValues(betaTest));
    const validate = React.useMemo(() => dataImpl.getValidation());

    const areBetaTestInputsDisabled = () => {
        return true
    }

    useEffect(() => {
        if (dataLoaded === false && rawBetaTestData !== null && rawBetaTestData !== undefined && Object.keys(rawBetaTestData).length > 0) {
            console.log('data loaded in view beta test', rawBetaTestData)
            dataImpl.onPageLoad(rawBetaTestData);
            setDataLoaded(true);
        }

    }, [rawBetaTestData]);

    useEffect(() => {
        if (acceptInvitaiton && queryParamCode) {
            fw.post(`/testers/accept-invitation`, { code: queryParamCode })
                .then((data) => {
                    if (data.success) {
                        window.location.href = `/betatests/${data.betaTestId}`;
                    }
                })
                .catch((err) => {
                    console.log('error accepting invitation', err);
                });
        }
    }, [acceptInvitaiton]);

    useEffect(() => {
        fw.get(`/betatests/calculate-dashboard-stats?betaTestName=${betaTestName}`)
            .then(data => {
                if (data.errorMessage) {
                    console.log('error getting dashboard stats', data.errorMessage);
                    return;
                }

                setBugs(data.last10Bugs);
                setRequests(data.last10Requests);
                setBugCount(data.bugCount);
                setRequestCount(data.requestCount);
                setTestersCount(data.testerCount);
                setFilesSizeUsed(data.filesSizeUsed);
                setTotalRequestCount(data.totalRequestCount);
                setTotalBugCount(data.totalBugCount);
            })
    }, [betaTestName]);

    useEffect(() => {
        console.log('card height change', cardHeight);
    }, [cardHeight])


    const {
        files,
        fileNames,
        handleDragDropEvent,
        clearAllFiles,
        createFormData,
        setFiles,
        removeFile,
    } = useFileUpload();



    const uploadTextFormatted =
        <>
            <p>Please use the form to your right to upload any file(s) of your choosing.</p>
            <p className='bold-font'>These files will be provided to the tester when they join the beta test.</p>
        </>;


    return (
        <>
            {rawBetaTestData?.isClosed === true ?
                <div className='card'>
                    <div className='card-body'>
                        <div className='card-title'>
                            <h4 style={{ textTransform: 'none' }}>
                                This beta test is closed.
                            </h4>
                        </div>
                    </div>
                </div>
                :

                <>
                    <div className='card'>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validate}
                            validateOnChange={true}
                            enableReinitialize={true}
                            onSubmit={values => {
                                // handleSubmit(values);
                            }}
                            onSubmittingCallback={(values) => {
                                console.log('callback', values);
                            }}
                            innerRef={form}
                        >

                            {({ setFieldValue }) => (
                                <Form>
                                    <div className='card-body'>
                                        <div className='card-title'>
                                            <div className="row">
                                                <div className="col-lg-6 col-md-5 col-sm-5 no-wrap">
                                                    <h4 style={{ textTransform: 'none' }}>
                                                        Viewing Beta Test: {betaTest?.title}
                                                    </h4>
                                                </div>
                                                <div className='col ml-auto d-flex justify-content-end align-items-center'>
                                                    {dataLoaded && (isOwner || isTesterJoined) && (
                                                        <AddTicketButtons
                                                            setTicketTypeId={setTicketTypeId}
                                                            setTicketIsAdd={setTicketIsAdd}
                                                            setTicketIsEdit={setTicketIsEdit}
                                                            setTicketIsView={setTicketIsView}
                                                            setShowTicketModal={setShowTicketModal}
                                                            setShouldGoBackOnClose={setShouldGoBackOnClose}
                                                        />)}

                                                    {dataLoaded && !isTesterJoined && !isOwner && (<button
                                                        type="submit"
                                                        className="btn btn-sm btn-gradient-primary mr-2"
                                                        disabled={isTesterJoined || betaTest?.privacyStatusId !== 1}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            fw.post('/testers/save-tester', { betaTestId: betaTest?.id, userId: currentUser.id })
                                                                .then(data => {
                                                                    if (data?.success) {
                                                                        window.location.reload();
                                                                    }
                                                                });
                                                        }}
                                                    >Join</button>)}
                                                    {(isOwner || isTesterJoined) && (
                                                        <Link style={{ color: 'black' }} to={`/betatests/${betaTestName}/settings`}>
                                                            <i className="mdi mdi-settings-outline ml-3" style={{ fontSize: '25px', cursor: 'pointer' }}
                                                            // onClick={() => window.location.href = `/betatests/${betaTestName}/settings`}
                                                            ></i>
                                                        </Link>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <BetaTestGeneralInformation
                                            betaTest={betaTest}
                                            areBetaTestInputsDisabled={areBetaTestInputsDisabled}
                                            displayIncentives={betaTest?.incentives && betaTest?.incentives.length > 0}
                                            renderFullText={false}
                                        // setFieldValue={setFieldValue}
                                        />
                                        <div className='row'>
                                            <DashboardCard
                                                background="bg-danger"
                                                title="Bugs in the last 24 hours"
                                                count={bugCount}
                                                cardId={'dashboard-card'}
                                            />

                                            <DashboardCard
                                                background="bg-info"
                                                title="Requests in the last 24 hours"
                                                count={requestCount}
                                            />

                                            <DashboardCard
                                                background="bg-primary"
                                                title="Total Bugs"
                                                count={totalBugCount}
                                                minHeight={cardHeight}
                                            />

                                            <DashboardCard
                                                background="bg-primary"
                                                title="Total Requests"
                                                count={totalRequestCount}
                                                minHeight={cardHeight}
                                            />

                                            <DashboardCard
                                                background="bg-primary"
                                                title="File Space Used"
                                                count={filesSizeUsed}
                                                fontSize='48px'
                                                minHeight={cardHeight}
                                            />
                                        </div>

                                        <div className='col-lg-6 col-md-12 col-sm-12 pt-1 ml-0 pl-0'>
                                            <FileUpload
                                                uploadDisabled={areBetaTestInputsDisabled()}
                                                uploadText={uploadTextFormatted}
                                                files={files}
                                                fileNames={fileNames}
                                                handleDragDropEvent={handleDragDropEvent}
                                                clearAllFiles={clearAllFiles}
                                                createFormData={createFormData}
                                                setFiles={setFiles}
                                                removeFile={removeFile}
                                                alreadyUploadedFiles={uploadedFiles}
                                                linkingId={betaTest?.id}
                                                fileType={FILE_TYPE.BETA_TEST}
                                                hasUploadPermission={false}
                                            />
                                        </div>
                                        {false && (<>
                                            <BugsCollapse
                                                betaTestId={betaTestName}
                                                bugs={bugs}
                                                currentUser={currentUser}
                                                setShowAlertModal={setShowAlertModal}
                                                setAlertModalTitle={setAlertModalTitle}
                                                setAlertModalMessage={setAlertModalMessage}
                                                isView={isView}
                                                isTesterJoined={isTesterJoined || betaTest?.userId === currentUser?.id}
                                                setShowTicketModal={setShowTicketModal}
                                                setTicketModalId={setTicketModalId}
                                                setTicketIsAdd={setTicketIsAdd}
                                                setTicketIsEdit={setTicketIsEdit}
                                                setTicketIsView={setTicketIsView}
                                                setTicketTypeId={setTicketTypeId}
                                                setShouldGoBackOnClose={setShouldGoBackOnClose}
                                            />
                                            <hr />
                                            <RequestsCollapse
                                                betaTestId={betaTestName}
                                                requests={requests}
                                                currentUser={currentUser}
                                                setShowAlertModal={setShowAlertModal}
                                                setAlertModalTitle={setAlertModalTitle}
                                                setAlertModalMessage={setAlertModalMessage}
                                                isView={isView}
                                                setShowTicketModal={setShowTicketModal}
                                                setTicketModalId={setTicketModalId}
                                                setTicketIsAdd={setTicketIsAdd}
                                                setTicketIsEdit={setTicketIsEdit}
                                                setTicketIsView={setTicketIsView}
                                                setTicketTypeId={setTicketTypeId}
                                                isTesterJoined={isTesterJoined || betaTest?.userId === currentUser?.id}
                                                setShouldGoBackOnClose={setShouldGoBackOnClose}
                                            />
                                            <hr />
                                        </>)}

                                    </div>
                                    {modalShouldGoBack ?
                                        <AlertModal showModal={showAlertModal} setShowModal={setShowAlertModal} message={alertModalMessage} title={alertModalTitle} onAfterHideModal={() => {
                                            history.goBack();
                                            setShowAlertModal(false);

                                        }} />
                                        :
                                        <AlertModal showModal={showAlertModal} setShowModal={setShowAlertModal} message={alertModalMessage} title={alertModalTitle} onAfterHideModal={() => {
                                            window.location.reload();
                                        }} />}
                                </Form>
                            )}
                        </Formik>
                        <AlertModal
                            showModal={showAlertModal}
                            setShowModal={setShowAlertModal}
                            message={alertModalMessage}
                            title={alertModalTitle}

                        />
                    </div>
                </>
            }
        </>
    );
}

export default ViewBetaTest;
