import React, { useState, useEffect } from "react";
import FetchWrapper from "../../util/fetchWrapper";
import MessageForm from "./MessageForm";
import AlertModal from "../shared/AlertModal";
import { Button } from "react-bootstrap";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";

export default (props) => {
    const [message, setMessage] = useState(undefined);
    const [messageArguments, setMessageArguments] = useState(undefined);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const isView = window.location.toString().includes('/messages/view');
    const [isReplying, setIsReplying] = useState(false);
    const { messageId } = props.match.params;
    const fw = FetchWrapper();
    const history = useHistory();
    useEffect(() => {
        if (isView) {
            fw.get(`/messages/view?messageId=${messageId}`)
                .then(data => {
                    if (data.errorMessage) {
                        setModalMessage(data.errorMessage);
                        setModalTitle('Error');
                        setShowModal(true);
                        return;
                    }
                    const parsed = JSON.parse(data.argumentsJson);
                    console.log('parsed', parsed);
                    setMessageArguments(parsed);
                    setMessage(data);
                });
        }
    }, []);

    const handleSubmit = (values) => {
        const newMessage = {
            recipientUserId: message.senderUserId,
            title: values.title,
            text: values.text,
        };

        fw.post(`/messages/send`, newMessage)
            .then(res => {
                if (res.errorMessage) {
                    setModalMessage(res.errorMessage);
                    setModalTitle('Error');
                    setShowModal(true);
                    return;
                }
                history.goBack();
            });
    };

    const initialValues = {
        from: message ? message.fullName : '',
        title: message ? message.title : '',
        text: message ? message.text : ''
    };

    const replyInitialValues = {
        title: message ? `RE:${message.title}` : '',
        text: ''
    };

    console.log(initialValues);

    return (
        <div className="card">
            <div className="card-body">
                <MessageForm
                    // handleSubmit={handleSubmit}
                    isView={isView}
                    initialValues={initialValues}
                    validate={{}}
                />
                {
                    messageArguments?.isBetaTestInvitation ?
                        <div
                            className="mb-2 pb-2"
                        >
                            <Button
                                disabled={messageArguments?.replied !== undefined}
                                variant="primary"
                                className="btn btn-gradient-success btn-fw mr-3"
                                onClick={(e) => {
                                    e.preventDefault();
                                    fw.post('/testers/accept-invitation', { betaTestId: messageArguments?.betaTestId, messageId: message.id, code: messageArguments?.code })
                                        .then(data => {
                                            if (data.errorMessage) {
                                                setModalMessage(data.errorMessage);
                                                setModalTitle('Error');
                                                setShowModal(true);
                                                return;
                                            }
                                            setModalMessage('You have successfully joined the beta test!');
                                            setModalTitle('Success');
                                            setShowModal(true);
                                        });
                                }}
                            >Join Beta Test</Button>

                            <Button
                                disabled={messageArguments?.replied !== undefined}
                                variant="primary"
                                className="btn btn-gradient-danger btn-fw"
                                onClick={(e) => {
                                    e.preventDefault();
                                    fw.post('/testers/decline-invitation', { betaTestId: messageArguments?.betaTestId, messageId: message.id })
                                        .then(data => {
                                            if (data.errorMessage) {
                                                setModalMessage(data.errorMessage);
                                                setModalTitle('Error');
                                                setShowModal(true);
                                                return;
                                            }
                                            setModalMessage('You have successfully declined to join the beta test');
                                            setModalTitle('Success');
                                            setShowModal(true);
                                        });
                                }}
                            >Decline to Join</Button>
                        </div>
                        :
                        <></>
                }
                <div className="row col-3">
                    <Button
                        variant="primary"
                        className="btn btn-gradient-primary mr-2 mb-4"
                        onClick={(e) => {
                            e.preventDefault();
                            setIsReplying(!isReplying);
                        }}
                    >Reply</Button>
                </div>
                <div hidden={!isReplying}>
                    <MessageForm
                        handleSubmit={handleSubmit}
                        isView={false}
                        initialValues={replyInitialValues}
                        validate={Yup.object({
                            title: Yup.string().required('Please enter a subject'),
                            text: Yup.string().required('Please enter a message')
                        })}
                    />
                </div>
                <AlertModal showModal={showModal} setShowModal={setShowModal} message={modalMessage} title={modalTitle}
                    onAfterHideModal={() => {
                        window.location.reload();
                    }}
                />
            </div>
        </div>
    );
};